<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Edit School" subText="Add or update a school." />

        <form @submit.prevent="saveInstitution">
            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Name<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="text"
                        v-model="institution.name"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Institution Logo
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="institution.logo"
                        class="form-control"
                        v-if="institution.logo != ''"
                        disabled
                        required
                    />
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        v-else
                        @change="onFileChange($event)"
                    />
                    <button
                        class="btn btn-link"
                        v-if="institution.logo != ''"
                        @click="replaceLogo"
                    >
                        Replace Logo
                    </button>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Address<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="text"
                        v-model="institution.address"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >State/Province
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="institution.state"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Country<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        v-model="institution.country"
                        class="form-select"
                        required
                    >
                        <option value="">Select Country</option>
                        <option
                            v-for="country in countries"
                            :value="country.label"
                            :key="country.value"
                        >
                            {{ country.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >About School
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <ckeditor
                        :editor="editor"
                        v-model="institution.about"
                        :config="{}"
                    ></ckeditor>
                    <!--<textarea v-model="institution.about" class="form-control" required></textarea>-->
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10">
                    <label
                        >List some key features of school
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <div
                        v-for="(feature, counter) in institution.features"
                        :key="counter"
                        class="mb-3 card p-3"
                    >
                        <div class="mb-3">
                            <label
                                >Title
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                v-model="feature.header"
                                class="form-control"
                                required
                            />
                        </div>
                        <div>
                            <label
                                >Description
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <textarea
                                v-model="feature.body"
                                class="form-control"
                                required
                            ></textarea>
                        </div>
                        <div class="mt-3">
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="deleteFeature(counter)"
                            >
                                Delete this feature
                            </button>
                        </div>
                    </div>

                    <div class="mt-2 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="addFeature"
                        >
                            Add more features
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Currency Name (e.g. CAD)<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        class="form-select"
                        v-model="institution.currency"
                        required
                    >
                        <!--<option value="">Select One</option>
              <option value="CAD">Canadian Dollar</option>
              <option value="USD">US Dollar</option>
              <option value="GBP">Pound Sterling</option>
              <option value="EUR">Euro</option>
              <option value="AUD">Australian Dollar</option>-->
                        <option
                            v-for="cur in currency_list"
                            :key="cur.id"
                            :value="cur.code"
                        >
                            {{ cur.name }}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Currency Symbol (e.g $)<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        class="form-select"
                        v-model="institution.currency_symbol"
                        required
                    >
                        <option value="">Select One</option>
                        <!--<option value="$">$</option>
              <option value="£">£</option>
              <option value="€">€</option>-->
                        <option
                            v-for="cur in currency_symbol_list"
                            :key="cur"
                            :value="cur"
                        >
                            {{ cur }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >School Type<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        v-model="institution.school_type"
                        class="form-select"
                    >
                        <option value="">Select One</option>
                        <option
                            v-for="st in school_types"
                            :key="st"
                            :value="st"
                        >
                            {{ st }}
                        </option>
                    </select>
                </div>
                <div
                    class="col-12 col-md-5 mt-3 mt-md-0"
                    style="max-height: 150px; overflow-y: auto"
                >
                    <label
                        >Type of programs offered<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <div v-for="st in program_types" :key="st">
                        <input
                            type="checkbox"
                            :id="st"
                            :value="st"
                            v-model="institution.programs_offered"
                        />
                        <label :for="st" class="ms-1">{{ st }}</label>
                    </div>
                </div>
            </div>

            <div class="row mb-md-4">
                <!--<div class="col-12 col-md-5">
            <label>Number of programs offered (approx)<span class="text-danger ml-2 fw-bold">*</span></label>
            <input type="number" v-model="institution.available_courses" class="form-control" required/>
        </div>-->
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >Tuition Range (e.g $5,000 to $8,000)<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="text"
                        v-model="institution.tuition_range"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-3">
                    <label
                        >Application Fee<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="institution.application_fee"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-4 mt-3 mt-md-0">
                    <label
                        >Average cost of tuition<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="institution.average_cost_of_tuition"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-3 mt-3 mt-md-0">
                    <label
                        >Average cost of living<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="institution.average_cost_of_living"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10">
                    <label
                        >List school's admission documents</label>
                    <div
                        v-for="(req, counter) in institution.required_docs"
                        :key="counter"
                        class="mb-3 card p-3"
                    >
                        <div class="mb-3">
                            <label
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                v-model="req.name"
                                class="form-control"
                                required
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                >Description
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <textarea
                                v-model="req.desc"
                                class="form-control"
                                required
                            ></textarea>
                        </div>
                        <div class="mb-3">
                            <label
                                >Is document required
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <select
                                class="form-select"
                                v-model="req.isRequired"
                                required
                            >
                                <option>Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div>
                            <label>Document to fill</label>
                            <div v-if="req.filePath != ''">
                                <input
                                    type="text"
                                    v-model="req.filePath"
                                    class="form-control"
                                    disabled
                                    required
                                />
                                <button
                                    class="btn btn-link"
                                    @click="replaceFile(req)"
                                >
                                    Replace File
                                </button>
                            </div>
                            <div v-else>
                                <input
                                class="form-control"
                                type="file"
                                @change="onReqFileChange($event, req)"
                                />
                            </div>
                        </div>
                        <div class="mt-3 text-end">
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="deleteRequiredDocs(counter)"
                            >
                                Delete this requirement
                            </button>
                        </div>
                    </div>

                    <div class="mt-2">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="addRequiredDocs"
                        >
                            Add more requirements
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Show to public<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        v-model="institution.show_to_public"
                        class="form-select"
                        required
                    >
                        <option>Select One</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>

            <div class="mt-3">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save Institution
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { currency_list } from '@/utils/currencyList';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
export default {
    name: 'EditSchool',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            editor: ClassicEditor,
            institution_id: '0',
            currency_list: currency_list,
            currency_symbol_list: [
                ...new Set(currency_list.map((x) => x.symbol)),
            ],
            school_types: ['University', 'College'],
            program_types: [
                'BSc',
                'BBA',
                'BA',
                'MSc',
                'MBA',
                'MA',
                'MD',
                'PhD',
                'Certificate',
                'Diploma',
                'Graduate Certificate',
                'Postgraduate Diploma',
                'Postgraduate Certificate',
            ],
            institution: {
                name: '',
                logo: '',
                address: '',
                state: '',
                country: '',
                about: '',
                currency: '',
                currency_symbol: '',
                features: [
                    {
                        header: '',
                        body: '',
                    },
                ],
                school_type: [],
                programs_offered: [],
                // available_courses: 0,
                tuition_range: '',
                application_fee: 0,
                average_cost_of_tuition: 0,
                average_cost_of_living: 0,
                show_to_public: false,
                required_docs: [
                    {
                        name: '',
                        desc: '',
                        filePath: '',
                        isRequired: true,
                    },
                ],
            },
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        replaceLogo() {
            this.institution.logo = '';
        },
        addRequiredDocs() {
            if (this.institution.required_docs == undefined) {
                this.institution.required_docs = [];
            }
            this.institution.required_docs.push({
                name: '',
                desc: '',
                filePath: '',
                isRequired: true,
            });
        },
        deleteRequiredDocs(ct) {
            this.institution.required_docs.splice(ct, 1);
        },
        replaceFile(req) {
            req.filePath = '';
        },
        onFileChange(event) {
            // doc.file = event.target.files[0];
            let up = {
                path: 'logos',
                uploadFiles: [event.target.files[0]],
            };
            AdminService.postDataForm('upload_document', up).then((resp) => {
                if (resp && resp.data) {
                    this.institution.logo = resp.data;
                }
            });
            //doc.path_to_file = "test post";
        },
        onReqFileChange(event, req) {
            // doc.file = event.target.files[0];
            let up = {
                path: 'schoolreq',
                uploadFiles: [event.target.files[0]],
            };
            AdminService.postDataForm('upload_document', up).then((resp) => {
                if (resp && resp.data) {
                    req.filePath = resp.data;
                }
            });
            //doc.path_to_file = "test post";
        },
        saveInstitution() {
            AdminService.postData('save_school', this.institution)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.institution = resp.data;
                        this.updateModal({
                            type: true,
                            message: 'Institution saved',
                        });
                        this.$router.push({ name: 'ManageSchools' });
                    }
                })
        },
        addFeature() {
            this.institution.features.push({
                header: '',
                body: '',
            });
        },
        deleteFeature(ct) {
            this.institution.features.splice(ct, 1);
        },
        getSchool() {
            AdminService.getData('get_school_by_id/' + this.institution_id)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.institution = resp.data;
                    }
                })
        },
    },
    computed: {
        role() {
            if (this.$store.state.admin.currentUser) {
                return this.$store.state.admin.currentUser.uuser.role;
            } else {
                return '';
            }
        },
        countries() {
            const list = countries.getNames('en', { select: 'official' });
            return Object.keys(list).map((key) => ({
                value: key,
                label: list[key],
            }));
        },
    },
    mounted() {
        this.institution_id = this.$route.params.id;
        if (this.institution_id !== '0') {
            this.getSchool();
        }
    },
};
</script>
