<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <h4>Coming Soon Page</h4>
    </div>
</template>
<script>

export default {
  name: 'StudentAbout'
}
</script>
