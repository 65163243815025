<template>
  <div>
    <div v-if="showForm === false" class="col-12 col-md-9">
      <div>Have you been refused a visa before?</div>
      <div>
        <input :checked="isRefused" :disabled="isRefused" type="radio" v-model="visaResponse" class="me-2" name="visaResponse" id="visa_true" value="visa_true"/>
        <label for="visa_true" class="me-3">Yes</label>
        <input type="radio" :checked="saidNoToRefused === false" v-model="visaResponse" :disabled="isRefused" class="me-2" name="visaResponse" id="visa_false" value="visa_false"/>
        <label for="visa_false" class="me-3">No</label>
      </div>
      <div v-if="(visaResponse == 'visa_true' || refusals.length > 0)" class="mt-3">
        <a href="#" @click.prevent="openBackgroundInfoForm" class="text-decoration-none primaryColor fw-bold"><i class="bi bi-plus"></i> Add Past Visa Refusal</a>
      </div>
      <div v-if="refusals && refusals.length > 0" class="mt-3">
        <div v-for="refus in refusals.slice().reverse()" v-bind:key="refus._id" class="d-flex mb-3 border rounded-3 bg-white p-3">
          <div class="col-9 col-md-6">
            <div class="fw-bold">{{findCountryName(refus.country)}}</div>
            <div><span>Refusal Date: </span> {{formatDateLocal(refus.date_of_refusal)}}</div>
          </div>
          <div class="col-3 col-md-6 d-flex align-items-center justify-content-end">
            <a href="#" @click.prevent="deleteBackground(refus._id)" class="text-decoration-none text-danger"><i class="bi bi-trash"></i></a>
            <a href="#" @click.prevent="editBackgroundInfoForm(refus._id)" class="text-decoration-none primaryColor ms-3"><i class="bi bi-pencil-square"></i></a>
          </div>
        </div>
      </div>

      <div v-if="visaResponse == 'visa_false'" class="mt-5">
          <button type="button" @click="saveNoBackground" class="btn primaryBtn">Save</button>
      </div>
    </div>
    <div v-if="showForm">
      <div class="mb-3">
        <a href="#" @click.prevent="closeBackgroundInfoForm" class="text-decoration-none primaryColor"><i class="bi bi-arrow-left"></i> Go Back</a>
      </div>
      <BackgroundInfoForm v-bind:backgroundInfoPass="this.formData"/>
    </div>
  </div>
</template>
<script>
import BackgroundInfoForm from '@/components/students/BackgroundInfoForm.vue'
import UserService from '@/apiServices/students/user.service'
import { mapActions } from 'vuex'
import { formatDate } from '@/utils/format'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default {
  name: 'BackgroundInfoHistory',
  components: {
    BackgroundInfoForm
  },
  data: function () {
    return {
      showForm: false,
      formData: null,
      visaResponse: null,
      refusals: [],
      fullResult: null
    }
  },
  methods: {
    ...mapActions({
      deleteBackgroundInfo: 'user/deleteBackgroundInfo',
      saveNoBackgroundInfo: 'user/saveNoBackgroundInfo',
      updateModal: 'general/updateInfo'
    }),
    getRefusals () {
      
      UserService.getData('get_background_info').then((resp) => {
        
        if (resp && resp.data) {
          this.fullResult = resp.data;
          this.refusals = resp.data?.background_info;
        }
      })
    },
    saveNoBackground () {
      
      this.saveNoBackgroundInfo().then(
        (data) => {
          
          if (data) {
            this.updateModal({ type: true, message: 'background info saved' })
          } else {
            this.updateModal({ type: false, message: 'an error occurred' })
          }
        }
      )
    },
    deleteBackground (id) {
      
      this.deleteBackgroundInfo(id).then(
        (data) => {
          
          if (data) {
            this.updateModal({ type: true, message: 'background info deleted' })
            this.getRefusals()
          } else {
            this.updateModal({ type: false, message: 'an error occurred' })
          }
        },
        error => {
          
          console.log(error)
        }
      )
    },
    openBackgroundInfoForm () {
      this.formData = null
      this.showForm = true
    },
    closeBackgroundInfoForm () {
      this.formData = null
      this.showForm = false
      this.getRefusals()
    },
    editBackgroundInfoForm (id) {
      this.formData = this.refusals.find(x => x._id === id)
      this.formData.date_of_refusal = this.formData.date_of_refusal.split('T')[0]
      this.showForm = true
    },
    findCountryName (ct) {
      return this.countries.find(x => x.value === ct).label
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    this.getRefusals()
  },
  computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },

    isRefused () {
      return (this.refusals != null && this.refusals.length > 0)
    },

    saidNoToRefused () {
      return this.fullResult?.refused_visa_before
    }
  }
}
</script>
