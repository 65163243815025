import axios from 'axios'
// import router from '../../router'
import { API_URL_ADMIN } from '../../utils/constants'
import store from '@/store/index'
import Notiflix from 'notiflix'

class AdminAuthService {
  login (user) {
    document.getElementById('pageLoader').classList.remove("d-none")
    Notiflix.Block.standard('#pageLoader')
    return axios
      .post(API_URL_ADMIN + 'login', {
        email: user.email,
        password: user.password
      })
      // .then(this.handleResponse)
      .then(response => {
        Notiflix.Block.remove('#pageLoader')
        document.getElementById('pageLoader').classList.add("d-none")
        return Promise.resolve(response.data)
      }).catch(this.handleResponseError)
  }

  logout () {
    store.dispatch('admin/logout')
    // router.push('/students/login')
  }

  register (user) {
    document.getElementById('pageLoader').classList.remove("d-none")
    Notiflix.Block.standard('#pageLoader')
    return axios.post(API_URL_ADMIN + 'sign-up', {
      user_name: user.username,
      email: user.email,
      password: user.password
    }).then(response => {
      Notiflix.Block.remove('#pageLoader')
      document.getElementById('pageLoader').classList.add("d-none")
      return Promise.resolve(response.data)
    }).catch(this.handleResponseError)
  }

  handleResponseError (err) {
    Notiflix.Block.remove('#pageLoader')
    document.getElementById('pageLoader').classList.add("d-none")
    Notiflix.Report.failure("Schooliply", "<div class='text-danger fw-bold'>An error occurred:</div><div>" + err.response.data.message + "</div>", "Okay!");
    if (err.response.data.status === 403) {
      this.logout()
    }
    return Promise.reject(err.response.data.message)
  }

  handleResponse (response) {
    Notiflix.Block.remove('#pageLoader')
    document.getElementById('pageLoader').classList.add("d-none")
    if (response.status === 401) {
      this.logout()

      const error = response.data && response.data.message
      return Promise.reject(error)
    }

    return Promise.resolve(response)
  }
}

export default new AdminAuthService()
