<template>
  <div>
    <div v-if="showForm === false" class="col-12 col-md-9">
      <h5 class="fw-bold">School attended</h5>
      <div v-if="canEdit">
        <a href="#" @click.prevent="openEducationForm" class="text-decoration-none primaryColor fw-bold"><i class="bi bi-plus"></i> Add School</a>
      </div>
      <div v-if="education && education.length > 0" class="mt-3">
        <div v-for="edu in education.slice().reverse()" v-bind:key="edu._id" class="d-flex mb-3 border rounded-3 bg-white p-3">
          <div class="col-9 col-md-6">
            <div class="fw-bold">{{edu.school_name}}</div>
            <div><span>Attended from </span> {{formatDateLocal(edu.attended_from)}} <span>to</span> {{formatDateLocal(edu.attended_to)}}</div>
            <div><span>Degree: </span> {{edu.degree}}</div>
          </div>
          <div class="col-3 col-md-6 d-flex align-items-center justify-content-end">
            <a v-if="canEdit" href="#" @click.prevent="deleteEducation(edu._id)" class="text-decoration-none text-danger"><i class="bi bi-trash"></i></a>
            <a href="#" @click.prevent="editEducationForm(edu._id)" class="text-decoration-none primaryColor ms-3"><i class="bi bi-pencil-square"></i></a>
          </div>
        </div>
      </div>
      <div v-else class="mt-3 text-danger">
        No information added yet
      </div>
    </div>
    <div v-if="showForm">
      <div class="mb-3">
        <a href="#" @click.prevent="closeEducationForm" class="text-decoration-none primaryColor"><i class="bi bi-arrow-left"></i> Go Back</a>
      </div>
      <EducationForm v-bind:educationInfoPass="this.formData" :canEdit="canEdit"/>
    </div>
  </div>
</template>
<script>
import EducationForm from '@/components/common/EducationForm.vue'
import { mapActions } from 'vuex'
import { formatDate } from '@/utils/format'
export default {
  name: 'EducationHistory',
  props: ['educationPass', 'canEdit'],
  components: {
    EducationForm
  },
  data: function () {
    return {
      showForm: false,
      formData: null,
      education: []
    }
  },
  methods: {
    ...mapActions({
      deleteEducationInfo: 'user/deleteEducationInfo',
      updateModal: 'general/updateInfo'
    }),
    deleteEducation (id) {
      
      this.deleteEducationInfo(id).then(
        (data) => {
          
          if (data) {
            this.updateModal({ type: true, message: 'educational info deleted' })
          } else {
            this.updateModal({ type: false, message: 'an error occurred' })
          }
        }
      )
    },
    openEducationForm () {
      this.formData = null
      this.showForm = true
    },
    closeEducationForm () {
      this.formData = null
      this.showForm = false
    },
    editEducationForm (id) {
      this.formData = this.education.find(x => x._id === id)
      this.formData.attended_from = this.formData.attended_from.split('T')[0]
      this.formData.attended_to = this.formData.attended_to.split('T')[0]
      this.showForm = true
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    this.education = this.educationPass
  }
}
</script>
