<template>
    <div v-if="showForm">
      <div class="mb-3">
        <a href="#" @click.prevent="closeDocumentForm" class="text-decoration-none primaryColor"><i class="bi bi-arrow-left"></i> Go Back</a>
      </div>
      <DocumentForm/>
    </div>
    <div v-if="!showForm">
      <div class="mb-4">
        <a href="#" @click.prevent="openDocumentForm" class="text-decoration-none primaryColor fw-bold"><i class="bi bi-plus"></i> Add Document</a>
      </div>
        <div v-if="docs === null || docs.length === 0">
            <div>You have no document uploaded</div>
        </div>
        <div v-else>
            <div v-for="doc in docs.slice().reverse()" :key="doc.id" class="mb-3 row">
                <div class="col-9 col-md-5">
                  <div class="fw-bold">{{doc.name}}</div>
                  <div v-if="doc.desc">{{doc.desc}}</div>
                  <div class="small">{{doc.type}}</div>
                </div>
                <div class="col-3 col-md-5 d-flex justify-content-center align-items-center">
                  <a :href="doc.url" target="_blank"><i class="bi bi-cloud-download"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ScoutService from '@/apiServices/scout/scout.service'
import DocumentForm from '@/components/scout/DocumentForm.vue'
import { mapActions } from 'vuex'
export default {
  name: 'DocumentList',
  components: {
    DocumentForm
  },
  data: function () {
    return {
      docs: null,
      showForm: false
    }
  },
  methods: {
    ...mapActions({
      //getAllDocuments: 'user/getAllDocuments',
      updateModal: 'general/updateInfo'
    }),
    closeDocumentForm () {
      this.showForm = false
    },
    openDocumentForm () {
      this.showForm = true
    },
    fetchDocs () {
      ScoutService.getData('get_all_documents/' + this.$route.params.studentId).then((resp) => {
        
        if (resp && resp.data) {
          this.docs = resp.data;
        }
      })
    }
  },
  mounted () {
    this.fetchDocs()
  }
}
</script>
