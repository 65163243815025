<template>
  <div class="mb-5 col-12 col-md-9">
    <h4 class="mb-0 fw-bold">{{ header }}</h4>
    <div class="small text-muted">
        <div v-html="subText"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['header', 'subText']
}
</script>
