<template>
  <div v-if="loaded">
    <personal-info :personalInfoUser="personalInfo" :userEmailPass="userEmail" :canEdit="false"></personal-info>
  </div>
</template>
<script>
import PersonalInfo from '@/components/common/PersonalInfo.vue'
import { Colors } from '@/utils/constants'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
export default {
  name: 'StudentPersonalInfo',
  components: {
    PersonalInfo
  },
  data: function () {
    return {
      authenticated: true,
      currentTab: 0,
      personalInfo: null,
      userEmail: null,
      loaded: false
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getStudentProfile () {
      
      AdminService.getData('get_student_personal_info/' + this.$route.params.id).then((resp) => {
        this.loaded = true
        if (resp && resp.data) {
          this.personalInfo = resp.data?.personal_info
          this.userEmail = resp.data?.email
        }
      })
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': Colors.primaryColor,
        'overflow-x': 'auto'
      }
    },
    currentUser () {
      return this.$store.state.admin.currentUser
    }
  },
  mounted () {
    if (this.currentUser == null) {
      this.$router.push('/admin/login')
      return
    }
    this.getStudentProfile()
  }
}
</script>
<style scoped>
  .tabButtons a{
    text-decoration: none;
    margin-right: 50px;
    font-weight: 500;
    color: gray;
  }

  .tabButtons a.router-link-exact-active{
    color: var(--primary-color);
  }
</style>
