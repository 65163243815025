<template>
    <div class="row">
        <div class="col-12 col-md-5">
            <label>Email</label>
            <input type="text" v-model="email" class="form-control mt-1" disabled/>
        </div>
        <div class="mt-4">
            <button @click="reset_password = true" type="button" class="btn primaryBtn px-5 py-2" 
                :disabled="reset_password">
                    Reset Password
                </button>
        </div>
    </div>
    <form @submit.prevent="resetPasswordSubmit">
            <div class="mt-4 row" v-if="reset_password">
            <div class="col-12 col-md-5">
                <label
                    >Enter New Password
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <input
                    type="password"
                    class="form-control"
                    v-model="new_password"
                    required
                />
            </div>
            <div class="mt-4">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save New Password
                </button>
                <button @click="clearPWDForm" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2">
                    Cancel
                </button>
            </div>
        </div>
        </form>
</template>
<script>
import { mapActions } from 'vuex'
import UserService from '@/apiServices/students/user.service'
    export default {
        name: 'AccountInfo',
        data: function() {
            return {
                email: '',
                reset_password: false,
                new_password: ''
            }
        },
        methods: {
            ...mapActions({
                updateModal: 'general/updateInfo'
            }),
            resetPasswordSubmit () {
                UserService.postData('update-password', {password: this.new_password})
                .then((resp) => {
                    if (resp && resp.data) {
                        this.updateModal({
                            type: true,
                            message: 'Password has been reset',
                        });
                        this.clearPWDForm();
                    }
                })
            },
            clearPWDForm () {
                this.new_password = ''; 
                this.reset_password = false
            },
        },
        mounted () {
            this.email = this.$store.state.user.currentUser?.uuser?.email;
        }
    }
</script>