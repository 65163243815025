<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Student" subText="Add New Student."/>

        <div class="col-12">
            <Form @submit="CreateUser" :validation-schema="personalInfoschema" :initial-values="personalInfoUser">
                <div class="row">
                    <div class="col-12 col-md-5">
                        <label>Email</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <Field v-model="personalInfo.email" name="email" class="form-control"/>
                        <ErrorMessage name="email" as="div" class="text-danger"/>
                    </div>
                </div>
                <div class="row mt-md-4">
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>First Name <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.first_name" name="first_name" class="form-control"/>
                        <ErrorMessage name="first_name" as="div" class="text-danger"/>
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Last Name <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.last_name" name="last_name" class="form-control"/>
                        <ErrorMessage name="last_name" as="div" class="text-danger"/>
                    </div>
                </div>
                <div class="row mt-md-4">
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Other Names</label>
                        <Field v-model="personalInfo.other_names" name="other_names" class="form-control"/>
                        <ErrorMessage name="other_names" as="div" class="text-danger"/>
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Date of Birth <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.D_O_B" name="D_O_B" class="form-control" type="date"/>
                        <ErrorMessage name="D_O_B" as="div" class="text-danger"/>
                    </div>
                </div>
                <div class="row mt-md-4">
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Nationality <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.nationality" name="nationality" class="form-select" as="select">
                            <option>Select your nationality</option>
                            <option v-for="country in countries" :value="country.value" :key="country.value">
                                {{ country.label }}
                            </option>
                        </Field>
                        <ErrorMessage name="nationality" as="div" class="text-danger"/>
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0 d-md-flex">
                        <div>
                            <div class="mb-1"><label>Marital Status <span class="text-danger ml-2 fw-bold">*</span></label></div>
                            <Field v-model="personalInfo.marital_status" type="radio" name="marital_status" value="S" id="single"/>
                            <label for="single" class="ms-1">Single</label>
                            <Field v-model="personalInfo.marital_status" type="radio" name="marital_status" value="M" id="married" class="ms-3"/>
                            <label for="married" class="ms-1">Married</label>
                            <ErrorMessage name="marital_status" as="div" class="text-danger"/>
                        </div>
                        <div class="ms-md-5 mt-3 mt-md-0">
                            <div class="mb-1"><label>Gender <span class="text-danger ml-2 fw-bold">*</span></label></div>
                            <Field v-model="personalInfo.gender" type="radio" name="gender" value="M" id="male"/>
                            <label for="male" class="ms-1">Male</label>
                            <Field v-model="personalInfo.gender" type="radio" name="gender" value="F" id="female" class="ms-3"/>
                            <label for="female" class="ms-1">Female</label>
                            <ErrorMessage name="gender" as="div" class="text-danger"/>
                        </div>
                    </div>
                </div>
                <div class="row mt-md-4">
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Primary Language <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.primary_language" name="primary_language" class="form-select" as="select">
                            <option>Select Language</option>
                            <option value="EN">English</option>
                            <option value="FR">French</option>
                            <option value="Other">Others</option>
                        </Field>
                        <ErrorMessage name="primary_language" as="div" class="text-danger"/>
                    </div>
                    <div class="col-12 col-md-5 mt-3 mt-md-0">
                        <label>Passport Number <span class="text-danger ml-2 fw-bold">*</span></label>
                        <Field v-model="personalInfo.passport_number" name="passport_number" class="form-control"/>
                        <ErrorMessage name="passport_number" as="div" class="text-danger"/>
                    </div>
                </div>
                <div class="mt-5">
                    <h5 class="fw-bold">Contact details</h5>
                    <div class="row mt-md-4">
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>Address Line 1 <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.address_line_1" name="address_line_1" class="form-control"/>
                            <ErrorMessage name="address_line_1" as="div" class="text-danger"/>
                        </div>
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>City/Town <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.city" name="city" class="form-control"/>
                            <ErrorMessage name="city" as="div" class="text-danger"/>
                        </div>
                    </div>
                    <div class="row mt-md-4">
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>Address Line 2</label>
                            <Field v-model="personalInfo.address_line_2" name="address_line_2" class="form-control"/>
                            <ErrorMessage name="address_line_2" as="div" class="text-danger"/>
                        </div>
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>State/Province <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.state" name="state" class="form-control"/>
                            <ErrorMessage name="state" as="div" class="text-danger"/>
                        </div>
                    </div>
                    <div class="row mt-md-4">
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>ZIP/Postal Code <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.postal_code" name="postal_code" class="form-control"/>
                            <ErrorMessage name="postal_code" as="div" class="text-danger"/>
                        </div>
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>Country <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.country" name="country" class="form-select" as="select">
                                <option>Select your country of residence</option>
                                <option v-for="country in countries" :value="country.value" :key="country.value">
                                    {{ country.label }}
                                </option>
                            </Field>
                            <ErrorMessage name="country" as="div" class="text-danger"/>
                        </div>
                    </div>
                    <div class="row mt-md-4">
                        <!--<div class="col-5">
                            <label>Email address <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field name="email" type="email" class="form-control"/>
                            <ErrorMessage name="email" as="div" class="text-danger"/>
                        </div>-->
                        <div class="col-12 col-md-5 mt-3 mt-md-0">
                            <label>Phone number <span class="text-danger ml-2 fw-bold">*</span></label>
                            <Field v-model="personalInfo.phone_number" name="phone_number" class="form-control"/>
                            <ErrorMessage name="phone_number" as="div" class="text-danger"/>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-12 col-md-10 text-end">
                        <button type="submit" class="btn primaryBtn">Save</button>
                    </div>
                </div>
            </Form>
        </div>
    </div>


</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import PageHeader from '@/components/PageHeader.vue'
import * as yup from 'yup'
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default {
  name: 'CreateNewStudent',
  components: {
    Form,
    Field,
    ErrorMessage,
    PageHeader
  },
  data: function () {
    const personalInfoschema = yup.object({
      first_name: yup.string().required().label('First Name'),
      last_name: yup.string().required().label('Last Name'),
      other_names: yup.string().nullable(),
      D_O_B: yup.date().typeError("Date is required").required().label('Date of birth'),
      nationality: yup.string().required().label('Nationality'),
      marital_status: yup.string().required().label('Marital Status'),
      gender: yup.string().required().label('Gender'),
      primary_language: yup.string().required().label('Primary Language'),
      passport_number: yup.string().required().label('Passport Number'),
      address_line_1: yup.string().required().label('Address'),
      city: yup.string().required().label('City'),
      address_line_2: yup.string().nullable(),
      state: yup.string().required().label('State / Province'),
      postal_code: yup.string().required().label('Postal Code'),
      country: yup.string().required().label('Country of residence'),
      email: yup.string().email().required().label('Email address'),
      phone_number: yup.string().required().label('Phone number')
    })

    return {
      personalInfoschema,
      personalInfo: {
        email: '',
        first_name: '',
        last_name: '',
        other_names: '',
        D_O_B: '',
        nationality: '',
        gender: '',
        marital_status: '',
        primary_language: '',
        passport_number: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        phone_number: ''
      }
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    CreateUser () {

      ScoutService.postData('create_user_personalInfo', this.personalInfo).then((resp) => {
        
        if (resp && resp.data) {
          this.updateModal({ type: true, message: 'Account Created' });
          this.$router.push({name: "ScoutStudentsList"});
        }
      })
    }
  },
  computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
    personalInfoUser () {
      return this.personalInfo
    },
  },
  mounted () {
    // console.log("User OBJ", this.$store.state.user.currentUser?.uuser)
  }
}
</script>
