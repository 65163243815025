<template>
  <div class="container-fluid mt-3">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column align-items-start ms-md-4">
        <div>
          <router-link to="/">
            <img src="/assets/images/Schooliply-Logo-Dark.png" width="150" class="imageNegMargin"/>
          </router-link>
        </div>
      </div>
    </div>
    <div class="mt-5 row d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-6">
            <h1 class="fw-bold">Congratulations</h1>
            <div class="small text-muted">
                Your account has been created.
            </div>
            <div class="mt-4">
                <router-link to="/students/login" class="primaryColor text-decoration-none fw-bold">Log in to your account</router-link>
            </div>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-md-0 d-flex justify-content-center align-items-center">
            <img src="/assets/images/signUpSuccess.png" style="max-width: 500px;width: 100%;"/>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignUpSuccess'
}
</script>
