<template>
    <div>
        <div v-if="payments.length == 0" class="text-center my-4">
          <img src="assets/images/no-payments.png" width="150"/>
          <div class="mt-4 text-gray">You have not made any payments yet. When you do, they’ll appear here.</div>
          <div class="mt-4">
            <router-link to="/students/find-programs/schools" class="btn primaryBtn text-decoration-none py-2 px-4">Find Schools/Programs</router-link>
          </div>
        </div>
        <div v-if="payments.length > 0" class="col-12 col-md-10 tableParent">
          <!--<table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>Payment ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pay in payments" :key="pay._id">
                  <td>{{pay._id}}</td>
                  <td>{{pay.currency_symbol}}{{toCurrency(pay.amount_paid)}}</td>
                  <td>Application Fee</td>
                  <td>{{formatDateLocal(pay.date_of_payment)}}</td>
              </tr>
            </tbody>
          </table>-->
          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>
          
          <EasyDataTable
            :headers="headers"
            :items="payments"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-type>
            <span>Application Fee</span>
          </template>
          <template #item-date_of_payment="date">
            {{formatDateLocal(date.date_of_payment)}}
          </template>
          <template #item-amount_paid="pay">
            {{pay.currency_symbol}}{{toCurrency(pay.amount_paid)}}
          </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
import { formatDate } from '@/utils/format'
import { toCurrency } from '@/utils/constants'
export default {
  name: 'ScoutStudentPayments',
  components: {
    
  },
  data: function () {
    return {
      payments: [],
      searchvalue: "",
      headers: [
        { text: "Payment ID", value: "_id" },
        { text: "Amount", value: "amount_paid", sortable: true },
        { text: "Type", value: "type" },
        { text: "Date Paid", value: "date_of_payment", sortable: true }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    toCurrency,
    getPayments () {
      
      ScoutService.getData('get_all_payments/' + this.$route.params.studentId).then((resp) => {
        
        if (resp && resp.data) {
          this.payments = resp.data.filter(x => x.amount_to_pay === x.amount_paid)
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    if (this.$store.state.scout.currentUser != null) {
      this.getPayments()
    }
  }
}
</script>
