<template>
    <!-- New code -->
    <div class="container mt-3">
        <div>
            <router-link to="/">
                <img
                    src="/assets/images/Schooliply-Logo-Dark.png"
                    width="150"
                    class="imageNegMargin"
                />
            </router-link>
        </div>
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12 col-md-6 d-flex flex-column align-items-start">
                <img
                    src="./../../assets/admin-login.png"
                    alt="Admin's login"
                    class="w-100 max-400"
                />

                <!-- <div class="mt-2 mb-4 d-flex justify-between align-center">
                    <a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/recruiter-logo.png"
                            alt="recruiter logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a recruiter</span
                        >
                    </a>
                    <a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/school-logo.png"
                            alt="school logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a school</span
                        >
                    </a>
                </div> -->
            </div>

            <div class="col-12 col-md-5 mt-4 offset-md-1 align-self-start">
                <div class="pb-4 px-3 mb-2 text-center">
                    <h2 class="pt-4 pt-md-0 fw-bold text-center start-applying">
                        Hello Admins
                    </h2>
                    <div class="text-secondary medium pb-2">
                        Sign into your accout
                    </div>
                    <form @submit.prevent="signIn">
                        <!-- <div class="mb-3 pt-4">
                            <label for="signUpUsername" class="form-label small"
                                >Username</label
                            >
                            <input
                                type="text"
                                v-model="username"
                                class="form-control"
                                id="signUpUsername"
                                required
                                placeholder="UserName"
                            />
                        </div> -->
                        <div class="mb-3">
                            <!-- <label for="loginEmail" class="form-label small"
                                >Email address</label
                            > -->
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="loginEmail"
                                aria-describedby="emailHelp"
                                required
                                placeholder="Email Address"
                            />
                        </div>
                        <div class="mb-3">
                            <!-- <label for="signUpPassword" class="form-label small"
                                >Password</label
                            > -->
                            <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="signUpPassword"
                                required
                                placeholder="Password"
                            />
                        </div>
                        <!-- <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <div class="single-line"></div>
                            <span class="small mx-2">or</span>
                            <div class="single-line"></div>
                        </div>
                        <div
                            class="mt-2 d-flex justify-between align-items-center"
                        >
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/google-logo.png"
                                    alt="recruiter logo"
                                />
                                <span class="small">Sign up with Google</span>
                            </a>
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/facebook-logo.png"
                                    alt="school logo"
                                />
                                <span class="small">Sign up with Facebook</span>
                            </a>
                        </div> -->

                        <button type="submit" class="btn primaryBtn mt-4 w-100">
                            Sign In
                        </button>
                        <div class="mt-4 px-4 text-center small">
                            By signing up, you agree to our Terms of use and
                            Privacy policy
                        </div>
                    </form>
                    <div class="mt-3 d-md-none">
                        <span>Don't have an account? </span
                        ><a
                            href="#/students/signup"
                            class="primaryColor text-decoration-none"
                            >Sign Up</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Old code -->
    <!-- <div class="container-fluid mt-5">
        <div class="row">
            <div
                class="col-12 col-md-6 d-flex flex-column align-items-start ms-md-4"
            >
                <div>
                    <router-link to="/">
                        <img
                            src="/assets/images/Schooliply-Logo-Dark.png"
                            width="150"
                            class="imageNegMargin"
                        />
                    </router-link>
                </div>
                <h2 class="mt-3 mt-md-5 pt-4 pt-md-5 fw-bold">
                    Hello Admins
                </h2>
                <div class="text-secondary small">sign in to continue.</div>
                
            </div>

            <div class="col-12 col-md-4 mt-4 mt-md-5 offset-md-1">
                <div
                    class="mt-3 border rounded-3 pt-3 pt-md-5 pb-4 px-4 mb-2 mb-md-0 bg-white"
                >
                    <form @submit.prevent="signIn">
                        <div class="mb-3 pt-4">
                            <label for="loginEmail" class="form-label small"
                                >Email address</label
                            >
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="loginEmail"
                                aria-describedby="emailHelp"
                                required
                            />
                        </div>
                        <div class="mb-3">
                            <label for="loginPassword" class="form-label small"
                                >Password</label
                            >
                            <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="loginPassword"
                                required
                            />
                        </div>
                        <button type="submit" class="btn primaryBtn mt-4 w-100">
                            Sign In
                        </button>

                        <div class="mt-4 px-4 text-center small">
                            By signing up, you agree to our Terms of use and
                            Privacy policy
                        </div>
                    </form>
                </div>
                <div class="mt-3 d-md-none">
                    <span>Don't have an account? </span
                    ><a
                        href="#/students/signup"
                        class="primaryColor text-decoration-none"
                        >Sign Up</a
                    >
                </div>
            </div>
        </div>
    </div> -->
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data: function () {
        return {
            loading: false,
            email: '',
            password: '',
            message: '',
        };
    },
    methods: {
        ...mapActions({
            callSignIn: 'admin/login',
            updateModal: 'general/updateInfo',
        }),
        signIn: function () {
            // this.$store.dispatch('user/signIn')
            if (this.email && this.password) {
                var user = {
                    email: this.email,
                    password: this.password,
                };

                this.callSignIn(user).then(() => {
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        this.$router.push('/admin/');
                    }
                });
            }
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.admin.status.loggedIn; // && (this.$store.state.admin?.uuser?.role === 'admin' || this.$store.state.admin?.uuser?.role === 'intermediate-admin')
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/admin');
        }
    },
};
</script>
<style scoped>
.start-applying {
    color: #5a189a;
}
</style>
