<template>
    <div class="container pt-5 px-3 px-md-5">
        <page-header
            header="Notifications"
            subText="These are your notifications"
        ></page-header>
        <div v-if="noties.length == 0" class="text-center my-4">
            <img src="assets/images/no-notifications.png" width="150" />
            <div class="mt-4 text-gray">
                You do not have any notifications yet. When you do, they’ll
                appear here.
            </div>
        </div>
        <div class="row mt-4 mt-md-5" v-else>
            <div class="col-12 col-md-10">
                <div class="accordion accordion-flush">
                    <div
                        v-for="(noty, index) in noties"
                        :key="noty._id"
                        class="accordion-item mb-3"
                    >
                        <h2
                            class="accordion-header"
                            :id="'noty-header-' + index"
                        >
                            <div
                                class="accordion-button bg-light collapsed px-0"
                                :class="
                                    noty.is_read
                                        ? ' text-muted'
                                        : ' text-dark fw-bold'
                                "
                                @click="marNotyRead(noty._id, noty.is_read)"
                                type="button"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#noty-' + index"
                                aria-expanded="false"
                                :aria-controls="'#noty-' + index"
                            >
                                <i
                                    class="bi bi-envelope-open"
                                    v-if="noty.is_read"
                                ></i>
                                <i class="bi bi-envelope" v-else></i>
                                <div class="ms-2">{{ noty.title }}</div>
                            </div>
                        </h2>
                        <div
                            :id="'noty-' + index"
                            class="accordion-collapse collapse"
                            :aria-labelledby="'noty-header-' + index"
                        >
                            <div class="accordion-body py-0">
                                {{ noty.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { formatDate } from '@/utils/format';
export default {
    name: 'AdminNotificaitons',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            authenticated: true,
            noties: [],
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
            reduceNoty: 'admin/reduceNotificationCount'
        }),
        marNotyRead(id, read) {
            if (!read) {
                AdminService.getData('mark_notifications_read/' + id)
                    .then((resp) => {
                        if (resp && resp.data) {
                            this.noties.find(
                                (x) => x._id === id
                            ).is_read = true;
                            this.reduceNoty();
                        }
                    })
                    .catch(() => {});
            }
        },
        getNoties() {
            AdminService.getData('get_notifications')
                .then((resp) => {
                    if (resp && resp.data) {
                        this.noties = resp.data;
                    }
                })
        },
        formatDateLocal(dt) {
            return formatDate(dt);
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.admin.currentUser;
        },
    },
    mounted() {
        if (this.currentUser == null) {
            this.$router.push('/admin/login');
            return;
        }
        this.getNoties();
    },
};
</script>
