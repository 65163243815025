<template>
  <div>
    <div v-if="showForm === false" class="col-12 col-md-9">
      <h5 class="fw-bold">Exam Details</h5>
      <div v-if="canEdit">
        <div class="small mb-3">Have you written any international exam? If yes, add it below</div>
        <a href="#" @click.prevent="openExamForm" class="text-decoration-none primaryColor fw-bold"><i class="bi bi-plus"></i> Add Test</a>
      </div>
      <div v-if="exams && exams.length > 0" class="mt-3">
        <div v-for="ex in exams.slice().reverse()" v-bind:key="ex._id" class="d-flex mb-3 border rounded-3 bg-white p-3">
          <div class="col-9 col-md-6">
            <div class="fw-bold">{{ex.exam_name}}</div>
            <div><span>Exam date: </span> {{formatDateLocal(ex.exam_date)}}</div>
          </div>
          <div class="col-3 col-md-6 d-flex align-items-center justify-content-end">
            <a v-if="canEdit" href="#" @click.prevent="deleteExam(ex._id)" class="text-decoration-none text-danger"><i class="bi bi-trash"></i></a>
            <a href="#" @click.prevent="editExamForm(ex._id)" class="text-decoration-none primaryColor ms-3"><i class="bi bi-pencil-square"></i></a>
          </div>
        </div>
      </div>
      <div v-else class="mt-3 text-danger">
        No information added yet
      </div>
    </div>
    <div v-if="showForm">
      <div class="mb-3">
        <a href="#" @click.prevent="closeExamForm" class="text-decoration-none primaryColor"><i class="bi bi-arrow-left"></i> Go Back</a>
      </div>
      <ExamScoresForm v-bind:examScoreUserPass="this.formData" :canEdit="canEdit"/>
    </div>
  </div>
</template>
<script>
import ExamScoresForm from '@/components/common/ExamScoresForm.vue'
import { mapActions } from 'vuex'
import { formatDate } from '@/utils/format'
export default {
  name: 'ExamScoresHistory',
  props: ['examsPass', 'canEdit'],
  components: {
    ExamScoresForm
  },
  data: function () {
    return {
      showForm: false,
      formData: null,
      exams: []
    }
  },
  methods: {
    ...mapActions({
      deleteExamInfo: 'user/deleteExamScore',
      updateModal: 'general/updateInfo'
    }),
    deleteExam (id) {
      
      this.deleteExamInfo(id).then(
        (data) => {
          
          if (data) {
            this.updateModal({ type: true, message: 'exam score deleted' })
          } else {
            this.updateModal({ type: false, message: 'an error occurred' })
          }
        }
      )
    },
    openExamForm () {
      this.formData = null
      this.showForm = true
    },
    closeExamForm () {
      this.formData = null
      this.showForm = false
    },
    editExamForm (id) {
      this.formData = this.exams.find(x => x._id === id)
      this.formData.exam_date = this.formData.exam_date.split('T')[0]
      this.showForm = true
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    this.exams = this.examsPass
  }
}
</script>
