<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Schools and Programs" :subText="header" />
        <form @submit.prevent="submitApplyForm">
            <div class="row">
                <div class="col-12 col-md-5">
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="smaller fw-bold">Application ID</div>
                            <div class="text-gray small">
                                {{ application_id }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6" v-if="application">
                            <span
                                class="badge rounded-pill bg-success text-dark py-2 px-3 text-white"
                                v-if="
                                    application.payment.amount_to_pay <=
                                    application.payment.amount_paid
                                "
                                >Paid</span
                            >
                            <span
                                class="badge rounded-pill bg-warning text-dark py-2 px-3"
                                v-else
                                >Pending payment</span
                            >
                        </div>
                        <div class="mt-3 col-12 col-md-6" v-if="isPaid">
                            <div class="smaller fw-bold">
                                Application Tracker
                            </div>
                            <app-tracker
                                :currentStepPass="calcTracker(application)"
                            ></app-tracker>
                        </div>
                    </div>
                    <div>
                        <div class="text-muted">
                            Academic session
                            <span class="text-danger ml-2 fw-bold">*</span>
                        </div>
                        <select
                            class="form-select"
                            v-model="selected_session"
                            @change="change_session"
                            required
                            :disabled="application && application.is_submitted"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="sess in program?.available_sessions"
                                :key="sess._id"
                                :value="sess.name"
                            >
                                {{ sess.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div
                    class="col-12 col-md-4 mt-4 mt-md-0"
                    v-if="application?.scout_id == null"
                >
                    <h5>Student Record</h5>
                    <div class="mb-3">
                        <div class="smaller fw-bold">NAME</div>
                        <div class="text-gray">
                            {{ user?.personal_info?.first_name }}
                            {{ user?.personal_info?.last_name }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="smaller fw-bold">EMAIL</div>
                        <div class="text-gray">
                            {{ user?.email }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <div v-if="user != null">
                            <router-link
                                :to="{
                                    name: 'StudentProfile',
                                    params: { id: user?._id },
                                }"
                                target="_blank"
                                class="text-decoration-none primaryColor fw-bold"
                                >View Profile
                                <i class="bi bi-arrow-up-right ms-1"></i
                            ></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5" v-if="application?.scout_id">
                <div class="col-12 col-md-4 mt-4 mt-md-0">
                    <h5>Student Record</h5>
                    <div class="mb-3">
                        <div class="smaller fw-bold">NAME</div>
                        <div class="text-gray">
                            {{ user?.personal_info?.first_name }}
                            {{ user?.personal_info?.last_name }}
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="smaller fw-bold">EMAIL</div>
                        <div class="text-gray">
                            {{ user?.email }}
                        </div>
                    </div>
                    <div class="mt-3">
                        <div v-if="user != null">
                            <router-link
                                :to="{
                                    name: 'StudentProfile',
                                    params: { id: user?._id },
                                }"
                                target="_blank"
                                class="text-decoration-none primaryColor fw-bold"
                                >View Profile
                                <i class="bi bi-arrow-up-right ms-1"></i
                            ></router-link>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-5 mt-4 mt-md-0"
                    v-if="application?.scout_id"
                >
                    <div>
                        <h5>Scout Information</h5>
                        <div class="mb-3">
                            <div class="smaller fw-bold">NAME</div>
                            <div class="text-gray">
                                {{ application?.scout_id?.first_name }}
                                {{ application?.scout_id?.last_name }}
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="smaller fw-bold">EMAIL</div>
                            <div class="text-gray">
                                {{ application?.scout_id?.email }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-4" v-if="program">
                    <div
                        class="d-flex mb-4 align-items-center"
                        v-if="program.university"
                    >
                        <div>
                            <img
                                :src="program.university.logo"
                                class="rounded-circle"
                                width="45"
                                height="45"
                            />
                        </div>
                        <div class="ms-3">
                            <h6 class="mb-0">{{ program.university.name }}</h6>
                            <div class="small">
                                {{ program.university.address }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Name</div>
                        <div class="fw-bolds">{{ program.name }}</div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Type</div>
                        <div>{{ program.program_type }}</div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Application Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(markUpApplicationFee(program.application_fee)) }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Tuition Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(program.tuition_fee) }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Application Deadline</div>
                        <div>{{ application_deadline_text }}</div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Resumption Date</div>
                        <div>{{ resumption_date_text }}</div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-5 mt-4 mt-md-0"
                    v-if="program && program.required_docs?.length > 0"
                >
                    <div class="mb-4"><h5>Application Requirements</h5></div>
                    <div
                        class="mb-4"
                        v-for="req in program.required_docs"
                        :key="req._id"
                    >
                        <div class="small">
                            {{ req.name }}
                            <span
                                v-if="req.isRequired"
                                class="text-danger ml-2 fw-bold"
                                >*</span
                            >
                        </div>
                        <div class="smaller">{{ req.desc }}</div>
                        <select
                            class="form-select mt-2 req_docs"
                            :required="req.isRequired"
                            :data-id="req._id"
                            :disabled="application && application.is_submitted"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="doc in docs"
                                :key="doc._id"
                                :value="doc._id"
                            >
                                {{ doc.name }} - {{ doc.desc }}
                            </option>
                        </select>
                    </div>
                    <!-- Emergency contact -->
                    <div><h5 class="mb-1">Emergency Contact</h5></div>
                    <div class="mb-4">
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_name"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-details-relationship"
                                >Relationship to you
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                name="emergency-details-relationship"
                                class="form-control"
                                v-model="emergency_contact_relationship"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Email
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="email"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_email"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label
                                class="text-muted small"
                                for="emergency-phone"
                                >Phone Number
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >

                            <input
                                type="tel"
                                name="emergency-phone"
                                class="form-control"
                                v-model="emergency_contact_phone_number"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div>
                            <label
                                class="text-muted small"
                                for="emergeny-address"
                                >Address
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                name="emergency-address"
                                v-model="emergency_contact_address"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12 col-md-5">
                    <h5>Application Tracker</h5>
                    <div class="mb-3">
                        <div class="text-muted">
                            Current Status
                            <span class="text-danger ml-2 fw-bold">*</span>
                        </div>
                        <select
                            class="form-select"
                            @change="updateDesc"
                            v-model="selectedTracker"
                        >
                            <option
                                v-for="trk in application_tracker"
                                :key="trk.status"
                                :value="trk.status"
                            >
                                {{ trk.status }}
                            </option>
                        </select>
                        <div class="mt-1 small">{{ tracker_desc }}</div>
                    </div>
                    <div class="">
                        <button
                            type="button"
                            class="btn primaryBtn px-5 py-2"
                            @click="saveApplicationTracker"
                        >
                            Save Application Tracker
                        </button>
                    </div>
                </div>

                <div class="col-12 col-md-5 mt-4 mt-md-0">
                    <h5 class="primaryColor fw-bold">Final Decision</h5>
                    <div v-if="application?.application_complete">
                        <div class="mb-3">
                            <div class="text-muted">
                                Is Application Complete
                            </div>
                            <div
                                :class="
                                    application?.application_complete
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            >
                                {{
                                    application?.application_complete
                                        ? 'YES'
                                        : 'NO'
                                }}
                            </div>
                        </div>
                        <div v-if="application?.declined_by_schooliply">
                            <div class="mb-3">
                                <div class="text-muted">
                                    Declined by Schooliply
                                </div>
                                <div class="text-danger">YES</div>
                            </div>
                            <div class="mb-3">
                                <div class="text-muted">
                                    Reason for declining
                                </div>
                                <div class="">
                                    {{ application?.reason_for_decline }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="application?.declined_by_schooliply == false"
                        >
                            <div class="mb-3">
                                <div class="text-muted">
                                    Admission Given By School
                                </div>
                                <div
                                    :class="
                                        application?.admission_granted
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                >
                                    {{
                                        application?.admission_granted
                                            ? 'YES'
                                            : 'NO'
                                    }}
                                </div>
                            </div>
                            <div
                                class="mb-3"
                                v-if="
                                    application?.decision_documents?.length > 0
                                "
                            >
                                <h6 class="primaryColor fw-bold">
                                    Decision documents
                                </h6>
                                <div
                                    v-for="doc in application?.decision_documents"
                                    :key="doc._id"
                                    class="mb-3 row"
                                >
                                    <div class="col-9 col-md-5">
                                        <div class="fw-bold">
                                            {{ doc.file_type }}
                                        </div>
                                    </div>
                                    <div class="col-3 col-md-5">
                                        <a
                                            :href="doc.path_to_file"
                                            target="_blank"
                                            ><i
                                                class="bi bi-cloud-download"
                                            ></i>
                                            Download</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button
                            type="button"
                            class="btn primaryBtn px-5 py-2"
                            data-bs-toggle="modal"
                            data-bs-target="#appTrackerModal"
                        >
                            {{
                                application?.application_complete
                                    ? 'Update Completion Status'
                                    : 'Complete application'
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
        <!-- Notes code -->
        <div class="mt-5">
            <h5>Admin Notes</h5>
            <div v-for="note in notes" :key="note._id" class="">
                <div class="p-3 card mb-3 col-lg-6 col">
                    <!-- display notes here -->
                    <!-- <p
                        v-if="
                            !(note.note.length > numberOfCharacters) &&
                            !readMoreActivated
                        "
                    >
                        {{ note.note }}
                    </p>

                    <p
                        v-if="
                            note.note.length > numberOfCharacters &&
                            !readMoreActivated
                        "
                    >
                        {{ `${note.note.slice(0, numberOfCharacters)} ... ` }}
                    </p>
                    <a
                        href="#"
                        @click.prevent="activateReadMore"
                        v-if="
                            note.note.length > numberOfCharacters &&
                            !readMoreActivated
                        "
                        >Read more</a
                    >

                    <p v-if="readMoreActivated">
                        {{ note.note }}
                    </p>
                    <a
                        class="link d-inline-block"
                        href="#"
                        @click.prevent="deactivateReadMore"
                        v-if="
                            note.note.length > numberOfCharacters &&
                            readMoreActivated
                        "
                        >Read less</a
                    > -->
                    <p>
                        {{ note.note }}
                    </p>

                    <div class="mt-3">
                        <span class="d-block small text-muted"
                            >By:
                            {{
                                note.created_by.first_name +
                                ' ' +
                                note.created_by.last_name
                            }}</span
                        >
                        <span v-if="note.created_on" class="small text-muted"
                            >Created on:
                            {{ formatDateLocal(note.created_on) }}
                        </span>
                    </div>
                </div>
            </div>
            <!-- display addition of notes section here -->
            <div class="mb-3 d-flex flex-column col col-lg-6">
                <label for="notes">Type note below</label>
                <textarea
                    name="notes"
                    id="notes"
                    rows="5"
                    v-model="note_text"
                ></textarea>
                <button
                    class="btn primaryBtn align-self-start mt-3"
                    @click="addNote"
                >
                    Add note
                </button>
            </div>
        </div>
    </div>
    <!-- Modal code -->
    <div
        class="modal"
        id="appTrackerModal"
        tabindex="-1"
        aria-labelledby="modal-title"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modal-title">
                        Complete application
                    </h5>
                    <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mt-3">
                            <label class="mr-2"
                                >Has the application been declined by
                                Schooliply?
                                <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                            </label>
                            <select
                                v-model="appChecklist.declined_by_schooliply"
                                name="declined_by_schooliply"
                                class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                            >
                                <option value="">--Select--</option>
                                <option :value="true">Yes</option>
                                <option :value="false">No</option>
                            </select>
                        </div>
                        <div
                            class="mt-3"
                            v-if="appChecklist.declined_by_schooliply === true"
                        >
                            <label class="mr-2" for="reason_for_decline"
                                >Reason for declining application
                                <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                            </label>
                            <textarea
                                class="form-control"
                                name="reason_for_decline"
                                v-model="appChecklist.reason_for_decline"
                            >
                            </textarea>
                        </div>
                        <!-- No need to show the rest if it is already declined by schooliply-->

                        <div
                            v-if="appChecklist.declined_by_schooliply === false"
                        >
                            <div class="mt-3">
                                <!-- Show this only if user has not picked an option -->
                                <label class="mr-2"
                                    >Has admission been granted?
                                    <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                                </label>
                                <select
                                    v-model="appChecklist.admission_granted"
                                    class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                                    name="admission_granted"
                                    as="select"
                                >
                                    <option value="">--Select--</option>
                                    <option :value="true">Yes</option>
                                    <option :value="false">No</option>
                                </select>
                            </div>

                            <div
                                class="col-12 mt-2"
                                v-if="appChecklist.admission_granted !== ''"
                            >
                                <label class="fw-bold mt-2"
                                    >Upload files
                                    <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                                </label>
                                <label
                                    >Type of file(eg. Rejection Letter,
                                    Acceptance Letter, Invoice/Recept etc.)
                                    <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                                </label>
                                <div
                                    class="mb-3 card p-3 mb-6"
                                    style="width: 28.5rem"
                                    v-for="(
                                        doc, index
                                    ) in appChecklist.decision_documents"
                                    :key="index"
                                >
                                    <select
                                        v-model="doc.file_type"
                                        :key="doc.file_type"
                                        class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                                        name="fileType"
                                    >
                                        <option value="null">
                                            --file-type--
                                        </option>
                                        <option
                                            v-for="ft in filteredListOfFiles"
                                            :key="ft.value"
                                            :value="ft.value"
                                        >
                                            {{ ft.name }}
                                        </option>
                                    </select>

                                    <div class="mt-3">
                                        <label for="file-upload"
                                            >Upload Document</label
                                        >
                                        <input
                                            id="file-upload"
                                            name="file-upload"
                                            type="file"
                                            accept=".doc, .docx, .pdf"
                                            v-if="doc.path_to_file == ''"
                                            @change="onFileChange($event, doc)"
                                        />
                                        <input
                                            name="path_to_file"
                                            class="form-control"
                                            type="text"
                                            :disabled="
                                                application &&
                                                application.is_submitted
                                            "
                                            v-model="doc.path_to_file"
                                            v-else
                                        />
                                        <button
                                            class="btn btn-link"
                                            v-if="doc.path_to_file != ''"
                                            @click="replaceDocument(doc)"
                                        >
                                            Replace Document
                                        </button>
                                    </div>
                                    <div class="mt-3">
                                        <button
                                            type="button"
                                            class="btn btn-danger"
                                            @click="deleteRequiredDocs(index)"
                                        >
                                            Delete document entry
                                        </button>
                                    </div>
                                </div>
                                <div class="mt-2 text-end">
                                    <button
                                        type="button"
                                        class="btn btn-primary"
                                        @click="addRequiredDocs"
                                    >
                                        Add document entry
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <div class="mt-3">
                        <button
                            v-if="appChecklist.declined_by_schooliply !== ''"
                            type="button"
                            class="btn primaryBtn px-5 py-2"
                            @click="submitAppChecklist"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import AppTracker from '@/components/common/Tracker.vue';
import { applicationTracker, toCurrency, markUpApplicationFee, calcTracker } from '@/utils/constants';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { Notify } from 'notiflix';
import { Modal } from 'bootstrap';
// import { Form, Field, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { formatDate } from '@/utils/format';
export default {
    name: 'ViewApplication',
    components: {
        PageHeader,
        AppTracker,
    },
    data: function () {
        const appChecklistSchema = yup.object({
            declined_by_schooliply: yup
                .boolean()
                .required('You have to give a decision between yes and no'),
            reason_for_decline: yup.string().when('declined_by_schooliply', {
                is: true,
                then: yup
                    .string()
                    .required(
                        'You have to give a reason for declining the application'
                    ),
            }),
            admission_granted: yup.boolean().when('declined_by_schooliply', {
                is: false,
                then: yup
                    .boolean()
                    .required('You have to give a decision between yes and no'),
            }),
            documents: yup.array().of(
                yup.object({
                    fileType: yup.string().when('declined_by_schooliply', {
                        is: false,
                        then: yup.string().required('Type of file is required'),
                    }),
                    file: yup.string().when('declined_by_schooliply', {
                        is: false,
                        then: yup.string().required('File is missing'),
                    }),
                })
            ),
        });
        return {
            appChecklistSchema,
            program: null,
            user: null,
            header: 'Schools and Programs',
            application_id: '0',
            docs: [],
            application_deadline_text: 'Select Academic session first',
            resumption_date_text: 'Select Academic session first',
            selected_session: '',
            application: null,
            isPaid: false,
            selectedTracker: '',
            application_tracker: [],
            tracker_desc: '',
            appChecklist: {
                declined_by_schooliply: '',
                reason_for_decline: '',
                admission_granted: '',
                decision_documents: [
                    {
                        file_type: null,
                        path_to_file: '',
                    },
                ],
            },
            list_of_files: [
                {
                    value: 'rejection',
                    name: 'Rejection Letter',
                    type: 'isRejected',
                },
                {
                    value: 'acceptance',
                    name: 'Acceptance Letter',
                    type: 'isAccepted',
                },
                {
                    value: 'offer-conditional',
                    name: 'Offer Letter - Conditional',
                    type: 'isAccepted',
                },
                {
                    value: 'offer-unconditional',
                    name: 'Offer Letter - Unconditional',
                    type: 'isAccepted',
                },
                { value: 'invoice', name: 'Invoice / Receipt', type: 'both' },
                { value: 'others', name: 'Others', type: 'both' },
            ],
            modalErr: false,
            emergency_contact_name: '',
            emergency_contact_relationship: '',
            emergency_contact_phone_number: '',
            emergency_contact_address: '',
            emergency_contact_email: '',
            notes: [],
            note_text: '',
            numberOfCharacters: 200,
            readMoreActivated: false,
        };
    },
    computed: {
        filteredListOfFiles() {
            return this.list_of_files.filter((el) => {
                if (this.appChecklist.admission_granted === true) {
                    return el.type === 'isAccepted' || el.type === 'both';
                } else if (this.appChecklist.admission_granted === false) {
                    return el.type === 'isRejected' || el.type === 'both';
                }
            });
        },
    },
    mounted() {
        if (this.$store.state.admin.currentUser != null) {
            this.getAppDetails();
        }
        this.application_tracker = applicationTracker.filter((x) => x.dropdown);
    },

    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
            getAllDocuments: 'user/getAllDocuments',
        }),
        toCurrency,
        markUpApplicationFee,
        saveApplicationTracker() {
            if (this.selectedTracker === '') {
                return;
            }

            const obj = {
                id: this.application_id,
                value: this.selectedTracker,
            };
            AdminService.postData('update_application_tracker', obj).then(
                (resp) => {
                    if (resp.data) {
                        this.application = this.selectedTracker;
                        this.updateModal({
                            type: true,
                            message: 'Tracker updated',
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        updateDesc() {
            if (this.selectedTracker === '') {
                this.tracker_desc = '';
            } else {
                this.tracker_desc = this.application_tracker.find(
                    (x) => x.status === this.selectedTracker
                )?.desc;
            }
        },
        getAppInfo() {
            return AdminService.getData(
                'get_app_details/' + this.$route.params.id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getUserDocs() {
            return AdminService.getData(
                'get_user_docs/' + this.$route.params.user_id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getAppDetails() {
            this.application_id = this.$route.params.id;
            Promise.all([this.getAppInfo(), this.getUserDocs()])
                .then(([app, doc]) => {
                    if (app) {
                        this.selected_session = app.academic_session;
                        this.emergency_contact_name =
                            app.emergency_contact_name;
                        this.emergency_contact_relationship =
                            app.emergency_contact_relationship;
                        this.emergency_contact_phone_number =
                            app.emergency_contact_phone_number;
                        this.emergency_contact_address =
                            app.emergency_contact_address;
                        this.emergency_contact_email =
                            app.emergency_contact_email;
                        this.application = app;
                        this.appChecklist.declined_by_schooliply =
                            app.declined_by_schooliply ?? '';
                        this.appChecklist.reason_for_decline =
                            app.reason_for_decline ?? '';
                        this.appChecklist.admission_granted =
                            app.admission_granted ?? '';
                        this.appChecklist.decision_documents =
                            app.decision_documents ??
                            this.appChecklist.decision_documents;
                        this.isPaid =
                            this.application.is_submitted &&
                            this.application.payment &&
                            this.application.payment.amount_to_pay <=
                                this.application.payment.amount_paid;
                        this.selectedTracker = this.calcTracker(app);
                        // Setting notes data on local data
                        this.notes = app.notes;
                        // combine program reqs with schools reqs
                        if (
                            app.university &&
                            app.university.required_docs?.length > 0
                        ) {
                            app.program.required_docs = [
                                ...app.program.required_docs,
                                ...app.university.required_docs,
                            ];
                            app.program.required_docs.sort((a, b) =>
                                a.isRequired > b.isRequired ? -1 : 1
                            );
                        }
                        this.program = app.program;
                        this.program.university = app.university;
                        this.user = app.user_id;
                        this.change_session();
                        this.header =
                            'Schools and Programs' +
                            '<i class="bi bi-chevron-right mx-2"></i>' +
                            this.program.university?.name +
                            '<i class="bi bi-chevron-right mx-2"></i>' +
                            this.program.name;
                        setTimeout(() => {
                            const selected =
                                document.getElementsByClassName('req_docs');
                            if (selected !== null && selected.length > 0) {
                                for (let i = 0; i < selected.length; i++) {
                                    const docToUpload =
                                        selected[i].getAttribute('data-id');
                                    const selectedValue =
                                        this.application.documents?.find(
                                            (x) =>
                                                x.doc_to_upload === docToUpload
                                        )?.uploaded_doc;
                                    selected[i].value =
                                        selectedValue == null
                                            ? ''
                                            : selectedValue;
                                }
                            }
                        }, 500);
                    }
                    if (doc) {
                        this.docs = doc.allDocs;
                    }
                })
                .catch((err) => {
                    console.log('big', err);
                });
        },
        change_session() {
            if (
                this.selected_session !== '' &&
                this.program &&
                this.program.available_sessions?.length > 0
            ) {
                this.resumption_date_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).resumption_date;
                this.application_deadline_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).application_deadline;
            } else {
                this.resumption_date_text = 'Select Academic session first';
                this.application_deadline_text =
                    'Select Academic session first';
            }
        },
        activateReadMore() {
            this.readMoreActivated = true;
        },
        deactivateReadMore() {
            this.readMoreActivated = false;
        },
        formatDateLocal(dt) {
            return formatDate(dt);
        },
        addNote() {
            if (this.note_text === '') {
                return;
            }

            const obj = {
                note: this.note_text,
            };
            AdminService.postData(
                'add_application_note/' + this.application_id,
                obj
            ).then(
                (resp) => {
                    if (resp.data) {
                        this.note_text = '';
                        this.updateModal({
                            type: true,
                            message: 'New note added',
                        });
                        this.notes = resp.data.notes;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        },
        submitApplyForm() {},
        calcTracker(app) {
            return calcTracker(app);
        },
        replaceDocument(doc) {
            doc.path_to_file = '';
        },
        onFileChange(event, doc) {
            // doc.file = event.target.files[0];
            let up = {
                path: 'admissiondocs',
                uploadFiles: [event.target.files[0]],
            };
            AdminService.postDataForm('upload_document', up).then((resp) => {
                if (resp && resp.data) {
                    doc.path_to_file = resp.data;
                }
            });
            //doc.path_to_file = "test post";
        },
        deleteRequiredDocs(ct) {
            this.appChecklist.decision_documents.splice(ct, 1);
        },
        addRequiredDocs() {
            this.appChecklist.decision_documents.push({
                file_type: null,
                path_to_file: '',
            });
        },
        submitAppChecklist() {
            // const isValid = await this.appChecklistSchema.isValid(
            //     this.appChecklist
            // );

            const myModal = document.querySelector('#appTrackerModal');

            var modal = Modal.getOrCreateInstance(myModal); // Returns a Bootstrap modal instance

            // if (!isValid) {
            //     Notify.failure(
            //         'Some of the required fields are missing. Kindly fill them in'
            //     );
            //     return;
            // }

            if (this.appChecklist.declined_by_schooliply === true) {
                if (this.appChecklist.reason_for_decline === '') {
                    Notify.failure(
                        'Kindly give a reason for declining the application'
                    );
                    return;
                }
                this.appChecklist.decision_documents = [];
                this.appChecklist.admission_granted = false;
                this.updateModal({
                    type: true,
                    message: `Updated successfully`,
                });
                modal.hide();
            } else if (this.appChecklist.declined_by_schooliply === false) {
                this.appChecklist.reason_for_decline = '';
                this.appChecklist.decision_documents.forEach((el) => {
                    if (el.path_to_file === '' || el.file_type === null) {
                        this.modalErr = true;
                    } else {
                        this.modalErr = false;
                    }
                });
                if (this.modalErr) {
                    Notify.failure(
                        'Error! One of the documents is empty. Kindly fill in the file type or upload the needed file.'
                    );
                    return;
                }
            }
            //uploadFiles
            this.appChecklist.status = this.appChecklist.admission_granted
                ? 'accepted'
                : 'declined';
            this.appChecklist.application_complete = true;
            //application_id
            modal.hide();
            AdminService.postData(
                'complete_application/' + this.application_id,
                this.appChecklist
            ).then((resp) => {
                if (resp && resp.data) {
                    this.getAppDetails();
                    this.application_tracker = applicationTracker.filter(
                        (x) => x.dropdown
                    );
                    this.updateModal({
                        type: true,
                        message: `Updated successfully`,
                    });
                }
            });
        },
    },
};
</script>
