<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Apply For Program" :subText="headerText"/>
        <div v-if="students.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no student added yet</div>
        </div>
        <div v-if="students.length > 0" class="col-12 col-md-10 tableParent">
          <h5 class="primaryColor mb-3">Please choose student to apply for from below</h5>
          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>
          
          <EasyDataTable
            :headers="headers"
            :items="studentsTable"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-operation="stu">
            <a href="#" @click.prevent="applyForStudent(stu._id)" class="text-decoration-none primaryColor fw-bold"><i class="bi bi-pencil-square me-2"></i>Apply For Student</a>
            <!--<router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'ScoutStudentApplyForm', params: {
                      studentId: stu._id, 
                      school_id: this.$route.params.school_id,
                      program_id: this.$route.params.program_id,
                      id: this.$route.params.id
                      }}"><i class="bi bi-pencil-square me-2"></i>Apply For Student</router-link>-->
          </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
import ApiService from '@/apiServices/api.service';
import { formatDate } from '@/utils/format'
export default {
  name: 'ScoutApplyForStudent',
  components: {
    PageHeader
  },
  data: function () {
    return {
      students: [],
      studentsTable: [],
      searchvalue: "",
      headerText: "",
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Other Name", value: "other_names", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    applyForStudent (id) {
      /*if(!this.$store.state.scout.currentUser?.uuser?.terms_conditions_accepted) {
        this.$router.push({name: "ScoutsTermsAndConditions", query: {
          redirect: `my-student/${id}/school/${this.$route.params.school_id}/program/${this.$route.params.program_id}/apply/${this.$route.params.id}`
        }});
        return;
      }*/
      this.$router.push({name: "ScoutStudentApplyForm", params: {
                      studentId: id, 
                      school_id: this.$route.params.school_id,
                      program_id: this.$route.params.program_id,
                      id: this.$route.params.id
      }});
    },
    getSchoolInfo() {
            return ApiService.getData(
                'edu/getProgram/school/' +
                    this.$route.params.school_id +
                    '/program/' +
                    this.$route.params.program_id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
    },
    getAllStudents() {
            return ScoutService.getData('get_scout_users').then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
    },
    setupPage () {
      Promise.all([this.getSchoolInfo(), this.getAllStudents()])
        .then(([sch, stus]) => {
            if (sch) {
                //check application fee
                this.program = sch;

                /*if (this.program.application_fee == 0) {
                    this.checkFreeAppLimit();
                }*/
                this.header =
                    'Schools and Programs' +
                    '<i class="bi bi-chevron-right mx-2"></i>' +
                    this.program.university?.name +
                    '<i class="bi bi-chevron-right mx-2"></i>' +
                    this.program.name;
            }
            if (stus) {
                this.students = stus;
                this.studentsTable = this.students.map(x => ({...x.personal_info, email: x.email, _id: x._id}))
            }
        })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    this.setupPage();
  }
}
</script>
