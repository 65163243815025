<template>
    <div class="container-fluid vh-100">
        <div
            class="d-flex justify-content-center align-items-center flex-column vh-100"
        >
            <div class="">
                <img
                    src="assets/images/Schooliply-Logo-Dark.png"
                    class="mainLogo"
                />
            </div>
            <div class="text-center">
                <div class="mb-4 mt-2">
                    <h5>
                        <router-link
                            :to="{ name: 'GuestProgramList' }"
                            class="primaryColor text-decoration-none"
                            >Find Programs</router-link
                        >
                    </h5>
                </div>

                <div class="mb-4">
                    <h6>Log in as:</h6>
                </div>
                <div class="mb-4">
                    <h5 class="fw-bold">
                        <router-link
                            to="/students"
                            class="primaryColor text-decoration-none"
                            >Students</router-link
                        >
                    </h5>
                </div>
                <div>
                    <h5 class="fw-bold">
                        <router-link
                            :to="{name: 'ScoutLogin'}"
                            class="primaryColor text-decoration-none"
                            >Scout</router-link
                        >
                    </h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
    name: 'HomePage',
    data: function () {
        return {
            lists: [
                {
                    id: 0,
                    text: 'Hello',
                },
                {
                    id: 1,
                    text: 'World',
                },
            ],
        };
    },
};
</script>
<style scoped>
.mainLogo {
    width: 100%;
    max-width: 400px;
    margin-left: -12.5px;
}
</style>
