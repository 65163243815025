<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Profile" subText="Your Profile"/>
        
      <form @submit.prevent="saveAdmin">
            <div class="row">
                <div class="col-12 col-md-5">
                    <label
                        >First Name
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="admin.first_name"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Last Name
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="admin.last_name"
                        class="form-control"
                        required
                    />
                </div>
            </div>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Email
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="email"
                        v-model="admin.email"
                        class="form-control"
                        disabled
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Role
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="admin.role"
                        class="form-control"
                        disabled
                        required
                    />
                </div>
            </div>
            <div class="mt-4">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Update Profile
                </button>
                <button @click="reset_password = true" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2" 
                v-if="!reset_password">
                    Reset Password
                </button>
            </div>
        </form>
        <form @submit.prevent="resetPasswordSubmit">
            <div class="mt-4 row" v-if="reset_password">
            <div class="col-12 col-md-5">
                <label
                    >Enter New Password
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <input
                    type="password"
                    class="form-control"
                    v-model="new_password"
                    required
                />
            </div>
            <div class="mt-4">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save New Password
                </button>
                <button @click="clearPWDForm" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2">
                    Cancel
                </button>
            </div>
        </div>
        </form>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
import { formatDate } from '@/utils/format'
export default {
  name: 'AdminProfile',
  components: {
    PageHeader
  },
  data: function () {
    return {
        admin: {
            role: '',
        },
        admin_id: '0',
        reset_password: false,
        new_password: ''
    };
  },
  methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        getAdmin() {
            AdminService.getData('get_admin_self')
                .then((resp) => {
                    if (resp && resp.data) {
                        this.admin = resp.data;
                    }
                })
        },
        saveAdmin() {
            AdminService.postData('update_admin_profile_self', this.admin)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.updateModal({
                            type: true,
                            message: 'Profile saved',
                        });
                    }
                })
        },
        formatDateLocal(dt) {
            return formatDate(dt);
        },
        clearPWDForm () {
            this.new_password = ''; 
            this.reset_password = false
        },
        resetPasswordSubmit () {
            AdminService.postData('update_password_by_self', {password: this.new_password})
                .then((resp) => {
                    if (resp && resp.data) {
                        this.updateModal({
                            type: true,
                            message: 'Password has been reset',
                        });
                        this.clearPWDForm();
                    }
                })
        }
    },
  mounted () {
    this.getAdmin();
  }
}
</script>
