<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Admins" subText="Create or Edit Admin" />

        <form @submit.prevent="saveAdmin">
            <div class="row">
                <div class="col-12 col-md-5">
                    <label
                        >First Name
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="admin.first_name"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Last Name
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="admin.last_name"
                        class="form-control"
                        required
                    />
                </div>
            </div>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Email
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="email"
                        v-model="admin.email"
                        class="form-control"
                        required
                    />
                </div>
                <div
                    class="col-12 col-md-5 mt-3 mt-md-0"
                    v-if="admin_id == '0'"
                >
                    <label
                        >Password
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="password"
                        v-model="admin.password"
                        class="form-control"
                        required
                    />
                </div>
            </div>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Role
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <select class="form-select" v-model="admin.role" required>
                        <option value="">Select One</option>
                        <option value="admin">Admin</option>
                        <option value="media">Media</option>
                        <option
                            value="intermediate-admin"
                            v-if="
                                role == 'super-admin' ||
                                role == 'intermediate-admin'
                            "
                        >
                            Intermediate Admin
                        </option>
                        <option
                            value="super-admin"
                            v-if="role == 'super-admin'"
                        >
                            Super Admin
                        </option>
                    </select>
                </div>
            </div>
            <div class="mt-3">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save Admin
                </button>
                <button @click="reset_password = true" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2" 
                v-if="admin_id != '0' && !reset_password">
                    Reset Password
                </button>
            </div>
        </form>
        <form @submit.prevent="resetPasswordSubmit">
            <div class="mt-4 row" v-if="reset_password">
            <div class="col-12 col-md-5">
                <label
                    >Enter New Password
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <input
                    type="password"
                    class="form-control"
                    v-model="new_password"
                    required
                />
            </div>
            <div class="mt-3">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save New Password
                </button>
                <button @click="clearPWDForm" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2" v-if="admin_id != '0'">
                    Cancel
                </button>
            </div>
        </div>
        </form>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { formatDate } from '@/utils/format';
export default {
    name: 'SaveAdmin',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            admin: {
                role: '',
            },
            admin_id: '0',
            reset_password: false,
            new_password: ''
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        getAdmin() {
            AdminService.getData('get_admin/' + this.$route.params.id)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.admin = resp.data;
                    }
                })
        },
        saveAdmin() {
            AdminService.postData('create_admin_by_admin', this.admin)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.updateModal({
                            type: true,
                            message: 'Admin saved',
                        });
                        this.$router.push('/admin/admins/');
                    }
                })
        },
        formatDateLocal(dt) {
            return formatDate(dt);
        },
        clearPWDForm () {
            this.new_password = ''; 
            this.reset_password = false
        },
        resetPasswordSubmit () {
            AdminService.postData('update_password_by_admin/' + this.$route.params.id, {password: this.new_password})
                .then((resp) => {
                    if (resp && resp.data) {
                        this.updateModal({
                            type: true,
                            message: 'Password has been reset',
                        });
                        this.clearPWDForm();
                    }
                })
        }
    },
    mounted() {
        this.admin_id = this.$route.params.id;
        if (
            this.$store.state.admin.currentUser != null &&
            this.$route.params.id !== '0'
        ) {
            this.getAdmin();
        }
    },
    computed: {
        role() {
            if (this.$store.state.admin.currentUser) {
                return this.$store.state.admin.currentUser.uuser.role;
            } else {
                return '';
            }
        },
    },
};
</script>
