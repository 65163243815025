<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Scout Applications" subText="All Submitted Scout Applications."/>
        <div v-if="apps.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no scout application submitted yet</div>
        </div>
        <div v-if="apps.length > 0" class="col-12 col-md-10 tableParent">

          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>

          <EasyDataTable
            :headers="headers"
            :items="apps"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-operation="ads">
            <router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'ViewScoutApplication', params: {id: ads._id}}"><i class="bi bi-pencil-square me-2"></i>View</router-link>
          </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
import { formatDate } from '@/utils/format'
export default {
  name: 'ScoutApplicationList',
  components: {
    PageHeader
  },
  data: function () {
    return {
      apps: [],
      searchvalue: "",
      headers: [
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Business Name", value: "business_name", sortable: true },
        { text: "Business Email", value: "business_email", sortable: true },
        { text: "Application Reviewed", value: "application_complete", sortable: true },
        { text: "Decision", value: "application_approved", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getApps () {
      
      AdminService.getData('scout_applications').then((resp) => {
        
        if (resp && resp.data) {
          let data = resp.data
          data = data.map(function(x){
              if(x.application_approved == undefined) {
                x.application_approved = null;
              } else {
                x.application_approved = x.application_approved ? "Approved" : "Declined"
              }
              x.application_complete = x.application_complete ? "Yes" : "No"
              return x;
          })
          this.apps = data
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    if (this.$store.state.admin.currentUser != null) {
      const admin = this.$store.state.admin.currentUser
      if (admin.uuser.role === 'super-admin') {
        this.getApps()
      }
    }
  }
}
</script>
