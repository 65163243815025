<template>
  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>-->
  <div class="entirePage">
    <div id="pageLoader" class="d-none"></div>
    <my-modal />
    <router-view/>
  </div>
</template>
<script>
import MyModal from '@/components/CustomModal.vue'
export default {
  components: {
    MyModal
  }
}
</script>

<style>
*{
  --main-color: #5a189a;
  --easy-table-header-font-size: 16px;
  --easy-table-body-row-font-size: 16px;
  --easy-table-footer-font-size: 14px;
  --easy-table-row-border: none;
  --easy-table-border: none;
  --easy-table-body-even-row-background-color: #ecedee;
  --easy-table-body-item-padding: 0.5rem 0.5rem;
}
#pageLoader{
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableParent{
  overflow-x: auto;
}

table tbody td{
  vertical-align: middle;
}

.primaryColor{
  color: var(--main-color) !important;
}

.primaryBG{
  background: var(--main-color) !important;
}

.primaryBtn{
  background: var(--main-color) !important;
  color: white !important;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('./fonts/CabinetGrotesk-Variable.woff2') format('woff2'),
       url('./fonts/CabinetGrotesk-Variable.woff') format('woff'),
       url('./fonts/CabinetGrotesk-Variable.ttf') format('truetype');
       font-weight: 100 900;
       font-display: swap;
       font-style: normal;
}

#app {
  font-family: 'CabinetGrotesk', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FBF8FF;
  /*text-align: center;
  color: #2c3e50;*/
  font-weight: 400;
  min-height: 100vh;
}

.smaller{
  font-size: 0.70em;
}

.text-gray{
  color: #6A6A6A;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.imageNegMargin{
  margin-left: -10px;
}

.stepProgressBar {
    display: flex;
    max-width: 300px;
}

.stepProgressBar .step {
    padding-right: 30px;
    font-size: 0.8em;
    position: relative;
}

.stepProgressBar .step-text {
    margin-bottom: 10px;
    color: #5a189a;
}

.stepProgressBar .bullet {
    border: 1px solid #5a189a;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    color: #5a189a;
    display: flex;
    justify-content: center;
    transition: background-color 500ms;
    align-items: center;
    position: relative;
}

.stepProgressBar .bullet.completed {
    color: white;
    background-color: #5a189a;
}

.stepProgressBar .middle-line {
    position: absolute;
    width: calc(100% - 40px);
    border-bottom: 1px solid #5a189a;
    bottom: 10px;
    left: 30px;
}

.stepProgressBar .step.last .middle-line {
    display: none;
}
.max-400{
  max-width: 400px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

.fade-in-up-leave-to {
  opacity: 0;
  transition: opacity .3s;
}

.fade-in-up-enter {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.fade-in-up-enter-to {
  opacity: 0;
  animation-duration: .7s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
</style>
