<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Profile" subText="Your profile." />
        <div class="row">
            <div class="col-12 col-md-9">
                <form>
                    <div class="mb-4">
                        <h5 class="primaryColor">Personal Info</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>First Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.first_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Last Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.last_name" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Nationality <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <select class="form-select" v-model="register.nationality" required>
                                        <option>Select your nationality</option>
                                        <option v-for="country in countries" :value="country.label" :key="country.value">
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <div class="mb-2"><label>Gender <span class="text-danger ml-2 fw-bold">*</span></label></div>
                                    <input type="radio" v-model="register.gender" name="gender" value="M" id="male" required/>
                                    <label for="male" class="ms-1">Male</label>
                                    <input type="radio" v-model="register.gender" name="gender" value="F" id="female" class="ms-3" required/>
                                    <label for="female" class="ms-1">Female</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Business Details</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Business Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.business_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Business Email Address <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="email" class="form-control" v-model="register.email" required disabled/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Phone Number <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.business_phone" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Certificate of Incorporation <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <div class="mt-2" v-if="register && register.incorporation_doc && register.incorporation_doc != ''">
                                        <a :href="register.incorporation_doc" target="_blank"><i class="bi bi-cloud-download"></i> Download</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Address Line 1 <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.address_line_1" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>City/Town <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.city" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Address Line 2</label>
                                    <input type="text" class="form-control" v-model="register.address_line_2"/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>State/Province <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.state" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>ZIP/Postal Code <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.postal_code" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Country <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <select class="form-select" v-model="register.country" required>
                                        <option>Select your nationality</option>
                                        <option v-for="country in countries" :value="country.label" :key="country.value">
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Online Presence</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Website <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="url" class="form-control" v-model="register.website" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Facebook Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.facebook" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Instagram Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.instagram" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Twitter Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.twitter" required/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Bank Details</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Bank Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.bank_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Bank Account Number <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.bank_account_number" required/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="mt-3">
                            <button type="button" class="btn primaryBtn" @click="saveProfile">Update Profile</button>
                            <button @click="reset_password = true" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2" 
                                v-if="!reset_password">
                                    Reset Password
                            </button>
                        </div>
                    </div>
                </form>
                <form v-if="reset_password" @submit.prevent="resetPasswordSubmit" class="mt-4">
                    <div class="mb-4">
                        <h5 class="primaryColor">Update Password</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>New Password <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="password" class="form-control" v-model="new_password" required/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <button type="submit" class="btn primaryBtn px-5 py-2">
                            Save New Password
                        </button>
                        <button @click="clearPWDForm" type="button" class="btn btn-link primaryColor px-5 py-2 text-decoration-none ms-2">
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import ScoutService from '@/apiServices/scout/scout.service'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
   name: "ScoutProfile",
   components: {
    PageHeader
  },
    data: function () {
        return {
            reset_password: false,
            new_password: '',
            register: {
                first_name: '',
                last_name: '',
                nationality: '',
                gender: '',
                business_name: '',
                email: '',
                password: '',
                business_phone: '',
                uploadFiles: null,
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                postal_code: '',
                country: '',
                bank_name: '',
                incorporation_doc: '',
                bank_account_number: '',
                website: '',
                facebook: '',
                instagram: '',
                twitter: ''
            },
            loading: false,
            email: '',
            password: '',
            message: ''
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        clearPWDForm () {
            this.new_password = ''; 
            this.reset_password = false
        },
        resetPasswordSubmit () {
            ScoutService.postData('update-password', {password: this.new_password}).then((resp) => {
                if(resp && resp.data) {
                    this.updateModal({
                            type: true,
                            message: 'Password has been reset',
                        });
                        this.clearPWDForm();
                }
            })
        },
        saveProfile: function () {
            ScoutService.putData('update_scout_profile', this.register).then((resp) => {
                if(resp && resp.data) {
                    this.register = resp.data
                    this.updateModal({ type: true, message: 'Profile Updated' });
                }
            })
        },
        fetchProfile: function () {
            ScoutService.getData('scout_profile').then((resp) => {
                if(resp && resp.data) {
                    this.register = resp.data
                }
            })
        },
    },
    computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
  },
    mounted() {
        this.fetchProfile();
    },
};
</script>

