<template>
  <div class='container py-3 px-3 py-md-5 px-md-5'>
    <page-header header='Manage Schools' subText='Manage all schools.' />
    <div class="mb-3">
          <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'EditSchool', params: {id: '0'}}"><i class="bi bi-plus"></i> Add School</router-link></h6>
        </div>
          <div v-if="institutions.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no institution added yet</div>
        </div>
        <div v-if="institutions.length > 0" class="col-12 col-md-10 tableParent">
          <!--<table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>Name</th>
                <th>Country</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inst in institutions" :key="inst._id">
                  <td>{{inst.name}}</td>
                  <td>{{inst.country}}</td>
                  <td>
                    <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'EditSchool', params: {id: inst._id}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
                    <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'ManagePrograms', params: {school_id: inst._id}, query: {school_name: inst.name}}"><i class="bi bi-list-ul me-2"></i>View Programs</router-link>
                  </td>
              </tr>
            </tbody>
          </table>-->

          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>

          <EasyDataTable
            :headers="headers"
            :items="institutions"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-operation="ins">
            <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'EditSchool', params: {id: ins._id}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
            <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'ManagePrograms', params: {school_id: ins._id}, query: {school_name: ins.name}}"><i class="bi bi-list-ul me-2"></i>View Programs</router-link>
          </template>
          </EasyDataTable>
        </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
  name: 'ManageSchools',
  components: {
    PageHeader
  },
  data: function () {
    return {
      institutions: [],
      searchvalue: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Country", value: "country", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getSchools () {
      
      AdminService.getData('get_all_schools').then((resp) => {
        
        if (resp && resp.data) {
          this.institutions = resp.data
          // console.log('schools ', resp.data)
        }
      })
    }
  },
  computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    }
  },
  mounted () {
    this.getSchools()
  }
}
</script>
