<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-12 col-md-6 d-flex flex-column align-items-start ms-md-4">
        <div>
          <router-link to="/">
            <img src="/assets/images/Schooliply-Logo-Dark.png" width="150" class="imageNegMargin"/>
          </router-link>
        </div>
        <h2 class="mt-3 mt-md-5 pt-4 pt-md-5 fw-bold">Reset your password</h2>
        <div class="text-secondary small">Let's get you back in the system</div>
        <!--<div class="d-none d-md-block mt-5">
          <span>Remember your password? </span><router-link :to="{name: 'StudentLogin'}" class="primaryColor text-decoration-none">Log in</router-link>
        </div>-->
      </div>

      <div class="col-12 col-md-4 mt-4 mt-md-5 offset-md-1">
        <div class="mt-3 border rounded-3 pt-3 pt-md-5 pb-4 px-4 mb-2 mb-md-0 bg-white">
          <form @submit.prevent="ResetPWDSubmit">
          <div class="mb-3 pt-4">
              <label for="resetPWD" class="form-label small">Enter new password</label>
              <input type="password" v-model="password" class="form-control" id="resetPWD" aria-describedby="emailHelp" required>
            </div>
            <button type="submit" class="btn primaryBtn mt-4 w-100">Change Password</button>
            <div class="mt-3 text-center">
              <span>Remember your password? </span><router-link :to="{name: 'ScoutLogin'}" class="primaryColor text-decoration-none">Log in</router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  data: function () {
    return {
      loading: false,
      password: '',
      message: '',
      token: ''
    }
  },
  methods: {
    ...mapActions({
      callResetPWD: 'scout/resetPWD',
      updateModal: 'general/updateInfo'
    }),
    ResetPWDSubmit: function () {
      // this.$store.dispatch('user/signIn')
      if (this.password) {
        var user = {
          password: this.password,
          token: this.token
        }
        
        this.callResetPWD(user).then(
          () => {
            
            this.updateModal({ type: true, message: 'Your password has been reset, you can login now.' })
            this.$router.push('/scout/login')
          }
        )
      }
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.scout.status.loggedIn
    }
  },
  mounted () {
    if (this.$route.params.token == null) {
      this.$router.push('/scout/login')
    }
    this.token = this.$route.params.token
    if (this.loggedIn) {
      this.$router.push({name: 'ScoutIndex'})
    }
  }
}
</script>
