<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header
            header="Applications"
            subText="All submitted applications."
        />
        <div>
            <button class="btn primaryBtn" @click="getCompletedApps()" v-if="!showingCompleted">Show Completed Applications</button>
            <button class="btn primaryBtn" @click="getPendingApps()" v-if="showingCompleted">Show Pending Applications</button>
        </div>
        <div v-if="apps.length == 0" class="text-center my-4">
            <img src="assets/images/no-application.png" width="150" />
            <div class="mt-4 text-gray">
                There is no application submitted yet
            </div>
        </div>
        <!-- Modal -->
        <div
            class="modal fade"
            id="assignModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="assignModalLabel"
            aria-hidden="true"
        >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="assignModalLabel">
                            Assign application to Admin
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <div class="modal-body mt-3 mb-4">
                        <div>
                            <div class="fw-bold">Application ID</div>
                            <div>{{ application_id }}</div>
                        </div>
                        <div class="mt-3">
                            <div class="fw-bold">School</div>
                            <div>{{ school_name }}</div>
                        </div>
                        <div class="mt-3">
                            <div class="fw-bold">Program</div>
                            <div>{{ program_name }}</div>
                        </div>
                        <div class="mt-3">
                            <div class="fw-bold">Assign To</div>
                            <div>
                                <select
                                    class="form-select"
                                    v-model="selectedAdmin"
                                >
                                    <option value="">Select One</option>
                                    <option
                                        v-for="admin in admins"
                                        :key="admin._id"
                                        :value="admin._id"
                                    >
                                        {{ admin.first_name }}
                                        {{ admin.last_name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                            id="closeAssignModal"
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="AssignToAdmin"
                        >
                            Assign To Admin
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="apps.length > 0" class="col-12 col-md-12 tableParent">
            <div class="mt-3 text-center" v-if="!showingCompleted">
                <h5>Total Applications: <span>{{ totalApps }}</span></h5>
            </div>
            <application-table :key="resetKey" :appsPass="apps" @showAssignModal="showAssignModal" :canAssignPass="true"></application-table>
            <div class="mt-3" v-if="!isLastPage && !showingCompleted">
                <button class="btn primaryBtn" @click="getPendingApps()">Load More Applications</button>
                <div class="col-12 col-md-6 offset-md-3 mt-3 d-flex">
                    <input
                        type="text"
                        placeholder="search by application id"
                        class="form-control rounded-pill mb-3"
                        v-model="searchByID"
                    />
                    <button class="btn btn-link primaryColor text-decoration-none" @click="searchAppById"><h5>Search</h5></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import AppTracker from '@/components/common/Tracker.vue';
import PageHeader from '@/components/PageHeader.vue';
import ApplicationTable from '@/components/admin/ApplicationTable.vue';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
export default {
    name: 'AdminApplications',
    components: {
        PageHeader,
        ApplicationTable
        // AppTracker,
    },
    data: function () {
        return {
            apps: [],
            pendingApps: [],
            completedApps: [],
            admins: [],
            school_name: '',
            program_name: '',
            application_id: null,
            selectedAdmin: '',
            resetKey: 0,
            showingCompleted: false,
            nextPage: 1,
            totalApps: 0,
            isLastPage: true,
            searchByID: ""
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        showAssignModal(id) {
            this.selectedAdmin = '';
            this.application_id = null;
            const app = this.apps.find((x) => x._id === id);
            this.application_id = id;
            this.school_name = app.university.name;
            this.program_name = app.program.name;
        },
        AssignToAdmin() {
            if (this.selectedAdmin !== '') {
                AdminService.getData(
                    'assign_app_to_admin/app/' +
                        this.application_id +
                        '/admin/' +
                        this.selectedAdmin
                ).then((resp) => {
                    document.getElementById('closeAssignModal').click();
                    this.apps.find(
                        (x) => x._id === this.application_id
                    ).assigned_admin = resp.data.assigned_admin;
                    this.selectedAdmin = '';
                    this.application_id = null;
                    this.resetKey += 1;
                    this.updateModal({
                        type: true,
                        message: 'Application assigned to admin',
                    });
                });
            }
        },
        getAdmins() {
            return AdminService.getData('get_admins_by_role/admin').then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getAppDetails(id) {
            AdminService.getData('get_app_details/' + id)
                .then((resp) => {
                    console.log('app details', resp.data);
                })
                .catch((err) => {
                    console.log('err', err);
                });
        },
        getApps() {
            return AdminService.getData('get_all_applications').then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        getAppsAndAdmin() {
            Promise.all([this.getApps(), this.getAdmins()]).then(
                ([getapps, admins]) => {
                    if (getapps) {
                        // this.apps = getapps;
                        this.pendingApps = getapps?.apps;
                        this.apps = getapps?.apps;
                        this.nextPage = getapps?.nextPage;
                        this.totalApps = getapps?.total;
                        this.isLastPage = getapps?.isLastPage;
                    }
                    if (admins) {
                        this.admins = admins;
                    }
                }
            );
        },
        getCompletedApps() {
            if(this.completedApps.length > 0) { // show fetched completed apps
                this.apps = this.completedApps;
                this.resetKey += 1;
                this.showingCompleted = true;
                return;
            }
            //fetch completed apps
            AdminService.getData('get_all_completed_applications')
                .then((resp) => {
                    if(resp.data){
                        this.completedApps = resp.data;
                        this.apps = resp.data;
                        this.resetKey += 1;
                        this.showingCompleted = true;
                    }
                })
        },
        getPendingApps() {
            if(this.showingCompleted) { // show previously fetched pending apps if toggling completed/pending apps
                this.apps = this.pendingApps;
                this.resetKey += 1;
                this.showingCompleted = false;
                return;
            }
            // load more apps
            AdminService.getData('get_all_applications?page=' + this.nextPage)
                .then((resp) => {
                    if(resp.data){
                        this.pendingApps = this.pendingApps.concat(resp.data?.apps);
                        this.apps = this.pendingApps;
                        this.resetKey += 1;
                        this.showingCompleted = false;
                        this.nextPage = resp.data?.nextPage;
                        this.totalApps = resp.data?.total;
                        this.isLastPage = resp.data?.isLastPage;
                    }
                })
        },
        searchAppById() {
            if(this.searchByID.trim() == ""){
                return;
            }
            AdminService.getData('get_app_details/' + this.searchByID.trim())
                .then((resp) => {
                    if(!resp.data){
                        this.updateModal({
                            type: false,
                            message: 'Application not found',
                        });
                        return;
                    }
                    this.$router.push({ name: 'ViewAnApplication', params: {id: resp.data._id, user_id: resp.data.user_id._id} })
                })
        }
    },
    mounted() {
        this.showingCompleted = false;
        if (this.$store.state.admin.currentUser != null) {
            this.getAppsAndAdmin();
        }
    },
};
</script>
