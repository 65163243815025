<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header
            :header="'Edit Program of ' + school_name"
            subText="Add or edit a program."
        />

        <form @submit.prevent="saveProgram">
            <div class="row mb-md-4">
                <div class="col-12 col-md-10">
                    <label
                        >Name<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="text"
                        v-model="program.name"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Program Type
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <!-- <input type="text" v-model="program.program_type" class="form-control" required/>
             -->
                    <select
                        class="form-select"
                        v-model="program.program_type"
                        required
                    >
                        <option value="">Select One</option>

                        <option
                            v-for="(prg, index) in program_list"
                            :key="index"
                            :value="prg"
                        >
                            {{ prg }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >Program description
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <ckeditor
                        :editor="editor"
                        v-model="program.program_description"
                        :config="{}"
                    ></ckeditor>
                    <!--<textarea v-model="institution.about" class="form-control" required></textarea>-->
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Application Fee<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="program.application_fee"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Tuition Fee
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="number"
                        v-model="program.tuition_fee"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Currency Name (e.g. CAD)<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        class="form-select"
                        v-model="program.currency"
                        required
                    >
                        <option value="">Select One</option>
                        <!--<option value="">Select One</option>
              <option value="CAD">Canadian Dollar</option>
              <option value="USD">US Dollar</option>
              <option value="GBP">Pound Sterling</option>
              <option value="EUR">Euro</option>
              <option value="AUD">Australian Dollar</option>-->
                        <option
                            v-for="cur in currency_list"
                            :key="cur.id"
                            :value="cur.code"
                        >
                            {{ cur.name }}
                        </option>
                    </select>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Currency Symbol (e.g $)<span
                            class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        class="form-select"
                        v-model="program.currency_symbol"
                        required
                    >
                        <option value="">Select One</option>
                        <!--<option value="$">$</option>
              <option value="£">£</option>
              <option value="€">€</option>-->
                        <option
                            v-for="cur in currency_symbol_list"
                            :key="cur"
                            :value="cur"
                        >
                            {{ cur }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Cost of living<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="program.cost_of_living"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >Admission requirements
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <ckeditor
                        :editor="editor"
                        v-model="program.admission_requirement"
                        :config="{}"
                    ></ckeditor>
                    <!--<textarea v-model="institution.about" class="form-control" required></textarea>-->
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Duration of program (in months)<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="number"
                        v-model="program.duration_in_months"
                        class="form-control"
                        required
                    />
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10">
                    <label
                        >List admission documents
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <div
                        v-for="(req, counter) in program.required_docs"
                        :key="counter"
                        class="mb-3 card p-3"
                    >
                        <div class="mb-3">
                            <label
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                v-model="req.name"
                                class="form-control"
                                required
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                >Description
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <textarea
                                v-model="req.desc"
                                class="form-control"
                                required
                            ></textarea>
                        </div>
                        <div>
                            <label
                                >Is document required
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <select
                                class="form-select"
                                v-model="req.isRequired"
                                required
                            >
                                <option>Select One</option>
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                            </select>
                        </div>
                        <div class="mt-3">
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="deleteRequiredDocs(counter)"
                            >
                                Delete this requirement
                            </button>
                        </div>
                    </div>

                    <div class="mt-2 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="addRequiredDocs"
                        >
                            Add more requirements
                        </button>
                    </div>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10">
                    <label
                        >List academic sessions
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <div
                        v-for="(sess, counter) in program.available_sessions"
                        :key="counter"
                        class="mb-3 card p-3"
                    >
                        <div class="mb-3">
                            <label
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                v-model="sess.name"
                                class="form-control"
                                required
                            />
                        </div>
                        <div class="mb-3">
                            <label
                                >Resumption month
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <select
                                v-model="sess.resumption_date"
                                class="form-select"
                                required
                            >
                                <option value="">Select One</option>
                                <option
                                    v-for="dt in sessionDates.filter(
                                        (x) => x != 'No deadline'
                                    )"
                                    :key="dt"
                                    :value="dt"
                                >
                                    {{ dt }}
                                </option>
                            </select>
                        </div>
                        <div>
                            <label
                                >Application deadline month
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <select
                                v-model="sess.application_deadline"
                                class="form-select"
                                required
                            >
                                <option value="">Select One</option>
                                <option
                                    v-for="dt in sessionDates"
                                    :key="dt"
                                    :value="dt"
                                >
                                    {{ dt }}
                                </option>
                            </select>
                        </div>
                        <div class="mt-3">
                            <button
                                type="button"
                                class="btn btn-danger"
                                @click="deleteAvailableSession(counter)"
                            >
                                Delete this session
                            </button>
                        </div>
                    </div>

                    <div class="mt-2 text-end">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="addAvailableSession"
                        >
                            Add more sessions
                        </button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Show to public<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <select
                        v-model="program.show_to_public"
                        class="form-select"
                        required
                    >
                        <option>Select One</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
            </div>

            <div class="mt-3">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save Program
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { currency_list } from '@/utils/currencyList';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
export default {
    name: 'EditProgram',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            editor: ClassicEditor,
            program_id: '0',
            school_id: '',
            school_name: '',
            currency_list: currency_list,
            program_list: [
                'Bachelors',
                'Diploma',
                'Masters',
                'MBA',
                'Certificate',
                'Dual Degree',
                'PhD',
                'Postgraduate Diploma',
                'Postgraduate Certificate',
            ],
            sessionDates: [
                'No deadline',
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
            ],
            currency_symbol_list: [
                ...new Set(currency_list.map((x) => x.symbol)),
            ],
            program: {
                name: '',
                program_type: '',
                application_fee: 0,
                tuition_fee: 0,
                university: '',
                currency: '',
                currency_symbol: '',
                cost_of_living: 0,
                duration_in_months: 0,
                required_docs: [
                    {
                        name: '',
                        desc: '',
                        isRequired: true,
                    },
                ],
                program_description: '',
                available_sessions: [
                    {
                        name: '',
                        resumption_date: '',
                        application_deadline: '',
                    },
                ],
                admission_requirement: '',
                show_to_public: true,
            },
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        saveProgram() {
            if (this.program.university === '') {
                this.program.university = this.school_id;
            }
            AdminService.postData('save_program', this.program)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.program = resp.data;
                        this.updateModal({
                            type: true,
                            message: 'Program saved',
                        });
                        this.$router.push({
                            name: 'ManagePrograms',
                            params: { school_id: this.school_id },
                            query: { school_name: this.school_name },
                        });
                    }
                })
        },
        addRequiredDocs() {
            this.program.required_docs.push({
                name: '',
                desc: '',
                isRequired: true,
            });
        },
        deleteRequiredDocs(ct) {
            this.program.required_docs.splice(ct, 1);
        },
        addAvailableSession() {
            this.program.available_sessions.push({
                name: '',
                resumption_date: '',
                application_deadline: '',
            });
        },
        deleteAvailableSession(ct) {
            this.program.available_sessions.splice(ct, 1);
        },
        getProgram() {
            AdminService.getData(
                'find_Program_For_Admin_By_Id/' + this.program_id
            )
                .then((resp) => {
                    if (resp && resp.data) {
                        this.program = resp.data;
                    }
                })
        },
    },
    computed: {
        countries() {
            const list = countries.getNames('en', { select: 'official' });
            return Object.keys(list).map((key) => ({
                value: key,
                label: list[key],
            }));
        },
    },
    mounted() {
        this.program_id = this.$route.params.program_id;
        this.school_id = this.$route.params.school_id;
        this.school_name = this.$route.query.school_name;
        if (this.program_id !== '0') {
            this.getProgram();
        }
    },
};
</script>
