<template>
    <Form @submit="submitDoc" :validation-schema="documentInfoschema">
        <div class="row mt-md-4">
            <div class="col-12 col-md-6 mt-3 mt-md-0">
                <label>Short Description <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="documentInfo.desc" name="desc" class="form-control" type="text"/>
                <ErrorMessage name="desc" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-6 mt-3 mt-md-0">
                <label>Upload document</label>
                <Field v-model="documentInfo.uploadFiles" id="uploadDocument" name="uploadFiles" class="form-control" type="file" multiple required/>
                <ErrorMessage name="uploadFiles" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 col-md-6 text-end">
                <button type="submit" class="btn primaryBtn">Save</button>
            </div>
        </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { mapActions } from 'vuex'
import UserService from '@/apiServices/students/user.service'
export default {
  name: 'DocumentForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  data: function () {
    const documentInfoschema = yup.object({
      desc: yup.string().max(25).required().label('Description'),
      uploadFiles: yup.mixed().required('File is required')
    })

    return {
      documentInfoschema,
      documentInfo: {
        desc: '',
        uploadFiles: null
      }
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    submitDoc () {
      
      UserService.postDataForm('upload_document', this.documentInfo).then(
        () => {
          
          document.getElementById('uploadDocument').value = ''
          this.documentInfo.desc = ''
          this.$parent.closeDocumentForm()
          this.$parent.fetchDocs()
          this.updateModal({ type: true, message: 'document uploaded' })
        },
        error => {
          
          console.log(error)
        }
      )
    }
  }
}
</script>
