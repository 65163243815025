<template>
    <!-- Progress bar meant to be worked on -->
    <div id="stepProgressBar">
        <div
            v-for="(trk, index) in tracks"
            :key="trk.progress"
            class="step d-flex flex-column"
            :class="{ last: tracks?.length == index + 1 }"
        >
            <div class="step-text text-start">{{ trk.status }}</div>
            <div
                class="bullet"
                :class="{
                    completed: currentStep >= index + 1,
                    last: tracks?.length == index + 1,
                }"
            >
                <i class="bi bi-check-lg" v-if="currentStep >= index + 1"></i>
            </div>
            <div v-if="currentStep >= index + 1" class="middle-line"></div>
        </div>
    </div>
</template>
<script>
import { applicationTracker } from '@/utils/constants';
export default {
    name: 'AppTracker',
    data: function () {
        return {
            tracks: [],
            currentStep: 0,
        };
    },
    props: ['currentStepPass'],
    mounted() {
        this.tracks = applicationTracker;
        this.currentStep = applicationTracker.find(
            (x) => x.status === this.currentStepPass
        )?.progress;
    },
};
</script>
<style>
#stepProgressBar {
    display: flex;
    max-width: 300px;
}

.step {
    padding-right: 30px;
    font-size: 0.8em;
    position: relative;
}

.step-text {
    margin-bottom: 10px;
    color: #5a189a;
}

.bullet {
    border: 1px solid #5a189a;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    color: #5a189a;
    display: flex;
    justify-content: center;
    transition: background-color 500ms;
    align-items: center;
    position: relative;
}

.bullet.completed {
    color: white;
    background-color: #5a189a;
}

.middle-line {
    position: absolute;
    width: calc(100% - 40px);
    border-bottom: 1px solid #5a189a;
    bottom: 10px;
    left: 30px;
}

.step.last .middle-line {
    display: none;
}

/*.bullet.completed:not(.last)::after {
        content: '';
        position: absolute;
        right: -50px;
        bottom: 12.5px;
        height: 1.5px;
        width: 100%;
        background-color: #5a189a;
    }*/
</style>
