<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Schools and Programs" :subText="header" />

        <form @submit.prevent="submitApplyForm">
            <div class="row">
                <div class="col-12 col-md-5">
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="smaller fw-bold">Application ID</div>
                            <div class="text-gray small">
                                {{ application_id }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6" v-if="application">
                            <span
                                class="badge rounded-pill bg-success text-dark py-2 px-3 text-white"
                                v-if="
                                    application.is_submitted &&
                                    application.payment &&
                                    application.payment?.amount_to_pay ==
                                        application.payment?.amount_paid
                                "
                                >Paid</span
                            >
                            <span
                                class="badge rounded-pill bg-warning text-dark py-2 px-3"
                                v-else
                                >Pending payment</span
                            >
                        </div>
                        <div class="mt-3 col-12 col-md-6" v-if="isPaid">
                            <div class="smaller fw-bold">
                                Application Tracker
                            </div>
                            <app-tracker
                                :currentStepPass="calcTracker(application)"
                            ></app-tracker>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted">
                            Academic session
                            <span class="text-danger ml-2 fw-bold">*</span>
                        </div>
                        <select
                            class="form-select"
                            v-model="selected_session"
                            @change="change_session"
                            required
                            :disabled="application && application.is_submitted"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="sess in program?.available_sessions"
                                :key="sess._id"
                                :value="sess.name"
                                :disabled="
                                    checkCanApply(
                                        sess.application_deadline,
                                        program?.available_sessions.length
                                    )
                                "
                            >
                                {{ sess.name }}
                                {{
                                    checkCanApply(
                                        sess.application_deadline,
                                        program?.available_sessions.length
                                    )
                                        ? ' (Deadline Passed)'
                                        : ''
                                }}
                            </option>
                        </select>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="mb-3">
                                <div class="text-muted small">
                                    Resumption Date
                                </div>
                                <div>{{ resumption_date_text }}</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="mb-3">
                                <div class="text-muted small">
                                    Application Deadline
                                </div>
                                <div>{{ application_deadline_text }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12 col-md-4" v-if="program">
                    <div
                        class="d-flex mb-4 align-items-center"
                        v-if="program.university"
                    >
                        <div>
                            <img
                                :src="program.university.logo"
                                class="rounded-circle"
                                width="45"
                                height="45"
                            />
                        </div>
                        <div class="ms-3">
                            <h6 class="mb-0">
                                <router-link
                                    class="text-decoration-none primaryColor me-2"
                                    target="_blank"
                                    :to="{
                                        name: 'SchoolPage',
                                        params: { id: program.university._id },
                                        query: {
                                            name: program.university.name,
                                        },
                                    }"
                                    >{{ program.university.name }}</router-link
                                >
                            </h6>
                            <div class="small">
                                {{ program.university.address }}
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Name</div>
                        <div class="fw-bolds">
                            <router-link
                                class="text-decoration-none primaryColor me-2"
                                target="_blank"
                                :to="{
                                    name: 'ProgramPage',
                                    params: {
                                        school_id: program.university._id,
                                        id: program._id,
                                    },
                                    query: {
                                        name:
                                            program.name +
                                            ' - ' +
                                            program.university.name,
                                    },
                                }"
                                >{{ program.name }}</router-link
                            >
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="text-muted small">Program Type</div>
                        <div>{{ program.program_type }}</div>
                    </div>
                    <div class="mb-3 row">
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Application Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(markUpApplicationFee(program.application_fee)) }}
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="text-muted small">Tuition Fee</div>
                            <div>
                                {{ program.currency_symbol
                                }}{{ toCurrency(program.tuition_fee) }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="col-12 col-md-5 mt-4 mt-md-0"
                    v-if="program && program.required_docs?.length > 0"
                >
                    <div><h5 class="mb-1">Application Requirements</h5></div>
                    <div class="small mb-4">
                        You can upload all your documents via your profile
                        <router-link
                            class="text-decoration-none primaryColor"
                            :to="{ name: 'DocumentList' }"
                            >here</router-link
                        >
                    </div>
                    <div
                        class="mb-4"
                        v-for="(req, ind) in program.required_docs"
                        :key="req._id"
                    >
                        <div class="small">
                            {{ req.name }}
                            <span
                                v-if="req.isRequired"
                                class="text-danger ml-2 fw-bold"
                                >*</span
                            >
                        </div>
                        <div class="smaller">{{ req.desc }}</div>
                        <div v-if="req.filePath && req.filePath != ''">
                            <a :href="req.filePath" target="_blank" class="small"
                            v-if="
                                application_id == '0' ||
                                (application && !application.is_submitted)
                            "
                            >Click to download this form, fill and upload it below</a>
                        </div>
                        <select
                            class="form-select mt-2 req_docs"
                            :required="req.isRequired"
                            :data-id="req._id"
                            :disabled="application && application.is_submitted"
                            :id="'req-' + ind"
                        >
                            <option value="">Select One</option>
                            <option
                                v-for="doc in docs"
                                :key="doc._id"
                                :value="doc._id"
                            >
                                {{ doc.name }} - {{ doc.desc }}
                            </option>
                        </select>
                        <div
                            class="mt-2 text-center"
                            v-if="
                                application_id == '0' ||
                                (application && !application.is_submitted)
                            "
                        >
                            <a
                                role="button"
                                class="text-danger text-decoration-none"
                                data-bs-toggle="modal"
                                data-bs-target="#uploadModal"
                                @click.prevent="saveSelectID('req-' + ind)"
                                >Or Upload New File</a
                            >
                        </div>
                    </div>
                    <!-- Emergency contact -->
                    <div><h5 class="mb-1">Emergency Contact</h5></div>
                    <div class="mb-4">
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Name
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_name"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label class="text-muted small" for="emergency-name"
                                >Email
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="email"
                                name="emergency-name"
                                class="form-control"
                                v-model="emergency_contact_email"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div class="mb-2">
                            <label
                                class="text-muted small"
                                for="emergency-phone"
                                >Phone Number
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >

                            <input
                                type="tel"
                                name="emergency-phone"
                                class="form-control"
                                v-model="emergency_contact_phone_number"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                        <div>
                            <label
                                class="text-muted small"
                                for="emergeny-address"
                                >Address
                                <span class="text-danger ml-2 fw-bold"
                                    >*</span
                                ></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                name="emergency-address"
                                v-model="emergency_contact_address"
                                :disabled="
                                    application && application.is_submitted
                                "
                                required
                            />
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="mt-3">
                <div
                    v-if="
                        application_id == '0' ||
                        (application && !application.is_submitted)
                    "
                >
                    <button
                        type="button"
                        class="btn btn-link text-decoration-none primaryColor me-3 py-2 fw-bold mb-3 mb-md-0 px-5 px-md-2"
                        @click="saveApplication"
                    >
                        Save Application
                    </button>
                    <button type="submit" class="btn primaryBtn px-5 py-2">
                        Submit Application
                    </button>
                </div>

                <button
                    type="button"
                    class="btn primaryBtn px-5 py-2"
                    @click="RequestPaymentLink"
                    v-if="
                        application_id != '0' &&
                        application &&
                        application.is_submitted &&
                        application.payment?.amount_to_pay !=
                            application.payment?.amount_paid
                    "
                >
                    Pay Application Fee
                </button>
            </div>-->
            <div
                class="col-12 col-md-6 mt-4 mt-md-0 mb-3"
                v-if="application?.application_complete"
            >
                <h5 class="primaryColor fw-bold">Final Decision</h5>
                <div>
                    <div class="mb-3">
                        <div class="text-muted">Is Application Complete</div>
                        <div
                            :class="
                                application?.application_complete
                                    ? 'text-success'
                                    : 'text-danger'
                            "
                        >
                            {{
                                application?.application_complete ? 'YES' : 'NO'
                            }}
                        </div>
                    </div>
                    <div v-if="application?.declined_by_schooliply">
                        <div class="mb-3">
                            <div class="text-muted">Declined by Schooliply</div>
                            <div class="text-danger">YES</div>
                        </div>
                        <div class="mb-3">
                            <div class="text-muted">Reason for declining</div>
                            <div class="">
                                {{ application?.reason_for_decline }}
                            </div>
                        </div>
                    </div>
                    <div v-if="application?.declined_by_schooliply == false">
                        <div class="mb-3">
                            <div class="text-muted">
                                Admission Given By School
                            </div>
                            <div
                                :class="
                                    application?.admission_granted
                                        ? 'text-success'
                                        : 'text-danger'
                                "
                            >
                                {{
                                    application?.admission_granted
                                        ? 'YES'
                                        : 'NO'
                                }}
                            </div>
                        </div>
                        <div
                            class="mb-3"
                            v-if="application?.decision_documents?.length > 0"
                        >
                            <h6 class="primaryColor fw-bold">
                                Decision documents
                            </h6>
                            <div
                                v-for="doc in application?.decision_documents"
                                :key="doc._id"
                                class="mb-3 row"
                            >
                                <div class="col-9 col-md-5">
                                    <div class="fw-bold">
                                        {{ doc.file_type }}
                                    </div>
                                </div>
                                <div class="col-3 col-md-5">
                                    <a :href="doc.path_to_file" target="_blank"
                                        ><i class="bi bi-cloud-download"></i>
                                        Download</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Needed conditions for additional documents modal => coming very soon-->
            <div
                class="row mt-3"
                v-if="
                    application_id != '0' &&
                    application &&
                    application.is_submitted &&
                    application.payment &&
                    application.payment?.amount_to_pay ==
                        application.payment?.amount_paid
                "
            >
                <a
                    role="button"
                    class="primaryColor text-decoration-none fw-bold mb-4"
                    data-bs-toggle="modal"
                    data-bs-target="#uploadAdditionalModal"
                    >Upload Additional File</a
                >
                <div class="col-12 col-md-9 text-center small">
                    If you want to withdraw this application, please reach out
                    to our admin.
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import * as yup from 'yup';
import AppTracker from '@/components/common/Tracker.vue';
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import ApiService from '@/apiServices/api.service';
import {
    toCurrency,
    markUpApplicationFee,
    calcTracker,
    checkCanApply
} from '@/utils/constants';
export default {
    name: 'AdminViewApplyPage',
    components: {
        PageHeader,
        AppTracker
    },
    data: function () {
        const documentInfoschema = yup.object({
            desc: yup.string().max(25).required().label('Description'),
            uploadFiles: yup.mixed().required('File is required'),
        });
        return {
            program: null,
            header: 'Schools and Programs',
            application_id: '0',
            docs: [],
            user: null,
            application_deadline_text: 'Select Academic session first',
            resumption_date_text: 'Select Academic session first',
            selected_session: '',
            application: null,
            isPaid: false,
            maxFreeApplications: 2,
            documentInfoschema,
            documentInfo: {
                desc: '',
                uploadFiles: null,
            },
            uploadSelectId: '',
            disableUpload: false,
            emergency_contact_name: '',
            emergency_contact_phone_number: '',
            emergency_contact_address: '',
            emergency_contact_email: '',
        };
    },
    mounted() {
        this.startNewApp();
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo'
        }),
        toCurrency,
        markUpApplicationFee,
        checkCanApply,
        saveSelectID(val) {
            this.uploadSelectId = val;
        },
        calcTracker(app) {
            return calcTracker(app);
        },
        getSchoolInfo() {
            return ApiService.getData(
                'edu/getProgram/school/' +
                    this.$route.params.school_id +
                    '/program/' +
                    this.$route.params.program_id
            ).then(
                (resp) => {
                    return Promise.resolve(resp.data);
                },
                (error) => {
                    return Promise.reject(error);
                }
            );
        },
        startNewApp() {
            this.application_id = this.$route.params.id;
            Promise.all([
                this.getSchoolInfo()
            ]).then(([sch]) => {
                if (sch) {
                    //check application fee

                    // combine program reqs with schools reqs
                    if(sch && sch.university && sch.university.required_docs?.length > 0){
                        sch.required_docs = [...sch.required_docs, ...sch.university.required_docs];
                        sch.required_docs.sort((a, b) => (a.isRequired > b.isRequired) ? -1 : 1);
                    }
                    this.program = sch;
                    this.header =
                        'Schools and Programs' +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.university?.name +
                        '<i class="bi bi-chevron-right mx-2"></i>' +
                        this.program.name;
                }
            });
        },
        change_session() {
            if (
                this.selected_session !== '' &&
                this.program &&
                this.program.available_sessions?.length > 0
            ) {
                this.resumption_date_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).resumption_date;
                this.application_deadline_text =
                    this.program.available_sessions.find(
                        (x) => x.name === this.selected_session
                    ).application_deadline;
            } else {
                this.resumption_date_text = 'Select Academic session first';
                this.application_deadline_text =
                    'Select Academic session first';
            }
        },
        gatherApplicationDetails() {
            let sD = {};
            // const upDocs = [];
            if (this.application !== null) {
                sD = this.application;
            }
            // emergency contact
            sD.emergency_contact_name = this.emergency_contact_name;
            sD.emergency_contact_phone_number =
                this.emergency_contact_phone_number;
            sD.emergency_contact_address = this.emergency_contact_address;
            sD.emergency_contact_email = this.emergency_contact_email;
            sD.academic_session = this.selected_session;
            sD.academic_session = this.selected_session;
            sD.program = this.$route.params.program_id;
            sD.university = this.$route.params.school_id;
            sD.status = this.application == null ? 'pending' : sD.status;
            if (sD.documents == undefined) {
                sD.documents = [];
            }
            const selects = document.getElementsByClassName('req_docs');
            if (selects !== null && selects.length > 0) {
                for (let i = 0; i < selects.length; i++) {
                    let doc_id = selects[i].getAttribute('data-id');
                    let find = sD.documents.find(
                        (x) => x.doc_to_upload == doc_id
                    );
                    if (find) {
                        // update value
                        let select_value =
                            selects[i].value === '' ? null : selects[i].value;
                        sD.documents.find(
                            (x) => x.doc_to_upload == doc_id
                        ).uploaded_doc = select_value;
                    } else {
                        // add new value
                        const obj = {
                            doc_to_upload: selects[i].getAttribute('data-id'),
                            uploaded_doc:
                                selects[i].value === ''
                                    ? null
                                    : selects[i].value,
                            is_required: selects[i].hasAttribute('required'),
                        };
                        sD.documents.push(obj);
                    }
                }
            }
            //sD.documents = upDocs;
            return sD;
        },
    },
    computed: {
        currentUser() {
            return this.$store.state.admin.currentUser;
        },
    }
};
</script>
