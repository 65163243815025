<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Scout Application" subText="Submitted application" />
        <div class="row">
            <div class="col-12 col-md-9">
                <form>
                    <div class="mb-4">
                        <h5 class="primaryColor">Personal Info</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>First Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.first_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Last Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.last_name" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Nationality <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <select class="form-select" v-model="register.nationality" required>
                                        <option>Select your nationality</option>
                                        <option v-for="country in countries" :value="country.label" :key="country.value">
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <div class="mb-2"><label>Gender <span class="text-danger ml-2 fw-bold">*</span></label></div>
                                    <input type="radio" v-model="register.gender" name="gender" value="M" id="male" required/>
                                    <label for="male" class="ms-1">Male</label>
                                    <input type="radio" v-model="register.gender" name="gender" value="F" id="female" class="ms-3" required/>
                                    <label for="female" class="ms-1">Female</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Business Details</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Business Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.business_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Business Email Address <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="email" class="form-control" v-model="register.business_email" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Phone Number <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.business_phone" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Certificate of Incorporation <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <div class="mt-2" v-if="register && register.incorporation_doc && register.incorporation_doc != ''">
                                        <a :href="register.incorporation_doc" target="_blank"><i class="bi bi-cloud-download"></i> Download</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Address Line 1 <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.address_line_1" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>City/Town <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.city" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Address Line 2</label>
                                    <input type="text" class="form-control" v-model="register.address_line_2"/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>State/Province <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.state" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>ZIP/Postal Code <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.postal_code" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Country <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <select class="form-select" v-model="register.country" required>
                                        <option>Select your nationality</option>
                                        <option v-for="country in countries" :value="country.label" :key="country.value">
                                            {{ country.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Online Presence</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Website <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="url" class="form-control" v-model="register.website" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Facebook Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.facebook" required/>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-3">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Instagram Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.instagram" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Twitter Account <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.twitter" required/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4">
                        <h5 class="primaryColor">Bank Details</h5>
                        <div class="border rounded-3 py-4 px-4 bg-white">
                            <div class="row">
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Bank Name <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.bank_name" required/>
                                </div>
                                <div class="col-12 col-md-6 mt-3 mt-md-0">
                                    <label>Bank Account Number <span class="text-danger ml-2 fw-bold">*</span></label>
                                    <input type="text" class="form-control" v-model="register.bank_account_number" required/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-12 col-md-6">
                            <h5 class="text-danger">Decision</h5>
                            <select v-model="decision" class="form-select" :disabled="register && register.application_complete">
                                <option value="">Select One</option>
                                <option :value="true">Approve</option>
                                <option :value="false">Decline</option>
                            </select>
                        </div>
                        <div class="mt-3" v-show="register && !register.application_complete" @click="saveDecision">
                            <button type="button" class="btn primaryBtn">Save Decision</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
   name: "ViewScoutApplication",
   components: {
    PageHeader
  },
    data: function () {
        return {
            register: {
                first_name: '',
                last_name: '',
                nationality: '',
                gender: '',
                business_name: '',
                business_email: '',
                password: '',
                business_phone: '',
                uploadFiles: null,
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                postal_code: '',
                country: '',
                bank_name: '',
                incorporation_doc: '',
                bank_account_number: '',
                website: '',
                facebook: '',
                instagram: '',
                twitter: ''
            },
            loading: false,
            email: '',
            password: '',
            message: '',
            decision: ''
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        saveDecision: function () {
            if(this.decision === ""){
                return;
            }
            AdminService.postData('scout_application_decision/' + this.$route.params.id, {decision: this.decision}).then((resp) => {
                if(resp && resp.data) {
                    this.updateModal({ type: true, message: 'Decision has been saved' });
                    this.$router.push({ name: 'ScoutApplicationList' });
                }
            })
        },
        fetchApplication: function () {
            AdminService.getData('scout_application/' + this.$route.params.id).then((resp) => {
                if(resp && resp.data) {
                    this.register = resp.data
                    if (this.register.application_complete && (this.register.application_approved || !this.register.application_approved)) {
                        this.decision = this.register.application_approved;
                    }
                }
            })
        },
    },
    computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
  },
    mounted() {
        this.fetchApplication();
    },
};
</script>

