<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Applications" subText="Review the status of your applications."/>
        <div v-if="apps.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">You have not applied to any schools yet. Find a school, start applying.</div>
          <div class="mt-4">
            <router-link to="/scout/find-programs/schools" class="btn primaryBtn text-decoration-none py-2 px-4">Find Schools/Programs</router-link>
          </div>
        </div>
        <div v-if="apps.length > 0" class="col-12 col-md-12 tableParent">
          <!--<table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>Student Name</th>
                <th>School</th>
                <th>Program</th>
                <th>Application ID</th>
                <th>Status</th>
                <th>Date Applied</th>
                <th>Actions</th>
                <th>Application Tracker</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="app in apps" :key="app._id">
                  <td>{{app.user_id?.personal_info?.first_name}} {{app.user_id?.personal_info?.last_name}}</td>
                  <td>{{app.university.name}}</td>
                  <td>{{app.program.name}}</td>
                  <td>{{app._id}}</td>
                  <td>{{app.status}}</td>
                  <td>{{formatDateLocal(app.created_on)}}</td>
                  <td><h6><router-link class="text-decoration-none primaryColor" :to="{name: 'ScoutStudentApplyForm', params: { studentId: app.user_id?._id, id: app._id, school_id: app.university._id, program_id: app.program._id}}">View Application</router-link></h6></td>
                  <td><app-tracker :currentStepPass="calcTracker(app)"></app-tracker></td>
              </tr>
            </tbody>
          </table>-->

          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>
          
          <EasyDataTable
            :headers="headers"
            :items="appsDataTable"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-date_applied="appD1">
            {{formatDateLocal(appD1.date_applied)}}
          </template>
          <template #item-operation="appD1">
            <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'ScoutStudentApplyForm', params: { studentId: appD1.full_app.user_id?._id, id: appD1.full_app._id, school_id: appD1.full_app.university._id, program_id: appD1.full_app.program._id}}">View Application</router-link></h6>
          </template>
          <template #item-tracker="appD">
                    <div class="text-center text-danger mb-2">
                        {{ calcTracker(appD.full_app) }}
                    </div>
                    <div class="stepProgressBar">
                    <div
                        v-for="(trk, index) in tracks"
                        :key="trk.progress"
                        class="step d-flex flex-column"
                        :class="{ last: tracks?.length == index + 1 }"
                    >
                        <div class="step-text text-start">{{ trk.status }}</div>
                        <div
                            class="bullet"
                            :class="{
                                completed: tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1,
                                last: tracks?.length == index + 1,
                            }"
                        >
                            <i class="bi bi-check-lg" v-if="tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1"></i>
                        </div>
                        <div v-if="tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1" class="middle-line"></div>
                    </div>
                </div>
                </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
// import AppTracker from '@/components/common/Tracker.vue'
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
import { formatDate } from '@/utils/format'
import { calcTracker } from '@/utils/constants';
import { applicationTracker } from '@/utils/constants';
export default {
  name: 'StudentApplications',
  components: {
    PageHeader,
    // AppTracker
  },
  data: function () {
    return {
      tracks: [],
      apps: [],
      appsDataTable: [],
      searchvalue: "",
      headers: [
        { text: "Student Name", value: "student_name", sortable: true },
        { text: "School", value: "school_name", sortable: true },
        { text: "Program", value: "program_name", sortable: true },
        { text: "Application ID", value: "application_id", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Date Applied", value: "date_applied", sortable: true },
        { text: "", value: "operation" },
        {text:"Application Tracker", value: "tracker"}
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getApps () {
      
      ScoutService.getData('get_scout_applications').then((resp) => {
        
        if (resp && resp.data) {
          this.apps = resp.data
          this.appsDataTable = this.apps.map(x => ({
            student_name: `${x.user_id?.personal_info?.first_name} ${x.user_id?.personal_info?.last_name}`,
            school_name: x.university.name,
            program_name: x.program.name, 
            application_id: x._id,
            status: x.status,
            date_applied: x.created_on,
            full_app: x
          }))
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    },
    calcTracker (app) {
      return calcTracker(app)
    }
  },
  mounted () {
    this.tracks = applicationTracker;
    this.getApps()
  }
}
</script>
