import { createStore } from 'vuex'
// import userStore from './user'
import { userAuthStore } from './user.auth'
import { adminAuthStore } from './admin.auth'
import { scoutAuthStore } from './scout.auth'
import { generalStore } from './generalStore'
import createPersistedState from 'vuex-persistedstate'

const store = {
  modules: {
    user: userAuthStore,
    admin: adminAuthStore,
    scout: scoutAuthStore,
    general: generalStore
  },
  plugins: [createPersistedState()]
}

export default createStore(store)
