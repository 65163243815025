<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Students" subText="All Your Students."/>
        <div class="mb-3">
          <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'CreateNewStudent'}"><i class="bi bi-plus"></i> Add New Student</router-link></h6>
        </div>
        <div v-if="students.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no student added yet</div>
        </div>
        <div v-if="students.length > 0" class="col-12 col-md-10 tableParent">
          
          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>
          
          <EasyDataTable
            :headers="headers"
            :items="studentsTable"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-operation="stu">
            <router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'ScoutStudentPersonalInfo', params: {studentId: stu._id}}"><i class="bi bi-pencil-square me-2"></i>View</router-link>
          </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
import { formatDate } from '@/utils/format'
export default {
  name: 'ScoutStudentsList',
  components: {
    PageHeader
  },
  data: function () {
    return {
      students: [],
      studentsTable: [],
      searchvalue: "",
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Other Name", value: "other_names", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getStudents () {
      
      ScoutService.getData('get_scout_users').then((resp) => {
        
        if (resp && resp.data) {
          this.students = resp.data
          this.studentsTable = this.students.map(x => ({...x.personal_info, email: x.email, _id: x._id}))
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    this.getStudents();
  }
}
</script>
