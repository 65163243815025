import store from '@/store/index'

export function authHeader () {
  const user = store.state.user.currentUser
  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token }
  } else {
    return {}
  }
}

export function authHeaderAdmin () {
  const user = store.state.admin.currentUser
  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token }
  } else {
    return {}
  }
}

export function authHeaderScout () {
  const user = store.state.scout.currentUser
  if (user && user.access_token) {
    return { Authorization: 'Bearer ' + user.access_token }
  } else {
    return {}
  }
}
