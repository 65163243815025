<template>
    <div class="container px-0">
        <div class="row">
            <div class="col-12 col-md-9">
                <div class="row">
                    <div class="col-12">
                        <input
                            v-model="searchForm.name"
                            type="text"
                            class="form-control"
                            placeholder="Enter school name"
                        />
                    </div>
                </div>
                <div class="row">
                    <!--<div class="col-12 col-md-6 d-md-flex mt-2 mt-md-3">
                        <select
                            v-model="searchForm.school_type"
                            class="form-select"
                        >
                            <option value="">School type</option>
                            <option value="University">Universities</option>
                            <option value="College">Colleges</option>
                        </select>
                        <select
                            v-model="searchForm.level"
                            class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                        >
                            <option value="">Level</option>
                            <option
                                v-for="(prg, index) in program_list"
                                :key="index"
                                :value="prg"
                            >
                                {{ prg }}
                            </option>
                        </select>
                        <select v-model="searchForm.tuition_fee" class="form-select ms-0 ms-md-2 mt-2 mt-md-0">
                        <option value="">Tuition fee</option>
                        <option value="10k-15k">10,000 to 15,000</option>
                        <option value="15k-20k">15,000 to 20,000</option>
                    </select> 
                        <select
                            v-model="searchForm.application_fee"
                            class="form-select ms-0 ms-md-2 mt-2 mt-md-0"
                        >
                            <option value="">Application fee</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>-->
                    <div class="col-12 col-md-6 mt-2 mt-md-3">
                        <select
                            v-model="searchForm.country"
                            class="form-select"
                        >
                            <option value="">Select a Country</option>
                            <option
                                v-for="country in countries"
                                :value="country.label"
                                :key="country.value"
                            >
                                {{ country.label }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-3 mt-3 mt-md-0">
                <div class="d-flex flex-md-column">
                    <div>
                        <button
                            type="button"
                            class="btn primaryBtn"
                            @click="searchSchools(true)"
                        >
                            Search
                        </button>
                    </div>
                    <div class="ms-3 ms-md-0 mt-2">
                        <a
                            href="#"
                            class="text-decoration-none text-secondary"
                            @click.prevent="clearSearch"
                            >Clear all</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex mt-4">
        <h6 class="mb-0 me-5">Schools</h6>
        <router-link
            :to="{ name: 'GuestProgramList' }"
            class="primaryColor text-decoration-none"
        >
            <h6>Programs</h6>
        </router-link>
    </div>
    <div class="mt-4">
        <div v-if="schools.length == 0">
            <div class="mt-1 fw-bold">No School Found</div>
        </div>
        <div v-if="schools.length > 0" class="row">
            <div v-for="sch in schools" :key="sch._id" class="d-flex mb-4 flex-column col-12 col-md-6 myCard">
                <div class="card h-100">
                    <div class="card-header d-flex align-items-center ps-0">
                        <div :class="sch.logo == null ? 'ps-2' : 'ps-3'">
                            <img
                                :src="sch.logo ?? 'assets/images/school_logo_only.png'"
                                class="rounded-circle"
                                width="45"
                                height="45"
                            />
                        </div>
                        <div class="ms-1">
                            <h6 class="mb-0">
                                <router-link
                                    class="text-decoration-none primaryColor"
                                    :to="{
                                        name: 'SchoolPage',
                                        params: { id: sch._id },
                                        query: {name: sch.name}
                                    }"
                                    >{{ sch.name }}</router-link
                                >
                            </h6>
                            <div v-if="sch.country" class="small">
                                {{ sch.country }}
                            </div>
                        </div>
                    </div>
                    <div class="card-body d-flex flex-column mt-2">
                        <div class="row small">
                            <div class="col-6 col-md-3 mb-3 mb-md-0">
                                <div class="title muted">School Type</div>
                                <div>{{ sch.school_type }}</div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-0">
                                <div class="title muted">Programs</div>
                                <div>{{ sch.programs_offered.join(', ') }}</div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-0">
                                <div class="title muted">Available Programs</div>
                                <div>{{ sch.programs.length }}</div>
                            </div>
                            <div class="col-6 col-md-3 mb-3 mb-md-0">
                                <div class="title muted">Tuition Range</div>
                                <div>{{ sch.tuition_range }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3" v-if="nextPage">
            <button class="btn primaryBtn" @click="searchSchools()">Load More Results</button>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import ApiService from '../apiServices/api.service';
import { Serialize } from '@/utils/constants';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
    name: 'SchoolList',
    data: function () {
        return {
            schools: [],
            filter: {},
            searchForm: {
                name: '',
                country: '',
                school_type: '',
                level: '',
                tuition_fee: '',
                application_fee: '',
                page: ''
            },
            program_list: [
                {name: 'Bachelors', id: 'Bachelor'},
                {name: 'Diploma', id: 'Diploma'},
                {name: 'Masters', id: 'Master'},
                {name: 'MBA', id: 'MBA'},
                {name: 'Certificate', id: 'Certificat'},
                {name: 'Dual Degree', id: 'Dual Degree'},
                {name: 'PhD', id: 'PhD'},
            ],
            nextPage: null,
            delayLoading: false 
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        clearSearch() {
            this.searchForm = {
                name: '',
                country: '',
                school_type: '',
                level: '',
                tuition_fee: '',
                application_fee: '',
                page: ''
            };
            this.nextPage = null;
            this.delayLoading = false;
            this.schools = [];
            this.searchSchools();
        },
        cleanQueries(opt) {
            Object.keys(opt).forEach(key => {
                if (opt[key] == null || opt[key] == "") {
                    delete opt[key];
                }
            });
            return opt;
        },
        getFromQueries() {
            let query = this.$route.query;
            Object.keys(this.searchForm).forEach(key => {
                if (query[key] != null && query[key] != "") {
                    this.searchForm[key] = query[key];
                }
            });
        },
        searchSchools(resetSearch = false) {
            if(resetSearch) {
                this.searchForm.page = '';
                this.nextPage = null;
                this.schools = [];
            }
            if(this.nextPage != null) {
                this.searchForm.page = this.nextPage;
            }
            this.filter = this.searchForm; //this.$parent.$parent.searchForm
            let query = Object.assign({}, this.filter);
            let newQuery = this.cleanQueries(query);
            this.$router.replace({ query: newQuery });
            let qry = Serialize(this.filter);
            qry = qry !== '' ? '?' + qry : '';
            ApiService.getData('edu/findUniversities' + qry)
                .then((resp) => {
                    if (resp && resp.data) {
                        this.schools = this.schools.concat(resp.data.schools);
                        this.nextPage = resp.data.nextPage == null ? null : resp.data.nextPage.toString();
                        this.delayLoading = false;
                    }
                })
        },
        registerPageScroll() {
            // detect scroll to bottom
            if ((window.innerHeight + Math.round(window.scrollY)) >= (document.body.offsetHeight * 0.95)) {
                // you're at the bottom of the page
                if(this.nextPage && this.delayLoading == false) {
                    this.delayLoading = true;
                    this.searchSchools()
                }  
            }
        },
    },
    unmounted () {
        window.removeEventListener('scroll', this.registerPageScroll);
    },
    mounted() {
        /*if (this.$store.state.user.currentUser !== null && this.$route.name != "SchoolList") {
        console.log('user schools')
        this.$router.push({ name: 'SchoolList' })
        return
      }
      if (this.$store.state.admin.currentUser !== null && this.$route.name != "AdminSchoolList") {
        console.log('admin schools')
        this.$router.push({ name: 'SchoolList' })
        return
      }*/
        window.addEventListener("scroll", this.registerPageScroll);
        this.getFromQueries();
        this.searchSchools();
        this.emitter.on('re-search', (searchData) => {
            this.filter = searchData;
            this.searchSchools();
        });
    },
    computed: {
        currentUser() {
            let currUser = null;
            if (this.$store.state.user.currentUser !== null) {
                currUser = this.$store.state.user.currentUser;
            }
            if (this.$store.state.admin.currentUser !== null) {
                currUser = this.$store.state.admin.currentUser;
            }
            if (this.$store.state.scout.currentUser !== null) {
                currUser = this.$store.state.scout.currentUser;
            }
            return currUser;
        },
        countries() {
            const list = countries.getNames('en', { select: 'official' });
            return Object.keys(list).map((key) => ({
                value: key,
                label: list[key],
            }));
        },
    },
};
</script>
<style scoped>
.title {
    color: #6a6a6a;
    font-size: 12px;
}

.myCard .card-header, .eachProgram .card-footer{
    background: rgb(0 0 0 /5%) !important;
}
</style>
