<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Edit Blog" subText="Add or update a blog." />

        <form @submit.prevent="saveBlog">
            <div class="row mb-md-4">
                <div class="col-12 col-md-5">
                    <label
                        >Title<span class="text-danger ml-2 fw-bold"
                            >*</span
                        ></label
                    >
                    <input
                        type="text"
                        v-model="blog.title"
                        class="form-control"
                        required
                    />
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label
                        >Blog Image
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <input
                        type="text"
                        v-model="blog.main_image"
                        class="form-control"
                        v-if="blog.main_image != ''"
                        disabled
                        required
                    />
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        v-else
                        @change="onFileChange($event)"
                    />
                    <button
                        class="btn btn-link"
                        v-if="blog.main_image != ''"
                        @click="replaceImage"
                    >
                        Replace Image
                    </button>
                </div>
            </div>

            <div class="row mb-md-4">
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >Description
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >

                    <textarea
                        v-model="blog.description"
                        class="form-control"
                        rows="5"
                        required
                    ></textarea>
                </div>
            </div>
            <!-- <div class="row mb-md-4">
                <label for="file-upload">Upload Images</label>
                <div
                    class="mt-3"
                    v-for="(img, index) in blog.blog_images"
                    :key="index"
                >
                    <input
                        type="text"
                        v-model="img.path_to_file"
                        class="form-control"
                        v-if="img.path_to_file !== ''"
                        disabled
                        required
                    />
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        v-else
                        @change="onImageChange($event, img)"
                    />
                    <button
                        class="btn btn-link"
                        v-if="img.path_to_file !== ''"
                        @click="replaceDocument(img)"
                    >
                        Replace Image
                    </button>
                    <div class="mt-3">
                        <button
                            type="button"
                            class="btn btn-danger"
                            @click="deleteBlogImage(index)"
                        >
                            Delete Image
                        </button>
                    </div>
                </div>
                <div class="mt-2 text-start">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="addBlogImage"
                    >
                        Add Image
                    </button>
                </div>
            </div> -->

            <div class="row mb-md-4">
                <div class="col-12 col-md-10 mt-3 mt-md-0">
                    <label
                        >Post Content
                        <span class="text-danger ml-2 fw-bold">*</span></label
                    >
                    <ckeditor
                        :editor="editor"
                        v-model="blog.content"
                        :config="editorConfig"
                    ></ckeditor>
                    <!-- <textarea
                        id="editor2"
                        v-model="blog.content"
                        class="form-control"
                        required
                    ></textarea> -->
                </div>
            </div>
            <div class="mt-3">
                <label for="show_to_public"
                    >Display on blog page?
                    <!-- <span class="text-danger ml-2 fw-bold">*</span> -->
                </label>
                <select
                    v-model="blog.show_to_public"
                    class="form-select ms-0 mt-2 mt-md-0"
                    name="show_to_public"
                    style="width: auto"
                >
                    <option :value="true">Visible</option>
                    <option :value="false">Not Visible</option>
                </select>
            </div>

            <div class="mt-4">
                <button type="submit" class="btn primaryBtn px-5 py-2">
                    Save blog
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ImageInsert from '@ckeditor/ckeditor5-image/src/imageinsert';
// import AutoImage from '@ckeditor/ckeditor5-image/src/autoimage';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
import { uuid } from 'vue-uuid';
export default {
    name: 'EditBlog',
    components: {
        PageHeader,
    },
    data: function () {
        return {
            uuid: uuid.v1(),
            editor: ClassicEditor,
            blog_id: '0',
            blog: {
                title: '',
                main_image: '',
                content: '',
                show_to_public: true,
                description: '',
                slug: '',
                blog_images: [
                    {
                        path_to_file: '',
                    },
                ],
                order_index: 1,
            },
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        replaceImage() {
            this.blog.main_image = '';
        },
        saveBlog() {
            if (this.blog.slug === '') {
                this.blog.slug = this.slug;
            }
            AdminService.postData('save_blog', this.blog).then((resp) => {
                if (resp && resp.data) {
                    this.blog = resp.data;
                    this.updateModal({
                        type: true,
                        message: 'Blog saved',
                    });
                    this.$router.push({ name: 'ManageBlogs' });
                }
            });
        },
        onFileChange(event) {
            // this.blog.image = event.target.files[0];
            let up = {
                path: 'blog',
                uploadFiles: [event.target.files[0]],
            };
            AdminService.postDataForm('upload_document', up).then((resp) => {
                if (resp && resp.data) {
                    this.blog.main_image = resp.data;
                }
            });
        },
        onImageChange(event, img) {
            // doc.file = event.target.files[0];
            let up = {
                path: 'admissiondocs',
                uploadFiles: [event.target.files[0]],
            };
            AdminService.postDataForm('upload_document', up).then((resp) => {
                if (resp && resp.data) {
                    img.path_to_file = resp.data;
                }
            });
            //doc.path_to_file = "test post";
        },
        replaceDocument(img) {
            img.path_to_file = '';
        },
        deleteBlogImage(ct) {
            this.blog.blog_images.splice(ct, 1);
        },
        addBlogImage() {
            this.blog.blog_images.push({
                path_to_file: '',
            });
        },
        getBlog() {
            AdminService.getData('get_blog/' + this.blog_id).then((resp) => {
                if (resp && resp.data) {
                    this.blog = resp.data;
                }
                // console.log(this.blog);
            });
        },
        sanitizeTitle(title) {
            var slug = '';
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            // add unique uuid
            slug = `${slug}-${this.uuid}`;

            return slug;
        },
    },
    computed: {
        slug() {
            var slug = this.sanitizeTitle(this.blog.title);
            return slug;
        },
    },
    mounted() {
        this.blog_id = this.$route.params.id;
        if (this.blog_id !== '0') {
            this.getBlog();
        }
    },
};
</script>
