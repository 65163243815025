<template>
    <Form
        @submit="submitBackgroundInfo"
        :validation-schema="backgroundInfoschema"
        :initial-values="backgroundInfoUser"
    >
        <div class="row">
            <h5 class="fw-bold">Add past visa refusal</h5>
            <div class="col-12 col-md-5">
                <label
                    >Country
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <Field
                    v-model="backgroundInfo.country"
                    name="country"
                    class="form-select"
                    as="select"
                >
                    <option>Country</option>
                    <option
                        v-for="country in countries"
                        :value="country.value"
                        :key="country.value"
                    >
                        {{ country.label }}
                    </option>
                </Field>
                <ErrorMessage name="country" as="div" class="text-danger" />
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label
                    >Date of refusal
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <Field
                    v-model="backgroundInfo.date_of_refusal"
                    name="date_of_refusal"
                    class="form-control"
                    type="date"
                />
                <ErrorMessage
                    name="date_of_refusal"
                    as="div"
                    class="text-danger"
                />
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label
                    >What were the reasons given?
                    <span class="text-danger ml-2 fw-bold">*</span></label
                >
                <Field
                    v-model="backgroundInfo.reason"
                    name="reason"
                    class="form-control"
                    as="textarea"
                />
                <ErrorMessage name="reason" as="div" class="text-danger" />
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label>Upload refusal letter if you have it</label>
                <Field
                    v-model="backgroundInfo.uploadFiles"
                    id="uploadBackgroundFile"
                    name="uploadFiles"
                    class="form-control"
                    type="file"
                    multiple
                />
            </div>
        </div>
        <div
            v-if="
                backgroundInfo.documents && backgroundInfo.documents.length > 0
            "
            class="row mt-md-4"
        >
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <div>Uploaded Docs</div>
                <ul>
                    <li v-for="doc in backgroundInfo.documents" :key="doc._id">
                        {{ doc.name }}
                        <a :href="doc.url" target="_blank" class="ms-3"
                            ><i class="bi bi-cloud-download"></i
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 col-md-10 text-end">
                <button type="submit" class="btn primaryBtn">Save</button>
            </div>
        </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate';
import ScoutService from '@/apiServices/scout/scout.service'
import * as yup from 'yup';
import { mapActions } from 'vuex';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

export default {
    name: 'BackgroundInfoForm',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    props: ['backgroundInfoPass'],
    data: function () {
        const backgroundInfoschema = yup.object({
            country: yup.string().required('Country is required'),
            date_of_refusal: yup
                .date()
                .typeError('Date is required')
                .required('Date of refusal is required'),
            reason: yup
                .string()
                .max(250, 'Reason cannot be more than 250 characters')
                .required('Reason for refusal is required'),
            uploadFiles: yup.object().nullable(),
        });

        return {
            backgroundInfoschema,
            backgroundInfo: {
                country: '',
                date_of_refusal: '',
                reason: '',
                uploadFiles: null,
                documents: null,
                countryName: null,
            },
        };
    },
    methods: {
        ...mapActions({
            saveBackgroundInfo: 'user/saveBackgroundInfo',
            updateModal: 'general/updateInfo',
        }),
        submitBackgroundInfo() {
            // console.log('full background info', this.backgroundInfo)

            // if (this.educationInfo._id) delete this.educationInfo._id
            // delete this.educationInfo.__v
            delete this.backgroundInfo.user_id;
            delete this.backgroundInfo.documents;
            this.backgroundInfo.countryName = this.getCountryName;

            ScoutService.postDataForm(`save_backgroundInfo/${this.$route.params.studentId}`, this.backgroundInfo).then((resp) => {
                if (resp && resp.data) {
                    let data = resp.data
                    data.date_of_refusal = data.date_of_refusal.split('T')[0];
                    this.backgroundInfo = data;
                    document.getElementById('uploadBackgroundFile').value = '';
                    this.$parent.closeBackgroundInfoForm();
                    this.updateModal({ type: true, message: 'Refusal info saved' });
                }
            })
        },
    },
    computed: {
        countries() {
            const list = countries.getNames('en', { select: 'official' });
            return Object.keys(list).map((key) => ({
                value: key,
                label: list[key],
            }));
        },
        backgroundInfoUser() {
            return this.backgroundInfoPass;
        },
        getCountryName() {
            return this.countries.find(
                (x) => x.value === this.backgroundInfo.country
            ).label;
        },
    },
    mounted() {
        if (this.backgroundInfoPass != null) {
            this.backgroundInfo = this.backgroundInfoPass;
        }
    },
};
</script>
