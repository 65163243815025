<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Admins" subText="All Schooliply Admins."/>
        <div class="mb-3">
          <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'SaveAdmins', params: {id: '0'}}"><i class="bi bi-plus"></i> Add Admin</router-link></h6>
        </div>
        <div v-if="admins.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no admin added yet</div>
        </div>
        <div v-if="admins.length > 0" class="col-12 col-md-10 tableParent">
          <!--<table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="adm in admins" :key="adm._id">
                  <td>{{adm.first_name}}</td>
                  <td>{{adm.last_name}}</td>
                  <td>{{adm.role}}</td>
                  <td>
                    <router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'SaveAdmins', params: {id: adm._id}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
                  </td>
              </tr>
            </tbody>
          </table>-->

          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>

          <EasyDataTable
            :headers="headers"
            :items="admins"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
          >
          <template #item-operation="ads">
            <router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'SaveAdmins', params: {id: ads._id}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
          </template>
          </EasyDataTable>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
import { formatDate } from '@/utils/format'
export default {
  name: 'AdminListAdmins',
  components: {
    PageHeader
  },
  data: function () {
    return {
      admins: [],
      searchvalue: "",
      headers: [
        { text: "First Name", value: "first_name", sortable: true },
        { text: "Last Name", value: "last_name", sortable: true },
        { text: "Role", value: "role", sortable: true },
        { text: "Email", value: "email", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getAdmins () {
      
      AdminService.getData('get_admins').then((resp) => {
        
        if (resp && resp.data) {
          this.admins = resp.data
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    if (this.$store.state.admin.currentUser != null) {
      const admin = this.$store.state.admin.currentUser
      if (admin.uuser.role === 'intermediate-admin' || admin.uuser.role === 'super-admin') {
        this.getAdmins()
      }
    }
  }
}
</script>
