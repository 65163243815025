<template>
  <div class='container py-3 px-3 py-md-5 px-md-5'>
    <page-header :header="'Manage Programs for ' + school_name" subText='Manage programs for school.' />
    <div class="mb-3">
          <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'EditProgram', params: {school_id: school_id, program_id: '0'}, query: {school_name: school_name}}"><i class="bi bi-plus"></i> Add Program</router-link></h6>
        </div>
          <div v-if="programs.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no institution added yet</div>
        </div>
        <div v-if="programs.length > 0" class="col-12 col-md-10 tableParent">
          <!--<table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="prog in programs" :key="prog._id">
                  <td>{{prog.name}}</td>
                  <td>
                    <router-link class="text-decoration-none primaryColor fw-bold" :to="{name: 'EditProgram', params: {school_id: school_id, program_id: prog._id}, query: {school_name: school_name}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
                  </td>
              </tr>
            </tbody>
          </table>-->

          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>

          <EasyDataTable
            :headers="headers"
            :items="programs"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            alternating
            :rows-per-page="100"
          >
            <template #item-operation="pgs">
              <div class="d-flex">
                <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'EditProgram', params: {school_id: school_id, program_id: pgs._id}, query: {school_name: school_name}}"><i class="bi bi-pencil-square me-2"></i>Edit</router-link>
                <router-link class="text-decoration-none primaryColor fw-bold me-3" :to="{name: 'AdminViewApplyForm', params: {school_id: school_id, program_id: pgs._id, id: '0'}}"><i class="bi bi-pencil me-2"></i>View Application Form</router-link>
              </div>
            </template>
          </EasyDataTable>
        </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
export default {
  name: 'ManagePrograms',
  components: {
    PageHeader
  },
  data: function () {
    return {
      programs: [],
      school_name: '',
      school_id: 'value',
      searchvalue: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "", value: "operation" }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getPrograms () {
      
      AdminService.getData('get_school_programs/' + this.$route.params.school_id).then((resp) => {
        
        if (resp && resp.data) {
          this.programs = resp.data
        }
      })
    }
  },
  computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    }
  },
  mounted () {
    this.school_id = this.$route.params.school_id
    this.school_name = this.$route.query.school_name
    this.getPrograms()
  }
}
</script>
