<template>
  <div class="container py-3 px-3 py-md-5 px-md-5">
    <page-header header="Profile" :subText="header"></page-header>
    <div class="d-flex py-3 py-md-0 mt-2 mb-4 mb-md-5 tabButtons" :style="cssProps">
      <router-link :to="{name: 'ScoutStudentPersonalInfo'}">Personal Info</router-link>
      <router-link :to="{name: 'ScoutStudentEducationalHistory'}">Educational History</router-link>
      <router-link :to="{name: 'ScoutStudentExamScoresHistory'}">Exam Scores</router-link>
      <router-link :to="{name: 'ScoutStudentBackgroundInfoHistory'}">Background Info</router-link>
      <router-link :to="{name: 'ScoutStudentDocumentList'}">Documents</router-link>
      <router-link :to="{name: 'ScoutStudentApplicationsList'}">Applications</router-link>
      <router-link :to="{name: 'ScoutStudentPaymentsList'}">Payments</router-link>
    </div>
    <router-view ref="childComp"/>
  </div>
</template>
<script>
import ScoutService from '@/apiServices/scout/scout.service'
import PageHeader from '@/components/PageHeader.vue'
import { Colors } from '@/utils/constants'

export default {
  name: 'StudentProfile',
  components: {
    PageHeader
  },
  data: function () {
    return {
      authenticated: true,
      currentTab: 0,
      student_name: '',
      header: ''
    }
  },
  methods: {
    switchTabs (val) {
      this.currentTab = val
    },
    getStudentProfile () {
      ScoutService.getData('get_personal_info/' + this.$route.params.studentId).then((resp) => {
        
        if (resp && resp.data) {
          let data = resp.data;
          this.student_name = data?.personal_info?.first_name + ' ' + data?.personal_info?.last_name;
          this.header = 'Student' + '<i class="bi bi-chevron-right mx-2"></i><span class="fw-bold">' + this.student_name + '</span>'
                            
        }
      })
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': Colors.primaryColor,
        'overflow-x': 'auto'
      }
    },
    currentUser () {
      return this.$store.state.user.currentUser
    },
    currentTabComp () {
      if (this.currentTab === 0) {
        return 'personal-info'
      } else if (this.currentTab === 1) {
        return 'education-history'
      } else if (this.currentTab === 2) {
        return 'exam-scores-history'
      } else if (this.currentTab === 3) {
        return 'background-info-history'
      } else if (this.currentTab === 4) {
        return 'document-list'
      } else {
        return 'personal-info'
      }
    }
  },
  mounted () {
    this.getStudentProfile();
  }
}
</script>
<style scoped>
  .tabButtons a{
    text-decoration: none;
    margin-right: 50px;
    font-weight: 500;
    color: gray;
  }

  .tabButtons a.router-link-exact-active{
    color: var(--primary-color);
  }
</style>
