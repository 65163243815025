<template>
    <div class="container mt-3">
        <div>
            <router-link to="/">
                <img
                    src="/assets/images/Schooliply-Logo-Dark.png"
                    width="150"
                    class="imageNegMargin"
                />
            </router-link>
        </div>
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12 col-md-6 d-flex flex-column align-items-start">
                <img
                    src="./../../assets/students-registration.png"
                    alt="Student's registration"
                    class="w-100 max-400"
                />

                <!--<div class="mt-2 mb-4 d-flex justify-between align-center">
                    <router-link
                    class="text-decoration-none btn"
                    :to="{ name: 'ScoutRegister' }"
                    >
                        <img
                            src="./../../assets/recruiter-logo.png"
                            alt="recruiter logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a Scout Route</span
                        >
                    </router-link>
                    <a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/school-logo.png"
                            alt="school logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a school</span
                        >
                    </a>
                </div>-->
            </div>

            <div class="col-12 col-md-5 mt-4 offset-md-1 align-self-start">
                <div class="pb-4 px-3 mb-2 text-center">
                    <h2 class="pt-4 pt-md-0 fw-bold text-center start-applying">
                        Start applying.
                    </h2>
                    <div class="text-secondary medium">
                        create an account to get started.
                    </div>
                    <form @submit.prevent="signUp">
                        <div class="mb-3 pt-4">
                            <!-- <label for="signUpUsername" class="form-label small"
                                >Username</label
                            > -->
                            <input
                                type="text"
                                v-model="username"
                                class="form-control"
                                id="signUpUsername"
                                required
                                placeholder="UserName"
                            />
                        </div>
                        <div class="mb-3">
                            <!-- <label for="signUpEmail" class="form-label small"
                                >Email address</label
                            > -->
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="signUpEmail"
                                aria-describedby="emailHelp"
                                required
                                placeholder="Email Address"
                            />
                        </div>
                        <div class="mb-3">
                            <!-- <label for="signUpPassword" class="form-label small"
                                >Password</label
                            > -->
                            <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="signUpPassword"
                                required
                                placeholder="Password"
                            />
                        </div>
                        <!-- <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <div class="single-line"></div>
                            <span class="small mx-2">or</span>
                            <div class="single-line"></div>
                        </div>
                        <div
                            class="mt-2 d-flex justify-between align-items-center"
                        >
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/google-logo.png"
                                    alt="recruiter logo"
                                />
                                <span class="small">Sign up with Google</span>
                            </a>
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/facebook-logo.png"
                                    alt="school logo"
                                />
                                <span class="small">Sign up with Facebook</span>
                            </a>
                        </div> -->
                        <div class="mt-3 text-center">
                            <h6 class="fw-normal d-inline-block me-2">
                                Have an account?
                            </h6>
                            <router-link
                                to="/students/login"
                                class="primaryColor text-decoration-none"
                                >Sign In</router-link
                            >
                        </div>

                        <button type="submit" class="btn primaryBtn mt-4 w-100">
                            Create Account
                        </button>

                        <div class="mt-4 px-4 text-center small">
                            By signing up, you agree to our Terms of use and
                            Privacy policy
                        </div>
                    </form>
                    <div class="mt-3 text-center">
                    <router-link
                        class="text-decoration-none btn"
                        :to="{ name: 'ScoutRegister' }"
                        >
                        <img
                            src="./../../assets/recruiter-logo.png"
                            alt="recruiter logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a Scout</span
                        >
                    </router-link>
                    <!--<a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/school-logo.png"
                            alt="school logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a school</span
                        >
                    </a>-->
                </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data: function () {
        return {
            loading: false,
            email: '',
            password: '',
            username: '',
            message: '',
        };
    },
    methods: {
        ...mapActions({
            callsignUp: 'user/register',
            updateModal: 'general/updateInfo',
        }),
        signUp: function () {
            // this.$store.dispatch('user/signIn')
            if (this.email && this.password && this.username) {
                var user = {
                    email: this.email,
                    password: this.password,
                    username: this.username,
                };

                this.callsignUp(user).then(
                    () => {
                        // this.updateModal({ type: true, message: 'account created successfully, please log in' })
                        this.$router.push('/students/signupsuccess');
                    },
                    (error) => {
                        this.message = error.message;

                        this.updateModal({
                            type: false,
                            message: error.message,
                        });
                    }
                );
            }
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.user.status.loggedIn;
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/students');
        }
    },
};
</script>

<style scoped>
.single-line {
    height: 1px;
    background-color: #d3c5c5a1;
    width: 43%;
}

.start-applying {
    color: #5a189a;
}
</style>
