import axios from 'axios'
import { authHeader } from '../authHeader'
import { API_URL_STUDENT } from '../../utils/constants'
import AuthService from './auth.service'
import Notiflix from 'notiflix'

class UserService {
  async postData (url, sendData, showLoader = true) {
    if(showLoader) this.showLoader()
    return axios.post(API_URL_STUDENT + url, sendData, { headers: authHeader() })
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  async postDataForm (url, sendData, showLoader = true) {
    if(showLoader) this.showLoader()
    const formData = new FormData()
    for (const key in sendData) {
      if (key !== 'uploadFiles') {
        formData.append(key, sendData[key])
      }
    }
    if (sendData.uploadFiles?.length > 0) {
      for (var i = 0; i < sendData.uploadFiles.length; i++) {
        formData.append('uploadFiles', sendData.uploadFiles[i])
      }
    }
    const head = { headers: authHeader() }
    head.headers = { ...head.headers, ...{ 'Content-Type': 'multipart/form-data' } }
    return axios.post(API_URL_STUDENT + url, formData, head)
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  async putData (url, sendData, showLoader = true) {
    if(showLoader) this.showLoader()
    return axios.put(API_URL_STUDENT + url, sendData, { headers: authHeader() })
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  async putDataForm (url, sendData, showLoader = true) {
    if(showLoader) this.showLoader()
    const formData = new FormData()
    for (const key in sendData) {
      if (key !== 'uploadFiles') {
        formData.append(key, sendData[key])
      }
    }
    if (sendData.uploadFiles?.length > 0) {
      for (var i = 0; i < sendData.uploadFiles.length; i++) {
        formData.append('uploadFiles', sendData.uploadFiles[i])
      }
    }
    const head = { headers: authHeader() }
    head.headers = { ...head.headers, ...{ 'Content-Type': 'multipart/form-data' } }
    return axios.put(API_URL_STUDENT + url, formData, head)
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  async getData (url, showLoader = true) {
    if(showLoader) this.showLoader()
    return axios.get(API_URL_STUDENT + url, { headers: authHeader() })
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  async deleteData (url, showLoader = true) {
    if(showLoader) this.showLoader()
    return axios.delete(API_URL_STUDENT + url, { headers: authHeader() })
      .then(this.handleResponse)
      .then(response => {
        return response.data
      }).catch(this.handleResponseError)
  }

  handleResponseError (err) {
    new UserService().hideLoader()
    if (err.response?.status === 403 || err.response?.data?.status === 403) {
      AuthService.logout()
    }
    if (err && err.response && err.response.status == 403) {
      return Promise.reject("");
    }
    let message = "An error has occurred";
    if (err && err.response && err.response.data && err.response.data.message) {
      message = err.response.data.message;
    }
    Notiflix.Report.failure("Schooliply", "<div>" + message + "</div>", "Okay!");
    return Promise.reject(err.response.data.message)
  }

  handleResponse (response) {
    new UserService().hideLoader()
    if (response.status === 401) {
      AuthService.logout()

      const error = response.data && response.data.message
      return Promise.reject(error)
    }

    return Promise.resolve(response)
  }

  showLoader (){
    document.getElementById('pageLoader').classList.remove("d-none")
    Notiflix.Block.standard('#pageLoader')
  }

  hideLoader (){
    Notiflix.Block.remove('#pageLoader')
    document.getElementById('pageLoader').classList.add("d-none")
  }
}

export default new UserService()
