<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
        <page-header header="Manage Blogs" subText="Manage all blogs." />
        <div class="mb-3 col-12 col-md-10">
            <h6>
                <router-link
                    class="text-decoration-none primaryColor"
                    :to="{ name: 'EditBlog', params: { id: '0' } }"
                    ><i class="bi bi-plus"></i> Add Blog</router-link
                >
            </h6>
            <div class="menu">
                <button
                    type="submit"
                    class="btn primaryBtn menuBtn"
                    @click="changeEditMode"
                >
                    Toggle blog visibility
                </button>
                <div>
                    <!-- <label for="show_to_public"
                        >Show public / private / all blogs
                        
                    </label> -->
                    <select
                        v-model="setBlogsBy"
                        @change="setFilteredBlogs"
                        class="form-select ms-0 mt-2 mt-md-0"
                        role="button"
                        name="show_to_public"
                        style="width: auto"
                    >
                        <option value="public">Public</option>
                        <option value="private">Private</option>
                        <option value="all">All blogs</option>
                    </select>
                </div>
                <div class="btnWrapper">
                    <button
                        type="submit"
                        class="btn menuBtn"
                        :class="blogOrderState"
                        @click="saveBlogOrder"
                    >
                        Save Blog Order
                    </button>
                </div>
            </div>
            <p class="text-danger pt-3" v-if="!!blogOrderErrMessage">
                {{ blogOrderErrMessage }}
            </p>
        </div>
        <div v-if="blogs.length == 0" class="text-center my-2">
            <img src="assets/images/no-application.png" width="150" />
            <div class="mt-4 text-gray">There is no blog added yet</div>
        </div>
        <div v-if="blogs.length > 0" class="col-12 col-md-10 tableParent">
            <!-- <div class="col-12 col-md-4 offset-md-4 mt-2">
                <input
                    type="text"
                    placeholder="search"
                    class="form-control rounded-pill mb-3"
                    v-model="searchvalue"
                />
            </div> -->

            <table class="table table-borderless table-striped table-light">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th></th>
                        <th v-if="editVisibilityMode">Blog visibility mode</th>
                    </tr>
                </thead>

                <!-- <tr v-for="blog in blogs" :key="blog._id"> -->
                <draggable
                    v-model="filteredBlogs"
                    tag="tbody"
                    item-key="_id"
                    @change="onBlogListChange"
                >
                    <template #item="{ element }">
                        <tr class="draggable-item">
                            <td>{{ element.title }}</td>
                            <td>
                                <router-link
                                    class="text-decoration-none primaryColor fw-bold me-3"
                                    :to="{
                                        name: 'EditBlog',
                                        params: { id: element._id },
                                    }"
                                    ><i class="bi bi-pencil-square me-2"></i
                                    >Edit</router-link
                                >
                            </td>
                            <td v-if="editVisibilityMode">
                                <input
                                    type="checkbox"
                                    name="bulkSelectArr"
                                    :value="element._id"
                                    v-model="bulkSelectArr"
                                />
                            </td>
                        </tr>
                    </template>
                </draggable>
            </table>

            <!-- <EasyDataTable
                :headers="headers"
                :items="blogs"
                :search-value="searchvalue"
                header-class-name="datatable-header"
                alternating
            >
                <template #header-visibility_mode>
                    {{ editVisibilityMode ? 'Blog Visibility Mode' : '' }}
                </template>

                <template #item-operation="bgs">
                    <router-link
                        class="text-decoration-none primaryColor fw-bold"
                        :to="{ name: 'EditBlog', params: { id: bgs._id } }"
                        ><i class="bi bi-pencil-square me-2"></i
                        >Edit</router-link
                    >
                </template>

                <template #item-visibility_mode="bgs" v-if="editVisibilityMode">
                    <input
                        type="checkbox"
                        name="bulkSelectArr"
                        :value="bgs._id"
                        v-model="bulkSelectArr"
                    />
                </template>
            </EasyDataTable> -->

            <div class="d-flex justify-content-end" v-if="editVisibilityMode">
                <div class="me-2">
                    <select
                        v-model="bulkVisibility"
                        class="form-select ms-0 ms-md-2 mt-2 mt-md-0 py-2"
                        name="show_to_public"
                        style="width: auto"
                    >
                        <option :value="true">Visible</option>
                        <option :value="false">Not Visible</option>
                    </select>
                </div>
                <div>
                    <button
                        type="submit"
                        class="btn primaryBtn px-5 py-2"
                        @click="saveVisibilityMode"
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Notify } from 'notiflix';
import PageHeader from '@/components/PageHeader.vue';
import { mapActions } from 'vuex';
import AdminService from '@/apiServices/admin/admin.service';
const countries = require('i18n-iso-countries');
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
import draggable from 'vuedraggable';
export default {
    name: 'ManageBlogs',
    components: {
        PageHeader,
        draggable,
    },
    data: function () {
        return {
            blogs: [],
            filteredBlogs: [],
            editVisibilityMode: false,
            bulkVisibility: true,
            bulkSelectArr: [],
            searchvalue: '',
            headers: [
                { text: 'Title', value: 'title', sortable: true },
                { text: '', value: 'operation' },
                { text: 'Blog visibility mode', value: 'visibility_mode' },
                // { text: 'Created At', value: 'createdAt' },
            ],
            blogOrderChangeMode: false,
            setBlogsBy: 'all',
            blogOrderErrMessage: null,
        };
    },
    computed: {
        blogOrderState() {
            if (this.blogOrderChangeMode && this.setBlogsBy === 'public') {
                return 'primaryBtn';
            } else {
                return 'secondaryBtn';
            }
            // return this.blogOrderChangeMode === true
            //     ? 'primaryBtn'
            //     : 'secondaryBtn';
        },
        publicBlogs() {
            return this.blogs.filter((blog) => {
                return blog.show_to_public === true;
            });
        },
        privateBlogs() {
            return this.blogs.filter((blog) => {
                return blog.show_to_public === false;
            });
        },
        allBlogs() {
            return this.blogs;
        },
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        onBlogListChange() {
            if (this.setBlogsBy !== 'public') {
                this.blogOrderErrMessage =
                    'Please set the order to "public" in order to alter the order of the blogs';
                return;
            }
            this.blogOrderChangeMode = true;
            // console.log(evt);
        },
        saveBlogOrder() {
            this.filteredBlogs.forEach((blog, index) => {
                blog.order_index = index + 1;
            });
            console.log(this.filteredBlogs);
            const finalOrderArr = [];
            this.filteredBlogs.forEach((blog) => {
                finalOrderArr.push({
                    id: blog._id,
                    order_index: blog.order_index,
                });
            });
            console.log(finalOrderArr);

            this.blogOrderChangeMode = false;
        },
        setFilteredBlogs() {
            let finalArr = [];
            if (this.setBlogsBy === 'all') {
                finalArr = this.blogs;
            } else if (this.setBlogsBy === 'public') {
                // reset blogOrderErrMessage back to null
                this.blogOrderErrMessage = null;
                finalArr = this.publicBlogs;
            } else if (this.setBlogsBy === 'private') {
                finalArr = this.privateBlogs;
            }

            this.filteredBlogs = finalArr;
        },
        changeEditMode() {
            this.editVisibilityMode = !this.editVisibilityMode;
            this.bulkSelectArr = [];
        },
        saveVisibilityMode() {
            if (this.bulkSelectArr.length <= 1) {
                Notify.failure('You have not selected any blog!');
                return;
            }
            AdminService.postData('set_blog_visibility', {
                ids: this.bulkSelectArr,
                status: this.bulkVisibility,
            }).then((resp) => {
                if (resp && resp.data) {
                    // console.log(resp.data);
                    this.updateModal({
                        type: true,
                        message: 'Blog visibility saved',
                    });
                }
                this.editVisibilityMode = false;
            });
        },
        getBlogs() {
            AdminService.getData('get_blogs').then((resp) => {
                if (resp && resp.data) {
                    resp.data.sort((a, b) => {
                        return a.order_index - b.order_index;
                    });
                    this.blogs = resp.data;
                    this.setFilteredBlogs();

                    // console.log('blogs ', resp.data);
                }
            });
        },
    },
    mounted() {
        this.getBlogs();
    },
};
</script>
<style scoped>
.draggable-item {
    cursor: move;
}

.menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #e9ecef;
}

.menuBtn {
    font-size: 0.9rem;
}

.secondaryBtn {
    background-color: #ced4da;
    pointer-events: none;
}

.btnWrapper {
    cursor: not-allowed !important;
}
</style>
