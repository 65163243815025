<template>
    <div class="container mt-3">
        <div>
            <router-link to="/">
                <img
                    src="/assets/images/Schooliply-Logo-Dark.png"
                    width="150"
                    class="imageNegMargin"
                />
            </router-link>
        </div>
        <div class="row d-flex justify-content-between align-items-center">
            <div class="col-12 col-md-6 d-flex flex-column align-items-start">
                <img
                    src="./../../assets/scouts-login.png"
                    alt="Scout's login"
                    class="w-100 max-400"
                />

                <!-- <div class="mt-2 mb-4 d-flex justify-between align-center">
                    <a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/recruiter-logo.png"
                            alt="recruiter logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a recruiter</span
                        >
                    </a>
                    <a href="#" class="btn d-flex align-items-center">
                        <img
                            src="./../../assets/school-logo.png"
                            alt="school logo"
                        />
                        <span class="small fw-bold ms-2"
                            >Register as a school</span
                        >
                    </a>
                </div> -->
            </div>

            <div class="col-12 col-md-5 mt-4 offset-md-1 align-self-start">
                <div class="pb-4 px-3 mb-2 text-center">
                    <h2 class="pt-4 pt-md-0 fw-bold text-center start-applying">
                        Hello Scouts
                    </h2>
                    <div class="text-secondary medium pb-2">
                        Sign into your accout
                    </div>
                    <form @submit.prevent="signIn">
                        <!-- <div class="mb-3 pt-4">
                            <label for="signUpUsername" class="form-label small"
                                >Username</label
                            >
                            <input
                                type="text"
                                v-model="username"
                                class="form-control"
                                id="signUpUsername"
                                required
                                placeholder="UserName"
                            />
                        </div> -->
                        <div class="mb-3">
                            <!-- <label for="loginEmail" class="form-label small"
                                >Email address</label
                            > -->
                            <input
                                type="email"
                                v-model="email"
                                class="form-control"
                                id="loginEmail"
                                aria-describedby="emailHelp"
                                required
                                placeholder="Email Address"
                            />
                        </div>
                        <div class="mb-3">
                            <!-- <label for="signUpPassword" class="form-label small"
                                >Password</label
                            > -->
                            <input
                                type="password"
                                v-model="password"
                                class="form-control"
                                id="signUpPassword"
                                required
                                placeholder="Password"
                            />
                        </div>
                        <!-- <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <div class="single-line"></div>
                            <span class="small mx-2">or</span>
                            <div class="single-line"></div>
                        </div>
                        <div
                            class="mt-2 d-flex justify-between align-items-center"
                        >
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/google-logo.png"
                                    alt="recruiter logo"
                                />
                                <span class="small">Sign up with Google</span>
                            </a>
                            <a href="#" class="btn d-flex align-items-center">
                                <img
                                    src="./../../assets/facebook-logo.png"
                                    alt="school logo"
                                />
                                <span class="small">Sign up with Facebook</span>
                            </a>
                        </div> -->

                        <button type="submit" class="btn primaryBtn mt-4 w-100">
                            Sign In
                        </button>
                        <div class="mt-3 text-center">
                            <span>Forgot your password? </span
                            ><router-link
                                :to="{ name: 'ScoutForgotPassword' }"
                                class="primaryColor text-decoration-none"
                                >Reset password</router-link
                            >
                        </div>

                        <div class="mt-4 px-4 text-center small">
                            By signing up, you agree to our Terms of use and
                            Privacy policy
                        </div>
                    </form>
                    <div class="d-block text-center">
                        <h6 class="mt-4 fw-bold">Want to be a Scout?</h6>
                        <div>
                            <router-link
                                class="text-decoration-none primaryColor"
                                :to="{ name: 'ScoutRegister' }"
                                >Register Here</router-link
                            >
                        </div>
                    </div>
                    <div class="mt-3 text-center">
                        <router-link
                            :to="{ name: 'StudentLogin' }"
                            class="primaryColor text-decoration-none"
                            >Sign in as a Student</router-link
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data: function () {
        return {
            loading: false,
            email: '',
            password: '',
            message: '',
        };
    },
    methods: {
        ...mapActions({
            callSignIn: 'scout/login',
            updateModal: 'general/updateInfo',
        }),
        signIn: function () {
            // this.$store.dispatch('user/signIn')
            if (this.email && this.password) {
                var user = {
                    email: this.email,
                    password: this.password,
                };

                this.callSignIn(user).then(() => {
                    /*if(!resp.data.uuser.terms_conditions_accepted){
                        this.$router.push({name: "ScoutsTermsAndConditions"});
                        return;
                    }*/
                    if (this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        this.$router.push('/scout/');
                    }
                });
            }
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.scout.status.loggedIn;
        },
    },
    mounted() {
        if (this.loggedIn) {
            this.$router.push('/scout');
        }
    },
};
</script>
<style scoped>
.start-applying {
    color: #5a189a;
}
</style>
