<template>
  <div class="container pt-5 px-3 px-md-5">
    <page-header v-bind:header="'Hi ' + currentUser?.uuser.first_name + '👋,'" subText="Here’s an overview of your activities on schooliply."></page-header>
    <div class="row flex-column-reverse flex-md-row">
      <div class="col-12 col-md-8">
          <div class="row">
              <div class="col-6 col-md-4">
                <div class="smaller fw-bold text-muted">TOTAL APPLICATIONS</div>
                <div class="fw-bold">{{totalApp}}</div>
              </div>
          </div>
          <div class="row mt-4 mt-md-5">
              <div class="col-12 col-md-4 d-flexs">
                <div class="row">
                  <div class="col-6 col-md-6">
                    <div class="smaller fw-bold text-muted">Submitted</div>
                    <div class="fw-bold">{{totalSubmittedApp}}</div>
                  </div>
                  <div class="col-6 col-md-6 mt-0 mt-md-0">
                    <div class="smaller fw-bold text-muted">Not Submitted</div>
                    <div class="fw-bold">{{totalUnsubmittedApp}}</div>
                  </div>
                </div>
              </div>
          </div>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-md-0">
        <h5>Quick links</h5>
        <ul>
            <li><a href="https://schooliply.com/faqs" target="_blank" class="btn btn-link primaryColor text-decoration-none">FAQs</a></li>
            <li><a href="https://schooliply.com/blog" target="_blank" class="btn btn-link primaryColor text-decoration-none">Blog</a></li>
            <li><a href="https://schooliply.com" target="_blank" class="btn btn-link primaryColor text-decoration-none">Visit Schooliply</a></li>
        </ul>
      </div>
    </div>

    <div class="row mb-4">
      <div v-if="apps.length == 0" class="mt-3">
            <div>You have no application created yet</div>
          </div>
          <div v-if="apps.length > 0" class="col-12 col-md-12 mt-3 tableParent">
            <!--<table class="table table-borderless table-striped table-light">
              <thead>
                <tr>
                  <th>School</th>
                  <th>Program</th>
                  <th>Application ID</th>
                  <th>Status</th>
                  <th>Date Applied</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="app in apps" :key="app._id">
                    <td>{{app.university.name}}</td>
                    <td>{{app.program.name}}</td>
                    <td>{{app._id}}</td>
                    <td>{{app.status}}</td>
                    <td>{{formatDateLocal(app.created_on)}}</td>
                    <td><h6><router-link class="text-decoration-none primaryColor" :to="{name: 'StudentApplyForm', params: { id: app._id, school_id: app.university._id, program_id: app.program._id}}">View Application</router-link></h6></td>
                </tr>
              </tbody>
            </table>-->

            <div class="col-12 col-md-4 offset-md-4 mt-2">
              <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
            </div>
            
              <EasyDataTable
                :headers="headers"
                :items="appsDataTable"
                :search-value="searchvalue"
                header-class-name="datatable-header"
                alternating
              >
              <template #item-date_applied="appD1">
                {{formatDateLocal(appD1.date_applied)}}
              </template>
              <template #item-operation="appD1">
                <h6><router-link class="text-decoration-none primaryColor" :to="{name: 'ScoutStudentApplyForm', params: { studentId: appD1.full_app.user_id?._id, id: appD1.full_app._id, school_id: appD1.full_app.university._id, program_id: appD1.full_app.program._id}}">View Application</router-link></h6>
              </template>
              <template #item-tracker="appD">
                    <div class="text-center text-danger mb-2">
                        {{ calcTracker(appD.full_app) }}
                    </div>
                    <div class="stepProgressBar">
                    <div
                        v-for="(trk, index) in tracks"
                        :key="trk.progress"
                        class="step d-flex flex-column"
                        :class="{ last: tracks?.length == index + 1 }"
                    >
                        <div class="step-text text-start">{{ trk.status }}</div>
                        <div
                            class="bullet"
                            :class="{
                                completed: tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1,
                                last: tracks?.length == index + 1,
                            }"
                        >
                            <i class="bi bi-check-lg" v-if="tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1"></i>
                        </div>
                        <div v-if="tracks.find((x) => x.status === calcTracker(appD.full_app))?.progress >= index + 1" class="middle-line"></div>
                    </div>
                </div>
                </template>
              </EasyDataTable>
          </div>
    </div>
  </div>
</template>
<script>
// import AppTracker from '@/components/common/Tracker.vue'
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import ScoutService from '@/apiServices/scout/scout.service'
import { formatDate } from '@/utils/format'
import { calcTracker } from '@/utils/constants';
import { applicationTracker } from '@/utils/constants';
export default {
  name: 'ScoutIndex',
  components: {
    PageHeader,
    // AppTracker
  },
  data: function () {
    return {
      tracks: [],
      authenticated: true,
      apps: [],
      totalApp: 0,
      totalSubmittedApp: 0,
      totalUnsubmittedApp: 0,
      totalCompleteApp: 0,
      totalIncompleteApp: 0,
      totalPayments: 0,
      totalCompletePayments: 0,
      totalIncompletePayments: 0,
      appsDataTable: [],
      searchvalue: "",
      headers: [
        { text: "Student Name", value: "student_name", sortable: true },
        { text: "School", value: "school_name", sortable: true },
        { text: "Program", value: "program_name", sortable: true },
        { text: "Application ID", value: "application_id", sortable: true },
        { text: "Status", value: "status", sortable: true },
        { text: "Date Applied", value: "date_applied", sortable: true },
        { text: "", value: "operation" },
        {text:"Application Tracker", value: "tracker"}
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getApps () {
      
      ScoutService.getData('get_scout_applications').then((resp) => {
        
        if (resp && resp.data) {
          this.apps = resp.data
          this.totalApp = this.apps.length
          this.totalSubmittedApp = this.apps.filter(x => x.is_submitted && (x.payment && x.payment.amount_paid == x.payment.amount_to_pay)).length
          this.totalUnsubmittedApp = this.apps.filter(x => !x.is_submitted || (x.payment && x.payment.amount_paid != x.payment.amount_to_pay)).length
          this.appsDataTable = this.apps.map(x => ({
            student_name: `${x.user_id?.personal_info?.first_name} ${x.user_id?.personal_info?.last_name}`,
            school_name: x.university.name,
            program_name: x.program.name, 
            application_id: x._id,
            status: x.status,
            date_applied: x.created_on,
            full_app: x
          }))
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    },
    calcTracker (app) {
      return calcTracker(app)
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.scout.currentUser
    }
  },
  mounted () {
    this.tracks = applicationTracker;
    if (this.currentUser == null) {
      this.$router.push('/scout/login')
      return
    }
    this.getApps();
  }
}
</script>
