<template>
  <div v-if="docs != null">
    <document-list :documentPass="docs" :canEdit="false"></document-list>
  </div>
</template>
<script>
import DocumentList from '@/components/common/DocumentList.vue'
import { Colors } from '@/utils/constants'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
export default {
  name: 'StudentDocumentList',
  components: {
    DocumentList
  },
  data: function () {
    return {
      authenticated: true,
      currentTab: 0,
      docs: null
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getStudentProfile () {
      
      AdminService.getData('get_user_docs/' + this.$route.params.id).then((resp) => {
        
        if (resp && resp.data) {
          this.docs = resp.data?.allDocs
        }
      })
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary-color': Colors.primaryColor,
        'overflow-x': 'auto'
      }
    },
    currentUser () {
      return this.$store.state.admin.currentUser
    }
  },
  mounted () {
    if (this.currentUser == null) {
      this.$router.push('/admin/login')
      return
    }
    this.getStudentProfile()
  }
}
</script>
<style scoped>
  .tabButtons a{
    text-decoration: none;
    margin-right: 50px;
    font-weight: 500;
    color: gray;
  }

  .tabButtons a.router-link-exact-active{
    color: var(--primary-color);
  }
</style>
