<template>
    <div class="container pt-5 px-3 px-md-5">
        <page-header header="Terms and Conditions" subText="Please read carefully"></page-header>

        <div>
            <iframe :src="doc_link + '?embedded=true'" style="width:100%;height:90vh;"></iframe>
        </div>

        <div class="mt-4 text-center mb-4">
            <div class="d-flex justify-content-center">
                <input
                    type="checkbox"
                    name="terms-and-conditions"
                    id="terms-and-conditions"
                    class="me-2"
                    v-model="iagree"
                    role="button"
                />
                <label for="terms-and-conditions" role="button" class="fw-bold">I have read and I agree to the terms and conditions</label>
            </div>

            <button class="btn primaryBtn px-5 mt-3" :disabled="!iagree" @click="signDoc">Agree to terms and conditions</button>
        </div>
    </div>



</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import ScoutService from '@/apiServices/scout/scout.service'
import Notiflix from 'notiflix'
import { mapActions } from 'vuex'
export default {
    name: 'ScoutTermsAndConditions',
    data: function () {
    return {
      doc_link: "https://docs.google.com/document/d/e/2PACX-1vQxisTORzy8Q2RvHrfxDpe8g4jsEiQmWjoRbN4pSQZSqgz33otfvs4vfTnaoTD8Yw/pub",
      iagree: false
    }
  },
    components: {
        PageHeader
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo',
      acceptTerms: 'scout/acceptTerms'
    }),
    signDoc () {
        ScoutService.postData('accept-terms', {doc_link: this.doc_link}).then((resp) => {
        if (resp && resp.data) {
          this.updateModal({ type: true, message: 'Terms and Conditons successfully signed' });
          this.acceptTerms();
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
            return;
        }
          this.$router.push({name: "ScoutIndex"});
        }
      })
    }
  },
  mounted() {
    Notiflix.Notify.info("Please read carefully and scroll to the bottom to agree", {timeout: 15000, position: "center-top"});
  }
};
</script>
