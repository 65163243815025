<template>
 <div>
  <router-view v-slot="{ Component, route }">
              <transition name="fade-in-up" appear>
                <div :key="route.name">  
                  <component :is="Component"></component>
                </div>
              </transition>
            </router-view>
 </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'GuestHome'
}
</script>

