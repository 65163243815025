import AdminHome from '../views/admin/Home.vue';
import AdminIndex from '../views/admin/Index.vue';
import AdminListAdmins from '../views/admin/Admins.vue';
import SaveAdmin from '../views/admin/SaveAdmin.vue';
import AdminPage from '../views/admin/AdminPage.vue';
import AdminProfile from '../views/admin/Profile.vue';
import AdminApplications from '../views/admin/Applications.vue';
import AssignedApplications from '../views/admin/AssignedApplications.vue';
import ViewApplication from '../views/admin/ViewApplication.vue';
import AdminNotification from '../views/admin/Notifications.vue';
import ManageSchools from '../views/admin/ManageSchools.vue';
import InstitutionPage from '../views/admin/InstitutionPage.vue';
import ManagePrograms from '../views/admin/ManagePrograms.vue';
import EditSchool from '../views/admin/EditSchool.vue';
import EditProgram from '../views/admin/EditProgram.vue';
import AdminLogin from '../views/admin/LoginPage.vue';
import AdminFindProgram from '../views/FindProgram.vue';
import SchoolList from '@/components/Schools.vue';
import ProgramList from '@/components/Programs.vue';
import AdminSchool from '../views/School.vue';
import AdminProgram from '../views/Program.vue';
import StudentProfile from '../views/admin/StudentProfile.vue';
import StudentPersonalInfo from '@/views/admin/StudentPersonalInfo.vue';
import StudentEducationHistory from '@/views/admin/StudentEducationHistory.vue';
import StudentExamScoresHistory from '@/views/admin/StudentExamScoresHistory.vue';
import StudentBackgroundInfoHistory from '@/views/admin/StudentBackgroundInfoHistory.vue';
import StudentDocumentList from '@/views/admin/StudentDocumentList.vue';
import BlogsPage from '@/views/admin/BlogsPage.vue';
import ManageBlogs from '@/views/admin/ManageBlogs.vue';
import EditBlog from '@/views/admin/EditBlog.vue';
import ScoutApplicationList from '../views/admin/ScoutApplications.vue';
import ViewScoutApplication from '../views/admin/ViewScoutApplication.vue';
import AdminViewApplyForm from '@/views/admin/ApplyPage.vue';

var adminRoute = {
    path: '/admin/',
    name: 'AdminHome',
    component: AdminHome,
    children: [
        {
            name: 'AdminLogin',
            path: 'login',
            component: AdminLogin,
        },
        {
            path: '',
            redirect: { name: 'AdminIndex' },
        },
        {
            path: 'dashboard',
            name: 'AdminIndex',
            component: AdminIndex,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'profile',
            name: 'AdminProfile',
            component: AdminProfile,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'notifications',
            name: 'AdminNotification',
            component: AdminNotification,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'applications',
            name: 'AdminApplications',
            component: AdminApplications,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'assignedapplications',
            name: 'AssignedApplications',
            component: AssignedApplications,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'viewapplication/:id/user/:user_id',
            name: 'ViewAnApplication',
            component: ViewApplication,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'scoutapplications',
            name: 'ScoutApplicationList',
            component: ScoutApplicationList,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'scoutapplication/:id',
            name: 'ViewScoutApplication',
            component: ViewScoutApplication,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'admins',
            name: 'AdminPage',
            component: AdminPage,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'AdminList' },
                },
                {
                    path: 'home',
                    name: 'AdminList',
                    component: AdminListAdmins,
                },
                {
                    path: 'save/:id',
                    name: 'SaveAdmins',
                    component: SaveAdmin,
                },
            ],
        },
        {
            path: 'find-programs/',
            component: AdminFindProgram,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'AdminSchoolList' },
                },
                {
                    name: 'AdminSchoolList',
                    path: 'schools',
                    component: SchoolList,
                },
                {
                    name: 'AdminProgramList',
                    path: 'programs',
                    component: ProgramList,
                },
            ],
        },
        {
            path: 'school/:id',
            name: 'AdminSchool',
            component: AdminSchool,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'school/:school_id/program/:id',
            name: 'AdminProgram',
            component: AdminProgram,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'school/:school_id/program/:program_id/apply/:id',
            name: 'AdminViewApplyForm',
            component: AdminViewApplyForm,
            meta: {
              requiresAuth: true
            },
          },
        {
            path: 'blogs',
            name: 'BlogsPage',
            component: BlogsPage,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'ManageBlogs' },
                },
                {
                    path: 'manage-blogs',
                    name: 'ManageBlogs',
                    component: ManageBlogs,
                },
                {
                    path: 'edit-blog/:id',
                    name: 'EditBlog',
                    component: EditBlog,
                },
            ],
        },
        {
            path: 'institutions',
            name: 'AdminInstitutions',
            component: InstitutionPage,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'ManageSchools' },
                },
                {
                    path: 'manage-school',
                    name: 'ManageSchools',
                    component: ManageSchools,
                },
                {
                    path: 'manage-programs/:school_id',
                    name: 'ManagePrograms',
                    component: ManagePrograms,
                },
                {
                    path: 'edit-school/:id',
                    name: 'EditSchool',
                    component: EditSchool,
                },
                {
                    path: 'edit-program/:school_id/:program_id',
                    name: 'EditProgram',
                    component: EditProgram,
                },
            ],
        },
        {
            path: 'studentprofile/:id/',
            name: 'StudentProfile',
            component: StudentProfile,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'StudentPersonalInfo' },
                },
                {
                    name: 'StudentPersonalInfo',
                    path: 'personal-info',
                    component: StudentPersonalInfo,
                },
                {
                    name: 'StudentEducationHistory',
                    path: 'education-history',
                    component: StudentEducationHistory,
                },
                {
                    name: 'StudentExamScoresHistory',
                    path: 'exam-scores-history',
                    component: StudentExamScoresHistory,
                },
                {
                    name: 'StudentBackgroundInfoHistory',
                    path: 'background-info-history',
                    component: StudentBackgroundInfoHistory,
                },
                {
                    name: 'StudentDocumentList',
                    path: 'document-list',
                    component: StudentDocumentList,
                },
            ],
        },
    ],
};

export default adminRoute;
