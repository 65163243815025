<template>
    <div
        class="py-1 px-3 px-md-5 primaryBG d-flex justify-content-between align-items-center"
        v-if="currentUser == null"
    >
        <router-link to="/"
            ><img src="assets/images/schooliply_header_2.png" width="150"
        /></router-link>
        <h5>
            <router-link to="/students/login" class="text-white fw-bold"
                >Log in</router-link
            >
        </h5>
    </div>
    <div v-if="school == null && !isLoading">No school found</div>
    <div v-if="school != null">
        <img src="assets/images/Best-Universities-In-The-World-edited.jpg" class="imageHeader" />
        <div class="d-flex">
            <div
                class="rounded-circle circleName"
                :style="school.logo == null ? 'background-image:url(' + 'assets/images/school_logo_only.png' + ')' : 'background-image:url(' + school.logo + ')'"
            ></div>
            <div class="ms-2 ms-md-5 mt-1 mt-md-3">
                <h5 class="mb-1">{{ school.name }}</h5>
                <div class="fw-bold smaller">
                    <span class="me-2">LOCATED AT</span>
                    <span class="text-muted text-uppercase">{{
                        school.address
                    }}</span>
                </div>
            </div>
        </div>

        <div class="container py-3 px-3 py-md-5 px-md-5">
            <div class="pb-4 goBack">
                <a
                    class="text-decoration-none primaryColor"
                    @click="$router.go(-1)"
                    ><i class="bi bi-arrow-left"></i> Go Back</a
                >
            </div>
            <div
                class="d-flex py-2 py-md-0 my-4 tabButtons"
                :style="cssProps"
                id="myTab"
            >
                <a
                    v-if="school.about"
                    href="#"
                    :class="{ active: currentTab === 'about' }"
                    @click.prevent="switchTabs('about')"
                    >About</a
                >
                <a
                    v-if="school.features"
                    href="#"
                    :class="{ active: currentTab === 'features' }"
                    @click.prevent="switchTabs('features')"
                    >Features</a
                >
                <a
                    href="#"
                    :class="{ active: currentTab === 'tuition' }"
                    @click.prevent="switchTabs('tuition')"
                    >Tuition</a
                >
                <a
                    v-if="school.programs"
                    href="#"
                    :class="{ active: currentTab === 'programs' }"
                    @click.prevent="switchTabs('programs')"
                    >Available Programs</a
                >
            </div>

            <div id="myTabContent">
                <div
                    v-if="school.about"
                    class="row mytab text-secondary mt-5"
                    :class="{ active: currentTab === 'about' }"
                >
                    <div class="col-12 col-md-9">
                        <div v-html="school.about"></div>
                    </div>
                </div>
                <div
                    v-if="school.features"
                    class="mytab mt-5"
                    :class="{ active: currentTab === 'features' }"
                >
                    <div class="row">
                        <div
                            v-for="ft in school.features"
                            :key="ft.header"
                            class="col-12 col-md-4 mb-3 mb-md-5"
                        >
                            <div class="mb-0">
                                <i class="bi bi-card-list fs-2"></i>
                            </div>
                            <div class="fw-bold mb-1">{{ ft.header }}</div>
                            <div class="small text-secondary">
                                {{ ft.body }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="mytab mt-5"
                    :class="{ active: currentTab === 'tuition' }"
                >
                    <div class="row mb-3">
                        <div class="fw-bold col-12 col-md-3">
                            Avg. Cost of Tuition
                        </div>
                        <div class="col-12 col-md-6">
                            {{ school.tuition_range }}
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="fw-bold col-12 col-md-3">
                            Application fee
                        </div>
                        <div class="col-12 col-md-6">
                            {{ school.currency_symbol
                            }}{{ toCurrency(school.application_fee) }}
                        </div>
                    </div>
                </div>
                <div
                    v-if="school.programs"
                    class="mytab mt-5"
                    :class="{ active: currentTab === 'programs' }"
                >
                <div class="row mb-5 d-flex align-items-center">
                    <div class="col-11 col-md-9 pe-md-4">
                        <input class="form-control" v-model="searchProgram.name" placeholder="Search for programs"/>
                        <div class="col-12 col-md-6">
                            <select
                                v-model="searchProgram.level"
                                class="form-select mt-2"
                            >
                                <option value="">Level</option>
                                <!-- <option value="undergraduate">Undergraduate</option>
                                <option value="postgraduate">Postgraduate</option> -->
                                <option
                                    v-for="(prg, index) in program_list"
                                    :key="index"
                                    :value="prg.id"
                                >
                                    {{ prg.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-11 col-md-3 mt-3 mt-md-0">
                        <div class="d-flex flex-md-column">
                            <div><button type="button" class="btn primaryBtn" @click="searchForPrograms">Search</button></div>
                            <div class="ms-3 ms-md-0 mt-2" v-if="searchProgram.name != '' || searchProgram.level != ''">
                                <a href="#" class="text-decoration-none text-danger" @click.prevent="clearSearchPrograms">Clear</a>
                            </div>
                        </div>
                    </div>
                </div>
                    <div class="row">
                        <div
                            v-for="prg in searchResult"
                            :key="prg._id"
                            class="d-flex mb-4 flex-column col-12 col-md-6 myCard"
                        >
                            <div class="card h-100">
                                <div class="card-body d-flex flex-column mt-2">
                                    <div>
                                        <div class="boldText">
                                            <router-link
                                                class="text-decoration-none primaryColor"
                                                :to="{
                                                    name: 'ProgramPage',
                                                    params: {
                                                        school_id: school._id,
                                                        id: prg._id,
                                                    },
                                                    query: {name: prg.name + ' - ' + school.name}
                                                }"
                                                >{{ prg.name }}</router-link
                                            >
                                        </div>
                                    </div>
                                    <div class="row mt-3 small">
                                        <div class="col-6 col-md-3 mb-3 mb-md-0">
                                            <div class="title muted">
                                                Application Fee
                                            </div>
                                            <div>
                                                {{ school.currency_symbol
                                                }}{{
                                                    toCurrency(markUpApplicationFee(prg.application_fee))
                                                }}
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3 mb-3 mb-md-0">
                                            <div class="title muted">
                                                Tuition Fee
                                            </div>
                                            <div>
                                                {{ school.currency_symbol
                                                }}{{ toCurrency(prg.tuition_fee) }}
                                            </div>
                                        </div>
                                        <div class="col-6 col-md-3 mb-3 mb-md-0">
                                            <div class="title muted">
                                                Program Type
                                            </div>
                                            <div>{{ prg.program_type }}</div>
                                        </div>
                                        <div v-if="prg.duration_in_months" class="col-6 col-md-3 mb-3 mb-md-0">
                                            <div class="title muted">
                                                Program Duration
                                            </div>
                                            <div>{{ formatProgramDuration(prg.duration_in_months) }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="card-footer d-flex mt-3 py-0"
                                >
                                        <div class="flex-grow-1 text-center py-2 border-right-button">
                                            <h6 class="mb-0">
                                                <router-link
                                                    class="text-decoration-none primaryColor me-2"
                                                    :to="{
                                                        name: 'ProgramPage',
                                                        params: {
                                                            school_id: school._id,
                                                            id: prg._id,
                                                        },
                                                    }"
                                                    >View</router-link
                                                >
                                            </h6>
                                            </div>
                                            <div class="flex-grow-1 text-center py-2">
                                                <h6 class="mb-0">
                                                    <router-link
                                                        class="text-decoration-none primaryColor"
                                                        :to="{
                                                            name: 'GuestApplyPage',
                                                            params: {
                                                                id: '0',
                                                                school_id: school._id,
                                                                program_id: prg._id,
                                                            },
                                                        }"
                                                        >Apply</router-link
                                                    >
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { Colors, toCurrency, markUpApplicationFee, formatProgramDuration } from '@/utils/constants';
import { mapActions } from 'vuex';
import ApiService from '../apiServices/api.service';
export default {
    name: 'StudentSchool',
    data: function () {
        return {
            school: null,
            currentTab: '',
            isLoading: false,
            searchProgram: {
                name: "",
                level: ""
            },
            searchResult: [],
            program_list: [
                {name: 'Bachelors', id: 'Bachelor'},
                {name: 'Diploma', id: 'Diploma'},
                {name: 'Masters', id: 'Master'},
                {name: 'MBA', id: 'MBA'},
                {name: 'Certificate', id: 'Certificat'},
                {name: 'Dual Degree', id: 'Dual Degree'},
                {name: 'PhD', id: 'PhD'},
            ],
        };
    },
    methods: {
        ...mapActions({
            updateModal: 'general/updateInfo',
        }),
        toCurrency,
        markUpApplicationFee,
        switchTabs(val) {
            this.currentTab = val;
            // this.$router.push(this.$route.path + `?tab=${this.currentTab}`);
            let query = this.$route.query;
            query = {...query, ...{tab: this.currentTab}}
            this.$router.replace({ query: query });
        },
        fetchSchool() {
            this.isLoading = true;
            ApiService.getData('edu/getUniversity/' + this.$route.params.id)
                .then((resp) => {
                    this.isLoading = false;
                    if (resp && resp.data) {
                        this.school = resp.data;
                        this.searchResult = this.school?.programs;
                        this.currentTab = this.$route.query.tab || 'about';
                        if (this.$route.query.search) {
                            this.searchForPrograms();
                        }
                    }
                })
                .catch((err) => {
                    this.isLoading = false;
                    console.log('err', err);
                });
        },
        searchForPrograms () {
            this.searchResult = [];
            for(var i = 0; i < this.school.programs.length; i++){
                let isPresent = true;
                let prog = this.school.programs[i];
                if(this.searchProgram.name.trim() != "") {
                    isPresent = prog.name.toLowerCase().indexOf(this.searchProgram.name.toLowerCase()) > -1;
                }
                if(isPresent && this.searchProgram.level.trim() != "") {
                    isPresent = prog.program_type.toLowerCase().indexOf(this.searchProgram.level.toLowerCase()) > -1;
                }
                if(isPresent) {
                    this.searchResult.push(prog);
                }
            }
        },
        clearSearchPrograms () {
            this.searchProgram = {
                name: "",
                level: ""
            };
            this.searchResult = this.school.programs;
        },
        formatProgramDuration(months) {
            return formatProgramDuration(months);
        }
    },
    mounted() {
        /*if (this.$store.state.user.currentUser !== null && this.$route.name != "StudentSchool") {
        this.$router.push({ name: 'StudentSchool', params: { id: this.$route.params.id } })
        return
    }
    if (this.$store.state.admin.currentUser !== null && this.$route.name != "AdminSchool") {
        this.$router.push({ name: 'AdminSchool', params: { id: this.$route.params.id } })
        return
    }*/
        if(this.$route.query.search) {
            this.searchProgram.name = this.$route.query.search;
        }
        this.fetchSchool();
    },
    computed: {
        cssProps() {
            return {
                '--primary-color': Colors.primaryColor,
                'overflow-x': 'auto',
            };
        },
        currentUser() {
            let currUser = null;
            if (this.$store.state.user.currentUser !== null) {
                currUser = this.$store.state.user.currentUser;
            }
            if (this.$store.state.admin.currentUser !== null) {
                currUser = this.$store.state.admin.currentUser;
            }
            if (this.$store.state.scout.currentUser !== null) {
                currUser = this.$store.state.scout.currentUser;
            }
            return currUser;
        },
    },
};
</script>
<style scoped>
.imageHeader {
    width: 100%;
}
.circleName {
    width: 11%;
    padding-top: 11%;
    border: 1px solid #c4c4c4;
    margin-left: 9.27%;
    margin-top: -3%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
}
/*#myTabContent,
.tabButtons,
.goBack {
    margin-left: 9.27%;
}*/
.tabButtons a {
    text-decoration: none;
    margin-right: 50px;
    font-weight: 500;
    color: gray;
}

#myTabContent .mytab {
    display: none;
}

#myTabContent .mytab.active {
    display: block;
}

.tabButtons a.active {
    color: var(--primary-color);
}

.border-right-button{
    border-right: 2px solid lightgray;
}

.myCard .card-header, .eachProgram .card-footer{
    background: rgb(0 0 0 /5%) !important;
}
</style>
