<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Payments" subText="An overview of all your payments."/>
        <div v-if="payments.length == 0" class="text-center my-4">
          <img src="assets/images/no-payments.png" width="150"/>
          <div class="mt-4 text-gray">You have not made any payments yet. When you do, they’ll appear here.</div>
          <div class="mt-4">
            <router-link to="/students/find-programs/schools" class="btn primaryBtn text-decoration-none py-2 px-4">Find Schools/Programs</router-link>
          </div>
        </div>
        <div v-if="payments.length > 0" class="col-12 col-md-10 tableParent">
          <table class="table table-borderless table-striped table-light">
            <thead>
              <tr>
                <th>Payment ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Date Paid</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pay in payments" :key="pay._id">
                  <td>{{pay._id}}</td>
                  <td>{{pay.currency_symbol}}{{toCurrency(pay.amount_paid)}}</td>
                  <td>Application Fee</td>
                  <td>{{formatDateLocal(pay.date_of_payment)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import UserService from '@/apiServices/students/user.service'
import { formatDate } from '@/utils/format'
import { toCurrency } from '@/utils/constants'
export default {
  name: 'StudentPayments',
  components: {
    PageHeader
  },
  data: function () {
    return {
      payments: []
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    toCurrency,
    getPayments () {
      
      UserService.getData('get_all_payments').then((resp) => {
        
        if (resp && resp.data) {
          this.payments = resp.data.filter(x => x.amount_to_pay === x.amount_paid)
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  mounted () {
    if (this.$store.state.user.currentUser != null) {
      this.getPayments()
    }
  }
}
</script>
