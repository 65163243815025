import ScoutHome from '../views/scout/HomePage.vue';
import ScoutIndex from '../views/scout/Index.vue';
import StudentProfile from '../views/scout/Profile.vue';
import PersonalInfo from '@/components/scout/PersonalInfo.vue';
import EducationHistory from '@/components/scout/EducationHistory.vue';
import ExamScoresHistory from '@/components/scout/ExamScoresHistory.vue';
import BackgroundInfoHistory from '@/components/scout/BackgroundInfoHistory.vue';
import DocumentList from '@/components/scout/DocumentList.vue';
// import StudentSignup from '../views/scout/SignupPage.vue'
import ScoutApplications from '../views/scout/Applications.vue';
import ScoutPayments from '../views/scout/Payments.vue';
// import StudentSignUpSuccess from '../views/scout/SignUpSuccess.vue'
import ScoutLogin from '../views/scout/LoginPage.vue';
import ScoutForgotPassword from '../views/scout/ForgotPassword.vue';
import ScoutResetPassword from '../views/scout/ResetPassword.vue';
import ScoutAbout from '../views/scout/About.vue';
import ApplyForm from '../views/scout/ApplyPage.vue';
import ScoutFindProgram from '../views/FindProgram.vue';
import ScoutSchoolList from '@/components/Schools.vue';
import ScoutProgramList from '@/components/Programs.vue';
import ScoutSchool from '../views/School.vue';
import ScoutProgram from '../views/Program.vue';
import ScoutNotifications from '../views/scout/Notifications.vue';
import ScoutStudentsList from '../views/scout/StudentsList.vue';
import CreateNewStudent from '../views/scout/CreateNewStudent.vue';
import StudentApplications from '@/components/scout/StudentApplications.vue';
import StudentPayments from '@/components/scout/StudentPayments.vue';
import ScoutApplyForStudent from '../views/scout/ScoutApplyForStudent.vue';
import ScoutRegister from '../views/scout/RegisterPage.vue';
import ScoutProfile from '../views/scout/ScoutProfile.vue';
import ScoutsTermsAndConditions from '../views/scout/ScoutsTermsAndConditions.vue';

var scoutRoute = {
    path: '/scout/',
    name: 'ScoutHome',
    component: ScoutHome,
    children: [
        /*{
      name: 'StudentSignUp',
      path: 'signup',
      component: StudentSignup
    },*/
        {
            name: 'ScoutLogin',
            path: 'login',
            component: ScoutLogin,
        },
        {
            name: 'ScoutRegister',
            path: 'register',
            component: ScoutRegister,
        },
        {
            name: 'ScoutForgotPassword',
            path: 'forgot-password',
            component: ScoutForgotPassword,
        },
        {
            name: 'ScoutResetPassword',
            path: 'reset-password/:token',
            component: ScoutResetPassword,
        },
        /*{
      name: 'StudentSignUpSuccess',
      path: 'signupsuccess',
      component: StudentSignUpSuccess
    },*/
        {
            path: 'dashboard',
            name: 'ScoutIndex',
            component: ScoutIndex,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'profile',
            name: 'ScoutProfile',
            component: ScoutProfile,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'my-students',
            name: 'ScoutStudentsList',
            component: ScoutStudentsList,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'my-student/school/:school_id/program/:program_id/apply/:id',
            name: 'ScoutApplyForStudent',
            component: ScoutApplyForStudent,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'create-student',
            name: 'CreateNewStudent',
            component: CreateNewStudent,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '',
            redirect: { name: 'ScoutIndex' },
        },
        {
            name: 'ScoutStudentProfileIndex',
            path: 'my-student/:studentId',
            component: StudentProfile,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'ScoutStudentPersonalInfo' },
                },
                {
                    name: 'ScoutStudentPersonalInfo',
                    path: 'personal-info',
                    component: PersonalInfo,
                },
                {
                    name: 'ScoutStudentEducationalHistory',
                    path: 'education-history',
                    component: EducationHistory,
                },
                {
                    name: 'ScoutStudentExamScoresHistory',
                    path: 'exam-scores-history',
                    component: ExamScoresHistory,
                },
                {
                    name: 'ScoutStudentBackgroundInfoHistory',
                    path: 'background-info-history',
                    component: BackgroundInfoHistory,
                },
                {
                    name: 'ScoutStudentDocumentList',
                    path: 'documents',
                    component: DocumentList,
                },
                {
                    name: 'ScoutStudentApplicationsList',
                    path: 'applications',
                    component: StudentApplications,
                },
                {
                    name: 'ScoutStudentPaymentsList',
                    path: 'payments',
                    component: StudentPayments,
                },
            ],
        },
        {
            path: 'find-programs',
            name: 'ScoutFindProgram',
            component: ScoutFindProgram,
            meta: {
                requiresAuth: true,
            },
            children: [
                {
                    path: '',
                    redirect: { name: 'ScoutSchoolList' },
                },
                {
                    name: 'ScoutSchoolList',
                    path: 'schools',
                    component: ScoutSchoolList,
                },
                {
                    name: 'ScoutProgramList',
                    path: 'programs',
                    component: ScoutProgramList,
                },
            ],
        },
        {
            path: 'school/:id',
            name: 'ScoutSchool',
            component: ScoutSchool,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'school/:school_id/program/:id',
            name: 'ScoutProgram',
            component: ScoutProgram,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'my-student/:studentId/school/:school_id/program/:program_id/apply/:id',
            name: 'ScoutStudentApplyForm',
            component: ApplyForm,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'applications',
            name: 'ScoutApplications',
            component: ScoutApplications,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'payments',
            name: 'ScoutPayments',
            component: ScoutPayments,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'notifications',
            name: 'ScoutNotifications',
            component: ScoutNotifications,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'messages',
            name: 'ScoutMessages',
            component: ScoutAbout,
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: 'terms-and-conditions',
            name: 'ScoutsTermsAndConditions',
            component: ScoutsTermsAndConditions,
            meta: {
                requiresAuth: true,
            },
        },
    ],
};

export default scoutRoute;
