<template>
    <Form @submit="submitEducationInfo" :validation-schema="educationInfoschema" :initial-values="educationInfoUser">
        <div class="row">
            <h5 class="fw-bold">Add School</h5>
            <div class="col-12 col-md-5">
                <label>School Name <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.school_name" name="school_name" class="form-control"/>
                <ErrorMessage name="school_name" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Level of education <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.level_of_education" name="level_of_education" class="form-select" as="select">
                    <option>Select One</option>
                    <option value="primary">Primary</option>
                    <option value="secondary">Secondary</option>
                    <option value="undergraduate">Undergraduate</option>
                    <option value="postgraduate">Postgraduate</option>
                </Field>
                <ErrorMessage name="level_of_education" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Degree <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.degree" name="degree" class="form-control"/>
                <ErrorMessage name="degree" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Primary Language <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.primary_language" name="primary_language" class="form-select" as="select">
                    <option>Select One</option>
                    <option value="EN">English</option>
                    <option value="FR">French</option>
                    <option value="other">Others</option>
                </Field>
                <ErrorMessage name="primary_language" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Grading Scale (GPA scale) <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.grading_scale" name="grading_scale" class="form-select" as="select">
                    <option>Select One</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="7">7</option>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="100">100</option>
                </Field>
                <ErrorMessage name="grading_scale" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Grading average (your GPA) <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.grading_average" name="grading_average" class="form-control" type="number"/>
                <ErrorMessage name="grading_average" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Attended from <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.attended_from" name="attended_from" class="form-control" type="date"/>
                <ErrorMessage name="attended_from" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Attended to <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="educationInfo.attended_to" name="attended_to" class="form-control" type="date"/>
                <ErrorMessage name="attended_to" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label>Upload transcript</label>
                <Field v-model="educationInfo.uploadFiles" id="uploadEducationalFile" name="uploadFiles" class="form-control" type="file" multiple/>
            </div>
            <div v-if="educationInfo.documents && educationInfo.documents.length > 0" class="">
              <div class="col-12 col-md-10 mt-3">
                <div>Uploaded Docs</div>
                <ul>
                  <li v-for="doc in educationInfo.documents" :key="doc._id">{{doc.name}}
                    <a :href="doc.url" target="_blank" class="ms-3"><i class="bi bi-cloud-download"></i></a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="mt-5">
            <h5 class="fw-bold">School address</h5>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>Address Line 1 <span class="text-danger ml-2 fw-bold">*</span></label>
                    <Field v-model="educationInfo.address_line_1" name="address_line_1" class="form-control"/>
                    <ErrorMessage name="address_line_1" as="div" class="text-danger"/>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>City/Town <span class="text-danger ml-2 fw-bold">*</span></label>
                    <Field v-model="educationInfo.city" name="city" class="form-control"/>
                    <ErrorMessage name="city" as="div" class="text-danger"/>
                </div>
            </div>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>Address Line 2</label>
                    <Field v-model="educationInfo.address_line_2" name="address_line_2" class="form-control"/>
                    <ErrorMessage name="address_line_2" as="div" class="text-danger"/>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>State/Province <span class="text-danger ml-2 fw-bold">*</span></label>
                    <Field v-model="educationInfo.state" name="state" class="form-control"/>
                    <ErrorMessage name="state" as="div" class="text-danger"/>
                </div>
            </div>
            <div class="row mt-md-4">
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>ZIP/Postal Code <span class="text-danger ml-2 fw-bold">*</span></label>
                    <Field v-model="educationInfo.postal_code" name="postal_code" class="form-control"/>
                    <ErrorMessage name="postal_code" as="div" class="text-danger"/>
                </div>
                <div class="col-12 col-md-5 mt-3 mt-md-0">
                    <label>Country <span class="text-danger ml-2 fw-bold">*</span></label>
                    <Field v-model="educationInfo.country" name="country" class="form-select" as="select">
                        <option>Country</option>
                        <option v-for="country in countries" :value="country.value" :key="country.value">
                            {{ country.label }}
                        </option>
                    </Field>
                    <ErrorMessage name="country" as="div" class="text-danger"/>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="canEdit">
            <div class="col-12 col-md-10 text-end">
                <button type="submit" class="btn primaryBtn">Save</button>
            </div>
        </div>
    </Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { mapActions } from 'vuex'
const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export default {
  name: 'EducationForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: ['educationInfoPass', 'canEdit'],
  data: function () {
    const educationInfoschema = yup.object({
      school_name: yup.string().required().label('School Name'),
      level_of_education: yup.string().required().label('Level of education'),
      degree: yup.string().required().label('Degree'),
      primary_language: yup.string().required().label('Primary language'),
      attended_from: yup.date().typeError("Date is required").required().label('Atteneded from'),
      attended_to: yup.date().typeError("Date is required").required().label('Attended to'),
      address_line_1: yup.string().required().label('Address'),
      city: yup.string().required().label('City'),
      address_line_2: yup.string().nullable(),
      state: yup.string().required().label('State / Province'),
      postal_code: yup.string().required().label('Postal Code'),
      country: yup.string().required().label('Country of residence'),
      grading_scale: yup.number().typeError("Score is required").required().label('Grading scale'),
      grading_average: yup.lazy(() => {
        return yup.number().typeError("Score is required").max(this.maxEduValue).required().label('Grading average')
      }),
      uploadFiles: yup.object().nullable()
    })
    // grading_average: yup.number().required().label('Grading average'),

    return {
      educationInfoschema,
      grading_average: 5,
      educationInfo: {
        school_name: '',
        level_of_education: '',
        degree: '',
        primary_language: '',
        attended_from: '',
        attended_to: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        postal_code: '',
        country: '',
        grading_scale: '',
        grading_average: '',
        uploadFiles: null,
        documents: null
      }
    }
  },
  methods: {
    ...mapActions({
      saveEducationInfo: 'user/saveEducationInfo',
      updateModal: 'general/updateInfo'
    }),
    submitEducationInfo () {
      
      // if (this.educationInfo._id) delete this.educationInfo._id
      // delete this.educationInfo.__v
      delete this.educationInfo.user_id
      delete this.educationInfo.documents
      this.saveEducationInfo(this.educationInfo).then(
        (data) => {
          
          data.attended_from = data.attended_from.split('T')[0]
          data.attended_to = data.attended_to.split('T')[0]
          this.educationInfo = data
          document.getElementById('uploadEducationalFile').value = ''
          this.$parent.closeEducationForm()
          this.updateModal({ type: true, message: 'educational info saved' })
        }
      )
    }
  },
  computed: {
    countries () {
      const list = countries.getNames('en', { select: 'official' })
      return Object.keys(list).map((key) => ({ value: key, label: list[key] }))
    },
    educationInfoUser () {
      return this.educationInfoPass
    },
    maxEduValue () {
      return this.educationInfo.grading_scale
    }
  },
  mounted () {
    if (this.educationInfoPass != null) {
      this.educationInfo = this.educationInfoPass
    }
  }
}
</script>
