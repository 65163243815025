import AuthService from '../apiServices/admin/auth.service'
import AdminService from '../apiServices/admin/admin.service'
import router from '../router'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { loggedIn: true }, currentUser: user, notificationCount: 0 }
  : { status: {}, currentUser: null }

export const adminAuthStore = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }) {
      commit('logout')
    },
    getNotificationCount ({ commit }) {
      return AdminService.getData('get_notifications').then(
        resp => {
          commit('getNotificationCount', resp.data)
        }
      )
    },
    reduceNotificationCount ({ commit }) {
      commit('reduceNotificationCount')
    }
  },
  mutations: {
    loginSuccess (state, user) {
      state.status = { loggedIn: true }
      state.currentUser = user.data
    },
    loginFailure (state) {
      state.status = {}
      state.currentUser = null
    },
    logout (state) {
      state.status = {}
      state.currentUser = null
      router.push('/admin/login')
    },
    registerSuccess (state) {
      state.status = {}
    },
    registerFailure (state) {
      state.status = {}
    },
    getNotificationCount (state, data) {
      state.notificationCount = data.filter(x => x.is_read != true).length;
    },
    reduceNotificationCount (state) {
      state.notificationCount--;
      state.notificationCount = state.notificationCount < 0 ? 0 : state.notificationCount;
    }
  }
}
