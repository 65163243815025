import Notiflix from "notiflix";
const data = {
  showModal: false,
  isSignOut: false,
  info: {
    type: true,
    message: ''
  }
}

export const generalStore = {
  namespaced: true,
  state: data,
  actions: {
    showModal ({ commit }) {
      commit('showModal')
    },
    showSignOut ({ commit }) {
      commit('showSignOut')
    },
    hideModal ({ commit }) {
      commit('hideModal')
    },
    updateInfo ({ commit }, { type, message }) {
      commit('updateInfo', { type, message })
    }
  },
  mutations: {
    showModal (state) {
      state.showModal = true
    },
    showSignOut (state) {
      state.showModal = true
      state.isSignOut = true
    },
    hideModal (state) {
      state.showModal = false
      state.info.type = true
      state.info.message = ''
      state.isSignOut = false
    },
    updateInfo (state, { type, message }) {
      //state.showModal = true
      state.info.type = type
      state.info.message = message
      if (type == false) {
        Notiflix.Report.failure("Schooliply", "<div>" + message + "</div>", "Okay!");
      } else if (type == true) {
        Notiflix.Report.success("Schooliply", "<div>" + message + "</div>", "Okay!");
      }
    }
  }
}
