<template>
  <div class="container pt-5 px-3 px-md-5">
    <page-header v-bind:header="'Hi ' + currentUser?.uuser.first_name + '👋,'" subText="Here’s an overview of your activities on schooliply."></page-header>
    <div class="row">
        <div class="col-6 col-md-4">
          <div class="smaller fw-bold text-muted">TOTAL APPLICATIONS</div>
          <div class="fw-bold">0</div>
        </div>
        <!--<div class="col-6 col-md-4">
          <div class="smaller fw-bold text-muted">TOTAL PAYMENTS</div>
          <div class="fw-bold">{{totalPayments}}</div>
        </div>-->
    </div>
    <div class="row mt-4 mt-md-5">
        <div class="col-12 col-md-4 d-flex">
          <div class="row">
            <div class="col-6 col-md-6">
              <div class="smaller fw-bold text-muted">COMPLETE</div>
              <div class="fw-bold">0</div>
            </div>
            <div class="col-6 col-md-6 mt-0 mt-md-0">
              <div class="smaller fw-bold text-muted">INCOMPLETE</div>
              <div class="fw-bold">0</div>
            </div>
          </div>
        </div>

        <!--<div class="col-6 col-md-4 d-flex">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="smaller fw-bold text-muted">COMPLETE</div>
              <div class="fw-bold">{{totalCompletePayments}}</div>
            </div>
            <div class="col-12 col-md-6 mt-3 mt-md-0">
              <div class="smaller fw-bold text-muted">INCOMPLETE</div>
              <div class="fw-bold">{{totalIncompletePayments}}</div>
            </div>
          </div>
        </div>-->
    </div>
    <div class="col-12 col-md-10 tableParent mb-4" v-if="scouts.countries.length > 0">
          <div class="col-12 col-md-4 offset-md-4 mt-2">
            <input type="text" placeholder="search" class="form-control rounded-pill mb-3" v-model="searchvalue"/>
          </div>
          <div class="col-12 mt-1">
            <div class="d-flex justify-content-center align-items-center text-danger">
                <h6 class="me-1">Total Scouts:</h6>
                <h5>{{scouts.total}}</h5>
              </div>
          </div>
          <EasyDataTable
            :headers="headers"
            :items="scouts.countries"
            :search-value="searchvalue"
            header-class-name="datatable-header"
            :sort-by="'count'"
            :sort-type="'desc'"
            alternating
          >
          </EasyDataTable>
        </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader.vue'
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
import { formatDate } from '@/utils/format'
export default {
  name: 'AdminIndex',
  components: {
    PageHeader
  },
  data: function () {
    return {
      authenticated: true,
      apps: [],
      totalApp: 0,
      totalCompleteApp: 0,
      totalIncompleteApp: 0,
      totalPayments: 0,
      totalCompletePayments: 0,
      totalIncompletePayments: 0,
      scouts: {
        total: 0,
        countries: []
      },
      searchvalue: "",
      headers: [
        { text: "Country", value: "country" },
        { text: "Scouts", value: "count", sortable: true }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getApps () {
      
      AdminService.getData('get_applications').then((resp) => {
        
        if (resp && resp.data) {
          this.apps = resp.data
          this.totalApp = this.apps.length
          this.totalCompleteApp = this.apps.filter(x => x.status === 'completed').length
          this.totalIncompleteApp = this.apps.filter(x => x.status !== 'completed').length
          // this.totalPayments = this.apps.map(x => x.payment.amount_to_pay).reduce((a, b) => a + b, 0)
          // this.totalCompletePayments = this.apps.map(x => x.payment.amount_paid).reduce((a, b) => a + b, 0)
          // this.totalIncompletePayments = this.totalPayments - this.totalCompletePayments
        }
      })
    },
    getScouts () {
      AdminService.getData('get_scouts').then((resp) => {
        if (resp && resp.data) {
          let scouts = resp.data;
          let countries = [...new Set(scouts.map(x => x.country))];
          this.scouts.total = scouts.length;
          for(var i = 0; i < countries.length; i++){
            let country = countries[i]
            let count = scouts.filter(x => x.country == country).length;
            this.scouts.countries.push({country: country, count: count});
          }
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    }
  },
  computed: {
    currentUser () {
      return this.$store.state.admin.currentUser
    }
  },
  mounted () {
    if (this.currentUser == null) {
      this.$router.push('/admin/login')
      return;
    }
    if(this.currentUser.uuser.role == 'super-admin'){
      this.getScouts();
    }
  }
}
</script>
