<template>
    <div class="container py-3 px-3 py-md-5 px-md-5">
      <page-header header="Applications" subText="Applications assigned to you"/>

      <div>
            <button class="btn primaryBtn" @click="getCompletedApps()" v-if="!showingCompleted">Show Completed Applications</button>
            <button class="btn primaryBtn" @click="getAssignedApps()" v-if="showingCompleted">Show Pending Applications</button>
        </div>
        <div v-if="apps.length == 0" class="text-center my-4">
          <img src="assets/images/no-application.png" width="150"/>
          <div class="mt-4 text-gray">There is no application assigned to you yet</div>
        </div>

        <div v-if="apps.length > 0" class="col-12 col-md-12 tableParent">
          <div class="mt-3 text-center" v-if="!showingCompleted">
                <h5>Total Applications: <span>{{ totalApps }}</span></h5>
            </div>
          <application-table :key="resetKey" :appsPass="apps" :canAssignPass="false"></application-table>
          <div class="mt-3" v-if="!isLastPage && !showingCompleted">
                <button class="btn primaryBtn" @click="getAssignedApps()">Load More Applications</button>
                <div class="col-12 col-md-6 offset-md-3 mt-3 d-flex">
                    <input
                        type="text"
                        placeholder="search by application id"
                        class="form-control rounded-pill mb-3"
                        v-model="searchByID"
                    />
                    <button class="btn btn-link primaryColor text-decoration-none" @click="searchAppById"><h5>Search</h5></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import AppTracker from '@/components/common/Tracker.vue'
import PageHeader from '@/components/PageHeader.vue'
import ApplicationTable from '@/components/admin/ApplicationTable.vue';
import { mapActions } from 'vuex'
import AdminService from '@/apiServices/admin/admin.service'
import { formatDate } from '@/utils/format'
import { calcTracker } from '@/utils/constants';
import { applicationTracker } from '@/utils/constants';
export default {
  name: 'AdminApplications',
  components: {
    PageHeader,
    ApplicationTable
    // AppTracker
  },
  data: function () {
    return {
      tracks: [],
      apps: [],
      pendingApps: [],
      completedApps: [],
      school_name: '',
      program_name: '',
      application_id: null,
      resetKey: 0,
      showingCompleted: false,
      nextPage: 1,
      totalApps: 0,
      isLastPage: true,
      searchByID: ""
    }
  },
  methods: {
    ...mapActions({
      updateModal: 'general/updateInfo'
    }),
    getAssignedApps () {
      if(this.showingCompleted) { // show previously fetched pending apps if toggling completed/pending apps
                this.apps = this.pendingApps;
                this.resetKey += 1;
                this.showingCompleted = false;
                return;
            }
            // load more apps
      AdminService.getData('get_assigned_applications?page=' + this.nextPage).then((resp) => {
        if (resp && resp.data) {
          this.pendingApps = this.pendingApps.concat(resp.data?.apps);
          this.apps = this.pendingApps;
          this.resetKey += 1;
          this.showingCompleted = false;
          this.nextPage = resp.data?.nextPage;
          this.totalApps = resp.data?.total;
          this.isLastPage = resp.data?.isLastPage;
        }
      })
    },
    formatDateLocal (dt) {
      return formatDate(dt)
    },
    calcTracker (app) {
      return calcTracker(app);
    },
    getCompletedApps() {
      if(this.completedApps.length > 0) { // show fetched completed apps
                this.apps = this.completedApps;
                this.resetKey += 1;
                this.showingCompleted = true;
                return;
            }
            //fetch completed apps
            AdminService.getData('get_assigned_completed_applications')
                .then((resp) => {
                    if(resp.data){
                        this.completedApps = resp.data;
                        this.apps = resp.data;
                        this.resetKey += 1;
                        this.showingCompleted = true;
                    }
                })
        },
        searchAppById() {
            if(this.searchByID.trim() == ""){
                return;
            }
            AdminService.getData('get_app_details/' + this.searchByID.trim())
                .then((resp) => {
                    if(!resp.data){
                        this.updateModal({
                            type: false,
                            message: 'Application not found',
                        });
                        return;
                    }
                    this.$router.push({ name: 'ViewAnApplication', params: {id: resp.data._id, user_id: resp.data.user_id._id} })
                })
        }
  },
  mounted () {
    this.tracks = applicationTracker;
    if (this.$store.state.admin.currentUser != null) {
      this.getAssignedApps()
    }
  }
}
</script>
