<template>
    <div class="row mb-4">
      <h5 class="fw-bold">Exam Type</h5>
      <div class="col-12 col-md-5">
        <select class="form-select" v-model="formType" :disabled="disableDropdown">
          <option value="IELTS">IELTS</option>
          <option value="TOEFL">TOEFL</option>
          <option value="GMAT">GMAT</option>
          <option value="GRE">GRE</option>
        </select>
      </div>
    </div>
    <div class="row">
      <h5 class="fw-bold">Exam Details</h5>
    </div>
    <Form v-if="formType == 'IELTS' || formType == 'TOEFL'" @submit="submitEnglishScoreInfo" :validation-schema="examScoreEnglishSchema" :initial-values="examScoreUser">
        <div class="row">
            <div class="col-12 col-md-5">
                <label>Exam Date <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="examEnglishInfo.exam_date" type="date" name="exam_date" class="form-control"/>
                <ErrorMessage name="exam_date" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Reading <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="examEnglishInfo.reading_score" type="number" name="reading_score" class="form-control"/>
                <ErrorMessage name="reading_score" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Listening <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="examEnglishInfo.listening_score" type="number" name="listening_score" class="form-control"/>
                <ErrorMessage name="listening_score" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Speaking <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="examEnglishInfo.speaking_score" type="number" name="speaking_score" class="form-control"/>
                <ErrorMessage name="speaking_score" as="div" class="text-danger"/>
            </div>
            <div class="col-12 col-md-5 mt-3 mt-md-0">
                <label>Writing <span class="text-danger ml-2 fw-bold">*</span></label>
                <Field v-model="examEnglishInfo.writing_score" type="number" name="writing_score" class="form-control"/>
                <ErrorMessage name="writing_score" as="div" class="text-danger"/>
            </div>
        </div>
        <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label>Upload result</label>
                <Field v-model="examEnglishInfo.uploadFiles" name="uploadFiles" class="form-control uploadFiles" type="file" multiple/>
            </div>
            <div v-if="examEnglishInfo.documents && examEnglishInfo.documents.length > 0" class="">
              <div class="col-12 col-md-10 mt-3">
                <div>Uploaded Docs</div>
                <ul>
                  <li v-for="doc in examEnglishInfo.documents" :key="doc._id">{{doc.name}}
                    <a :href="doc.url" target="_blank" class="ms-3"><i class="bi bi-cloud-download"></i></a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        <div class="row mt-3" v-if="canEdit">
            <div class="col-12 col-md-10 text-end">
                <button type="submit" class="btn primaryBtn">Save</button>
            </div>
        </div>
    </Form>
    <Form v-if="formType == 'GMAT'" @submit="submitOtherScoreInfo" :validation-schema="examGMATSchema" :initial-values="examScoreUser">
    <div class="row">
        <div class="col-12 col-md-5">
            <label>Verbal <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.verbal_score" type="number" name="verbal_score" class="form-control"/>
            <ErrorMessage name="verbal_score" as="div" class="text-danger"/>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Quantitative <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.quantitative_score" type="number" name="quantitative_score" class="form-control"/>
            <ErrorMessage name="quantitative_score" as="div" class="text-danger"/>
        </div>
    </div>
    <div class="row mt-md-4">
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Integrated Reasoning <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.integrated_reasoning_score" type="number" name="integrated_reasoning_score" class="form-control"/>
            <ErrorMessage name="integrated_reasoning_score" as="div" class="text-danger"/>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Analytical Writing Assessment <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.awa_score" type="number" name="awa_score" class="form-control"/>
            <ErrorMessage name="awa_score" as="div" class="text-danger"/>
        </div>
    </div>
    <div class="row mt-md-4">
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Total Score <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.total_score" type="number" name="total_score" class="form-control"/>
            <ErrorMessage name="total_score" as="div" class="text-danger"/>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Exam Date <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGMAT.exam_date" type="date" name="exam_date" class="form-control"/>
            <ErrorMessage name="exam_date" as="div" class="text-danger"/>
        </div>
    </div>
    <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label>Upload result</label>
                <Field v-model="examGMAT.uploadFiles" name="uploadFiles" class="form-control uploadFiles" type="file" multiple/>
            </div>
            <div v-if="examGMAT.documents && examGMAT.documents.length > 0" class="">
              <div class="col-12 col-md-10 mt-3">
                <div>Uploaded Docs</div>
                <ul>
                  <li v-for="doc in examGMAT.documents" :key="doc._id">{{doc.name}}
                    <a :href="doc.url" target="_blank" class="ms-3"><i class="bi bi-cloud-download"></i></a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
    <div class="row mt-3" v-if="canEdit">
        <div class="col-12 col-md-10 text-end">
            <button type="submit" class="btn primaryBtn">Save</button>
        </div>
    </div>
</Form>
    <Form v-if="formType == 'GRE'" @submit="submitOtherScoreInfo" :validation-schema="examGRESchema" :initial-values="examScoreUser">
    <div class="row">
        <div class="col-12 col-md-5">
            <label>Verbal <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGRE.verbal_score" type="number" name="verbal_score" class="form-control"/>
            <ErrorMessage name="verbal_score" as="div" class="text-danger"/>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Quantitative <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGRE.quantitative_score" type="number" name="quantitative_score" class="form-control"/>
            <ErrorMessage name="quantitative_score" as="div" class="text-danger"/>
        </div>
    </div>
    <div class="row mt-md-4">
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Analytical Writing Assessment <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGRE.awa_score" type="number" name="awa_score" class="form-control"/>
            <ErrorMessage name="awa_score" as="div" class="text-danger"/>
        </div>
        <div class="col-12 col-md-5 mt-3 mt-md-0">
            <label>Exam Date <span class="text-danger ml-2 fw-bold">*</span></label>
            <Field v-model="examGRE.exam_date" type="date" name="exam_date" class="form-control"/>
            <ErrorMessage name="exam_date" as="div" class="text-danger"/>
        </div>
    </div>
    <div class="row mt-md-4">
            <div class="col-12 col-md-10 mt-3 mt-md-0">
                <label>Upload result</label>
                <Field v-model="examGRE.uploadFiles" name="uploadFiles" class="form-control uploadFiles" type="file" multiple/>
            </div>
            <div v-if="examGRE.documents && examGRE.documents.length > 0" class="">
              <div class="col-12 col-md-10 mt-3">
                <div>Uploaded Docs</div>
                <ul>
                  <li v-for="doc in examGRE.documents" :key="doc._id">{{doc.name}}
                    <a :href="doc.url" target="_blank" class="ms-3"><i class="bi bi-cloud-download"></i></a>
                  </li>
                </ul>
              </div>
            </div>
        </div>
    <div class="row mt-3" v-if="canEdit">
        <div class="col-12 col-md-10 text-end">
            <button type="submit" class="btn primaryBtn">Save</button>
        </div>
    </div>
</Form>
</template>
<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'
import { mapActions } from 'vuex'

export default {
  name: 'ExamScoresForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: ['examScoreUserPass', 'canEdit'],
  data: function () {
    const EngScores = {
      reading_score: {
        IELTS: 9,
        TOEFL: 45
      },
      listening_score: {
        IELTS: 9,
        TOEFL: 34
      },
      speaking_score: {
        IELTS: 9,
        TOEFL: 24
      },
      writing_score: {
        IELTS: 9,
        TOEFL: 10
      }
    }

    const examScoreEnglishSchema = yup.object({
      exam_date: yup.date().typeError("Date is required").required().label('Exam date'),
      reading_score: yup.lazy(() => {
        return yup.number().typeError("Score is required").max(EngScores.reading_score[this.formType]).required().label('Reading')
      }),
      listening_score: yup.lazy(() => {
        return yup.number().typeError("Score is required").max(EngScores.listening_score[this.formType]).required().label('Listening')
      }),
      speaking_score: yup.lazy(() => {
        return yup.number().typeError("Score is required").max(EngScores.speaking_score[this.formType]).required().label('Speaking')
      }),
      writing_score: yup.lazy(() => {
        return yup.number().typeError("Score is required").max(EngScores.writing_score[this.formType]).required().label('Writing')
      }),
      uploadFiles: yup.object().nullable()
    })

    const examGMATSchema = yup.object({
      verbal_score: yup.number().typeError("Score is required").min(6).max(51).required().label('Verbal'),
      quantitative_score: yup.number().typeError("Score is required").min(6).max(51).required().label('Quantitative'),
      integrated_reasoning_score: yup.number().typeError("Score is required").min(1).max(8).required().label('Integrated reasoning'),
      awa_score: yup.number().typeError("Score is required").min(0).max(6).required().label('Analytical Writing Assessment'),
      total_score: yup.number().typeError("Score is required").min(200).max(800).required().label('Total score'),
      exam_date: yup.date().typeError("Date is required").required().label('Exam date'),
      uploadFiles: yup.object().nullable()
    })

    const examGRESchema = yup.object({
      verbal_score: yup.number().typeError("Score is required").min(130).max(170).required().label('Verbal'),
      quantitative_score: yup.number().typeError("Score is required").min(130).max(170).required().label('Quantitative'),
      awa_score: yup.number().typeError("Score is required").min(0).max(6).required().label('Analytical Writing Assessment'),
      exam_date: yup.date().typeError("Date is required").required().label('Exam date'),
      uploadFiles: yup.object().nullable()
    })

    return {
      examScoreEnglishSchema,
      examGMATSchema,
      examGRESchema,
      examEnglishInfo: {
        exam_name: '',
        exam_date: '',
        reading_score: '',
        listening_score: '',
        speaking_score: '',
        writing_score: '',
        uploadFiles: null,
        documents: null
      },
      examGMAT: {
        exam_name: '',
        verbal_score: '',
        quantitative_score: '',
        integrated_reasoning_score: '',
        awa_score: '',
        total_score: '',
        exam_date: '',
        uploadFiles: null,
        documents: null
      },
      examGRE: {
        exam_name: '',
        verbal_score: '',
        quantitative_score: '',
        awa_score: '',
        exam_date: '',
        uploadFiles: null,
        documents: null
      },
      formType: 'IELTS',
      disableDropdown: false
    }
  },
  methods: {
    ...mapActions({
      saveExamScores: 'user/saveExamScores',
      updateModal: 'general/updateInfo'
    }),
    submitEnglishScoreInfo () {
      
      // if (this.educationInfo._id) delete this.educationInfo._id
      // delete this.educationInfo.__v
      // console.log('data exam english', this.examEnglishInfo)
      delete this.examEnglishInfo.user_id
      delete this.examEnglishInfo.documents
      this.examEnglishInfo.exam_name = this.formType
      this.saveExamScores(this.examEnglishInfo).then(
        (data) => {
          
          data.exam_date = data.exam_date.split('T')[0]
          this.examEnglishInfo = data
          this.disableDropdown = true
          document.querySelector('.uploadFiles').value = ''
          this.$parent.closeExamForm()
          this.updateModal({ type: true, message: 'exam score saved' })
        },
        error => {
          
          console.log(error)
          
        }
      )
    },
    submitOtherScoreInfo () {
      
      // if (this.educationInfo._id) delete this.educationInfo._id
      // delete this.educationInfo.__v
      let sendData = null
      if (this.formType === 'GRE') sendData = this.examGRE
      if (this.formType === 'GMAT') sendData = this.examGMAT
      sendData.exam_name = this.formType
      delete sendData.user_id
      delete sendData.documents
      this.saveExamScores(sendData).then(
        (data) => {
          
          document.querySelector('.uploadFiles').value = ''
          data.exam_date = data.exam_date.split('T')[0]
          if (this.formType === 'GRE') this.examGRE = data
          if (this.formType === 'GMAT') this.examGMAT = data
          this.disableDropdown = true
          this.$parent.closeExamForm()
          this.updateModal({ type: true, message: 'exam score saved' })
        }
      )
    }
  },
  computed: {
    examScoreUser () {
      return this.examScoreUserPass
    }
  },
  mounted () {
    if (this.examScoreUserPass != null) {
      const examTypePass = this.examScoreUserPass.exam_name
      if (examTypePass === 'IELTS' || examTypePass === 'TOEFL') {
        this.examEnglishInfo = this.examScoreUserPass
      } else if (examTypePass === 'GRE') {
        this.examGRE = this.examScoreUserPass
      } else if (examTypePass === 'GMAT') {
        this.examGMAT = this.examScoreUserPass
      }
      this.disableDropdown = true
      this.formType = this.examScoreUserPass.exam_name
    }
  }
}
</script>
