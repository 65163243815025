import StudentHome from '../views/students/HomePage.vue'
import StudentIndex from '../views/students/Index.vue'
import StudentProfile from '../views/students/Profile.vue'
import PersonalInfo from '@/components/students/PersonalInfo.vue'
import AccountInfo from '@/components/students/AccountInfo.vue'
import EducationHistory from '@/components/students/EducationHistory.vue'
import ExamScoresHistory from '@/components/students/ExamScoresHistory.vue'
import BackgroundInfoHistory from '@/components/students/BackgroundInfoHistory.vue'
import DocumentList from '@/components/students/DocumentList.vue'
import StudentSignup from '../views/students/SignupPage.vue'
import StudentApplications from '../views/students/Applications.vue'
import StudentPayments from '../views/students/Payments.vue'
import StudentSignUpSuccess from '../views/students/SignUpSuccess.vue'
import StudentLogin from '../views/students/LoginPage.vue'
import StudentForgotPassword from '../views/students/ForgotPassword.vue'
import StudentResetPassword from '../views/students/ResetPassword.vue'
import StudentAbout from '../views/students/About.vue'
import ApplyForm from '../views/students/ApplyPage.vue'
import StudentFindProgram from '../views/FindProgram.vue'
import SchoolList from '@/components/Schools.vue'
import ProgramList from '@/components/Programs.vue'
import StudentSchool from '../views/School.vue'
import StudentProgram from '../views/Program.vue'
import StudentNotifications from '../views/students/Notifications.vue'

var stud = {
  path: '/students/',
  name: 'StudentHome',
  component: StudentHome,
  children: [
    {
      name: 'StudentSignUp',
      path: 'signup',
      component: StudentSignup
    },
    {
      name: 'StudentLogin',
      path: 'login',
      component: StudentLogin
    },
    {
      name: 'StudentForgotPassword',
      path: 'forgot-password',
      component: StudentForgotPassword
    },
    {
      name: 'StudentResetPassword',
      path: 'reset-password/:token',
      component: StudentResetPassword
    },
    {
      name: 'StudentSignUpSuccess',
      path: 'signupsuccess',
      component: StudentSignUpSuccess
    },
    {
      path: 'dashboard',
      name: 'StudentIndex',
      component: StudentIndex,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '',
      redirect: { name: 'StudentIndex' }
    },
    {
      path: 'profile',
      component: StudentProfile,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          redirect: { name: 'PersonalInfo' }
        },
        {
          name: 'PersonalInfo',
          path: 'personal-info',
          component: PersonalInfo
        },
        {
          name: 'AccountInfo',
          path: 'account-info',
          component: AccountInfo
        },
        {
          name: 'EducationalHistory',
          path: 'education-history',
          component: EducationHistory
        },
        {
          name: 'ExamScoresHistory',
          path: 'exam-scores-history',
          component: ExamScoresHistory
        },
        {
          name: 'BackgroundInfoHistory',
          path: 'background-info-history',
          component: BackgroundInfoHistory
        },
        {
          name: 'DocumentList',
          path: 'documents',
          component: DocumentList
        }
      ]
    },
    {
      path: 'find-programs',
      name:'StudentFindProgram',
      component: StudentFindProgram,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '',
          redirect: { name: 'SchoolList' }
        },
        {
          name: 'SchoolList',
          path: 'schools',
          component: SchoolList
        },
        {
          name: 'ProgramList',
          path: 'programs',
          component: ProgramList
        }
      ]
    },
    {
      path: 'school/:id',
      name: 'StudentSchool',
      component: StudentSchool,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'school/:school_id/program/:id',
      name: 'StudentProgram',
      component: StudentProgram,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'school/:school_id/program/:program_id/apply/:id',
      name: 'StudentApplyForm',
      component: ApplyForm,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: 'applications',
      name: 'StudentApplications',
      component: StudentApplications,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'payments',
      name: 'StudentPayments',
      component: StudentPayments,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'notifications',
      name: 'StudentNotifications',
      component: StudentNotifications,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: 'messages',
      name: 'StudentMessages',
      component: StudentAbout,
      meta: {
        requiresAuth: true
      }
    }
  ]
}

export default stud
